import {
  IconButton,
  Dialog,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  Box,
  CircularProgress,
  DialogContent,
  DialogActions,
  Tooltip,
  Button,
  TextField,
  Autocomplete,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { currencyFormat } from "../../common/components/Utils";

import {
  addOrUpdateTeacher,
  getSchoolPositions,
} from "../../../../store/posts/actions";

import { isEmpty } from "lodash";
import axios from "axios";
import { CSVLink } from "react-csv";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import moment from "moment";
// import { showError } from "../../toastify";
import defaultPofile from "../../../assets/default-profile.jpg";
// import { idNumberPattern } from "../../common/components/Utils";

import { getDistricts } from "../../../../store/posts/actions";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import MaskedInput from "react-text-mask";
import joi from "joi";
import DoneAllIcon from "@mui/icons-material/DoneAll";

const personalEmailValidator = joi.object({
  email: joi.string().email({
    minDomainSegments: 2,
    tlds: { allow: ["com", "fr", "rw"] },
  }),
});

const idNumberPattern = [
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
];

const UpdateTeacherDialog = (props) => {
  const {
    user,
    loading,
    onWait,
    teacher,
    showDialog,
    setShowDialog,
    addOrUpdateTeacher,
    roles,
    districts,
    getDistricts,
    // position,
    degrees,
    specializations,
    schoolPositions,

    placementReport,
    getSchoolPositions,
  } = props;

  const onClose = () => {
    setShowDialog(false);
  };

  const [sectors, setSectors] = useState([]);
  const [cells, setCells] = useState([]);
  const [villages, setVillages] = useState([]);

  const [district, setDistrict] = useState(null);
  const [sector, setSector] = useState(null);
  const [cell, setCell] = useState(null);
  const [village, setVillage] = useState(null);

  const [errors, setErrors] = useState({
    nidNumberHasError: false,
    emailHasError: false,
    phoneNumberHasError: false,
    employeeIdHasError: false,
    staffCodeHasError: false,
    employmentTypeHasError: false,
    bankAccountNumberHasError: false,
    educationCertficateHasError: false,
    positionHasError: false,
    passportNumberHasError: false,

    employmentDateInEducationHasError: false,
    positionAppointmentDateHasError: false,
    hasError: false,
  });

  const teacherInfoFormValidator = () => {
    const error = {
      nidNumberHasError: false,
      emailHasError: false,
      phoneNumberHasError: false,
      employeeIdHasError: false,
      staffCodeHasError: false,
      employmentTypeHasError: false,
      bankAccountNumberHasError: false,
      educationCertficateHasError: false,
      positionHasError: false,
      passportNumberHasError: false,

      employmentDateInEducationHasError: false,
      positionAppointmentDateHasError: false,
      hasError: false,
    };

    // console.log(teacherForm.nidNumber.replace(/\s/g, "").length);
    if (
      ((teacherForm.nidNumber || "").replace(/\s/g, "").length !== 16 &&
        (teacherForm.nidNumber || "").replace(/\s/g, "").length !== 10 &&
        !teacherForm.passportNumber) ||
      !!idHasError
    ) {
      error.nidNumberHasError = true;
      error.hasError = true;
    }

    if (
      !!teacherForm.nidNumber &&
      (teacherForm.nidNumber || "").replace(/\s/g, "").length !== 16 &&
      !teacherForm.passportNumber
    ) {
      error.passportNumberHasError = true;
      error.hasError = true;
    }

    if (!teacherForm.employeeId) {
      error.employeeIdHasError = true;
      error.hasError = true;
    }

    if (!teacherForm.sdmsStaffCode) {
      error.staffCodeHasError = true;
      error.hasError = true;
    }

    if ((teacherForm.phoneNumber || "").replace(/\s/g, "").length !== 10) {
      error.phoneNumberHasError = true;
      error.hasError = true;
    }

    if (
      !teacherForm.email ||
      (!!teacherForm.email &&
        personalEmailValidator.validate({ email: teacherForm.email }).error)
    ) {
      error.emailHasError = true;
      error.hasError = true;
    }

    if (!selectedEmploymentType) {
      error.employmentTypeHasError = true;
      error.hasError = true;
    }

    if (!teacherForm.bankId) {
      error.bankAccountNumberHasError = true;
      error.hasError = true;
    }

    if (teacherForm.hasEducationCertficate === "") {
      error.educationCertficateHasError = true;
      error.hasError = true;
    }

    // if (!selectedDegree) {
    //   error.degreeHasError = true;
    //   error.hasError = true;
    // }

    // if (!selectedSpecialization) {
    //   error.specializationHasError = true;
    //   error.hasError = true;
    // }

    if (
      !position ||
      (!!position &&
        +position?.budgetPosts <= +position?.numberOfEmployees &&
        position.id !== teacher.schoolPositionId)
    ) {
      error.positionHasError = true;
      error.hasError = true;
    }

    if (
      (!!teacherForm.positionAppointmentDate &&
        moment(teacherForm.positionAppointmentDate).format("yyyy-MM-DD") >
          moment().format("yyyy-MM-DD")) ||
      !teacherForm.positionAppointmentDate
    ) {
      error.positionAppointmentDateHasError = true;
      error.hasError = true;
    }

    if (
      (!!teacherForm.employmentDateInEducation &&
        moment(teacherForm.employmentDateInEducation).format("yyyy-MM-DD") >
          moment().format("yyyy-MM-DD")) ||
      !teacherForm.employmentDateInEducation
    ) {
      error.employmentDateInEducationHasError = true;
      error.hasError = true;
    }

    // if (
    //   (!!teacherForm.graduationDate &&
    //     new Date(teacherForm.graduationDate) > new Date()) ||
    //   !teacherForm.graduationDate
    // ) {
    //   error.graduationDateHasError = true;
    //   error.hasError = true;
    // }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const employmentTypes = [
    { id: 1, name: "Permanent" },
    { id: 2, name: "Contractual" },
  ];

  const [isVerifing, setIsVerifing] = useState(false);
  const [wasVerified, setWasVerified] = useState(teacher.isNIDVerified);
  const [idHasError, setIdHasError] = useState(false);

  const [selectedEmploymentType, setSelectedEmploymentType] = useState(
    employmentTypes.find(({ id }) => id === teacher.employmentTypeId)
  );
  const [selectedDegree, setSelectedDegree] = useState(null);
  const [selectedSpecialization, setSelectedSpecialization] = useState(null);
  const [position, setPosition] = useState(null);

  const [teacherForm, setTeacherForm] = useState({
    nidNumber: teacher.nidNumber,
    passportNumber: teacher.passportNumber,
    nin: teacher.nin,
    email: teacher.email,
    phoneNumber: teacher.phoneNumber,
    gender: teacher.gender,
    genderId: teacher.genderId,
    countryOfBirth: teacher.countryOfBirth,
    countryIdOfBirth: teacher.countryIdOfBirth,
    firstName: teacher.firstName,
    lastName: teacher.lastName,
    civilStatus: teacher.civilStatus,
    civilStatusId: teacher.civilStatusId,
    spouseNames: teacher.spouseNames,
    fatherNames: teacher.fatherNames,
    motherNames: teacher.motherNames,
    dateOfBirth: teacher.dateOfBirth,
    placeOfBirth: teacher.placeOfBirth,
    nationalityId: teacher.nationalityId,
    villageId: teacher.villageId,
    profilePicture: "",
    employeeId: teacher.id,
    rssbNumber: teacher.rssbNumber,
    accountNumber: teacher.accountNumber,
    bankId: teacher.bankId,
    bankName: teacher.bankName,
    sdmsStaffCode: teacher.id,
    hasEducationCertficate: teacher.hasEducationCertficate,
    graduationDate: !!teacher.graduationDate
      ? new Date(teacher.graduationDate)
      : null,

    employmentDateInEducation: !!teacher.employmentDateInEducation
      ? new Date(teacher.employmentDateInEducation)
      : null,
    positionAppointmentDate: !!teacher.positionAppointmentDate
      ? new Date(teacher.positionAppointmentDate)
      : null,
  });

  const getNidDetails = async (nidNumber) => {
    try {
      setWasVerified(false);
      setIsVerifing(true);
      // let staff = {};
      // try {
      //   const { data: tmisData } = await axios.get(
      //     "/api/users/search-staff/" + (nidNumber || "").replace(/\s/g, "")
      //   );

      //   staff = tmisData;
      // } catch (error) {}

      const { data } = await axios.get(
        "https://ippis.rw/api/search-tmis-employee/" +
          (nidNumber || "").replace(/\s/g, "")
      );

      setTeacherForm({
        ...teacherForm,
        nidNumber: nidNumber,
        nin: data.nin,
        gender: data.gender,
        genderId: data.genderId,
        countryOfBirth: data.countryOfBirth,
        countryIdOfBirth: data.countryIdOfBirth,
        firstName: data.firstName,
        lastName: data.lastName,
        civilStatus: data.civilStatus,
        civilStatusId: data.civilStatusId,
        spouseNames: data.spouseNames,
        fatherNames: data.fatherNames,
        motherNames: data.motherNames,
        dateOfBirth: data.dateOfBirth,
        placeOfBirth: data.placeOfBirth,
        nationalityId: data.nationalityId,
        villageId: data.villageId,
        profilePicture: data.profileImage,

        employeeId: data.employee?.id || "",
        sdmsStaffCode: data.employee?.id || "",
        rssbNumber: data.employee?.rssbNumber || "",
        accountNumber: data.employee?.accountNumber || "",
        bankId: data.employee?.bankId || "",
        bankName: data.employee?.bankName || "",

        // employmentDateInEducation: data.employee?.employmentDateInEducation || null,

        // email: staff?.email?.trim() || "",
        // phoneNumber: staff?.phoneNumber?.trim() || "",
      });

      // setChanged(true);
      setIdHasError(false);
      setWasVerified(true);
      setIsVerifing(false);
    } catch (error) {
      // setChanged(false);
      setIdHasError(true);
      setWasVerified(false);
      setIsVerifing(false);
    }
  };

  const onSave = () => {
    if (!teacherInfoFormValidator())
      addOrUpdateTeacher(
        {
          id: teacherForm.employeeId,
          lastName: teacherForm.lastName || null,
          firstName: teacherForm.firstName || null,
          email: teacherForm.email?.trim(),
          phoneNumber: teacherForm.phoneNumber?.trim(),
          nidNumber: (teacherForm.nidNumber || "").replace(/\s/g, "") || null,
          passportNumber: teacherForm.passportNumber || null,
          nin: teacherForm.nin || null,
          gender: teacherForm.genderId || null,
          dateOfBirth: teacherForm.dateOfBirth || null,
          placeOfBirth: teacherForm.placeOfBirth || null,
          fatherNames: teacherForm.fatherNames || null,
          motherNames: teacherForm.motherNames || null,
          civilStatusId: teacherForm.civilStatusId || null,
          spouseNames: teacherForm.spouseNames || null,
          countryOfBirth: teacherForm.countryIdOfBirth || null,
          nationalityId: teacherForm.nationalityId || null,
          residenceVillageId:
            village?.ippisVillageCode || teacherForm.villageId || null,
          bankId: teacherForm.bankId || null,
          bankAccountNumber: teacherForm.accountNumber || null,
          rssbNumber: teacherForm.rssbNumber || null,
          sdmsStaffCode: teacherForm.employeeId || null,
          hasEducationCertficate: teacherForm.hasEducationCertficate,
          schoolPositionId: position?.id,
          currentSchoolPositionId: teacher?.schoolPositionId || null,
          employmentTypeId: selectedEmploymentType.id,
          degreeId: selectedDegree?.id || null,
          specializationId: selectedSpecialization?.id || null,

          graduationDate: !!teacherForm.graduationDate
            ? moment(teacherForm.graduationDate).format("yyyy-MM-DD")
            : null,
          employmentDateInEducation: !!teacherForm.employmentDateInEducation
            ? moment(teacherForm.employmentDateInEducation).format("yyyy-MM-DD")
            : null,

          positionAppointmentDate: !!teacherForm.positionAppointmentDate
            ? moment(teacherForm.positionAppointmentDate).format("yyyy-MM-DD")
            : null,

          isUpdate: true,
          placementReportId: placementReport?.id || null,
          teachingLevelId: placementReport?.teachingLevelId || null,
        },
        onClose
      );
  };

  useEffect(async () => {
    setPosition(
      schoolPositions.find(({ id }) => id === teacher.schoolPositionId)
    );
  }, [teacher, schoolPositions]);

  useEffect(async () => {
    setPosition(
      schoolPositions.find(({ id }) => id === teacher.schoolPositionId)
    );

    setSelectedEmploymentType(
      employmentTypes.find(({ id }) => id === teacher.employmentTypeId)
    );

    setSelectedDegree(degrees.find(({ id }) => id === teacher.degreeId));

    setSelectedSpecialization(
      specializations.find(({ id }) => id === teacher.specializationId)
    );

    if (!!teacher.nidNumber) {
      try {
        // const { data: tmisData } = await axios.get(
        //   "/api/users/search-staff/" +
        //     (teacher.nidNumber || "").replace(/\s/g, "")
        // );

        const { data } = await axios.get(
          "https://ippis.rw/api/search-tmis-employee/" +
            (teacher.nidNumber || "").replace(/\s/g, "")
        );

        setTeacherForm({
          ...teacherForm,
          profilePicture: data.profileImage || "",
          // employmentDate: data.employee?.employmentData || null,
          // graduationDate: tmisData.graduationDate || null,
        });

        // setSelectedDegree(
        //   degrees.find(({ id }) => id === tmisData.degreeId) ||
        //     selectedDegree ||
        //     null
        // );
        // setSelectedSpecialization(
        //   specializations.find(({ id }) => id === tmisData.specializationId) ||
        //     selectedSpecialization ||
        //     null
        // );
      } catch (error) {}
    }
  }, [teacher]);

  useEffect(() => {
    getSchoolPositions("SCHOOL", teacher.schoolId);

    if (!districts.length) getDistricts();

    if (!!teacher?.residenceDistrictId)
      getSectors(teacher?.residenceDistrictId);

    if (!!teacher?.residenceSectorId) getCells(teacher?.residenceSectorId);

    if (!!teacher?.residenceCellId) getVillages(teacher?.residenceCellId);
  }, []);

  useEffect(() => {
    if (!!district?.id) getSectors(district.id);
  }, [district]);

  useEffect(() => {
    if (!!sector?.id) getCells(sector.id);
  }, [sector]);

  useEffect(() => {
    if (!!cell?.id) getVillages(cell.id);
  }, [cell]);

  useEffect(() => {
    if (!!teacher?.residenceDistrictId && districts.length)
      setDistrict(
        districts.find((d) => d.id === teacher?.residenceDistrictId) || null
      );
  }, [teacher, districts]);

  useEffect(() => {
    if (!!teacher?.residenceSectorId && sectors.length)
      setSector(
        sectors.find((d) => d.id === teacher?.residenceSectorId) || null
      );
  }, [teacher, sectors]);

  useEffect(() => {
    if (!!teacher?.residenceCellId && cells.length)
      setCell(cells.find((d) => d.id === teacher?.residenceCellId) || null);
  }, [teacher, cells]);

  useEffect(() => {
    if (!!teacher?.residenceVillageId && villages.length)
      setVillage(
        villages.find(
          (v) => v.ippisVillageCode === teacher?.residenceVillageId
        ) || null
      );
  }, [teacher, villages]);

  const getSectors = async (districtId) => {
    try {
      const { data } = await axios.get(`/api/posts/sectors/` + districtId);
      setSectors(data);
    } catch (error) {}
  };

  const getCells = async (sectorId) => {
    try {
      const { data } = await axios.get(`/api/posts/cells/` + sectorId);
      setCells(data);
    } catch (error) {}
  };

  const getVillages = async (cellId) => {
    try {
      const { data } = await axios.get(`/api/posts/villages/` + cellId);
      setVillages(data);
    } catch (error) {}
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary pb-0 mb-0 text-uppercase border">
          <span className="d-flex align-items-center mb-2">
            <Typography className="d-flex align-items-center text-primary">
              <span className="text-dark mr-1">Update Teacher Profile</span>
              {!!teacher && (
                <>
                  {teacher?.lastName} {teacher?.firstName}
                </>
              )}
            </Typography>
          </span>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 4,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="px-0">
          <div className="mt-0" style={{ height: "100%" }}>
            <div
              style={{
                display: "table-cell",
                width: "100vw",
                height: "45vh",
                padding: "10px",
                verticalAlign: "middle",
              }}
            >
              <div className="row mx-0">
                <div className="col-sm-12">
                  <React.Fragment>
                    <div>
                      <div className="row no-gutters">
                        <div className="col-2 justify-content-center  text-center">
                          <div
                            style={{
                              minHeight: "150px",
                              overflow: "hidden",
                            }}
                          >
                            <>
                              <img
                                className="mt-0 rounded  text-center"
                                src={
                                  !!teacherForm.profilePicture
                                    ? "data:image/jpg;base64," +
                                      teacherForm.profilePicture
                                    : defaultPofile
                                }
                                style={{
                                  border: "1px solid #a7a7a7",
                                  height: "150px",
                                  width: "120px",
                                  overflow: "hidden",
                                  borderRadius: "50%",
                                }}
                              />
                            </>
                          </div>

                          <div className="text-primary">
                            <strong>NID PHOTO</strong>{" "}
                          </div>
                          <label className="text-center text-uppercase  mt-n2">
                            <span style={{ fontSize: "12px" }}>
                              {teacherForm?.lastName} {teacherForm?.firstName}
                            </span>
                          </label>

                          <FormControl
                            className="mt-3 d-block border rounded pt-2"
                            style={{ backgroundColor: "#eee" }}
                          >
                            <FormLabel className="text-primary  px-2">
                              Education certficate
                            </FormLabel>
                            <RadioGroup
                              className="mt-0 d-flex justify-content-start px-4"
                              row
                              name="hasEducationCertficate"
                              value={teacherForm?.hasEducationCertficate}
                              onChange={(e) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  hasEducationCertficate: e.target.value,
                                });

                                setErrors({
                                  ...errors,
                                  educationCertficateHasError: false,
                                });
                              }}
                            >
                              <FormControlLabel
                                value={true}
                                control={<Radio disableRipple size="small" />}
                                label="Certfied"
                              />
                              <FormControlLabel
                                value={false}
                                control={<Radio disableRipple size="small" />}
                                label="Not certfied"
                              />
                            </RadioGroup>
                            {errors.educationCertficateHasError && (
                              <small className="text-danger mb-1">
                                Select if has education certficate
                              </small>
                            )}
                          </FormControl>
                        </div>

                        <div className="col-10 row mx-0">
                          <div className="col-4 justify-content-center">
                            {!idHasError && !isVerifing && !wasVerified && (
                              <span className="material-icons loaderIcon text-danger">
                                help
                              </span>
                            )}

                            {!!isVerifing && (
                              <i
                                className="fas fa-spinner spinner loaderIcon text-primary"
                                style={{ fontSize: "22px" }}
                              ></i>
                            )}

                            {!isVerifing && !!idHasError && (
                              <span className="loaderIcon text-danger d-flex align-items-center">
                                <span className="material-icons text-danger">
                                  report_problem
                                </span>
                                <span style={{ fontSize: "10px" }}>Error</span>
                              </span>
                            )}

                            {!idHasError && !isVerifing && !!wasVerified && (
                              <span className="loaderIcon text-success d-flex align-items-center">
                                <span className="material-icons text-success ">
                                  verified
                                </span>
                                <span style={{ fontSize: "10px" }}>
                                  Verified
                                </span>
                              </span>
                            )}

                            <MaskedInput
                              mask={idNumberPattern}
                              className="form-control"
                              placeholder="NID Number"
                              guide={false}
                              value={teacherForm.nidNumber || ""}
                              onKeyPress={(e) => {
                                if (
                                  e.key === "Enter" &&
                                  !!e.target.value.length
                                ) {
                                  getNidDetails(e.target.value);
                                }
                              }}
                              onChange={(e) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  nidNumber: e.target.value,
                                });

                                if (e.target.value.length === 21) {
                                  getNidDetails(e.target.value);
                                } else {
                                  setIdHasError(false);
                                  setWasVerified(false);
                                  setIsVerifing(false);
                                }
                                setErrors({
                                  ...errors,
                                  nidNumberHasError: false,
                                  passportNumberHasError: false,
                                  emailHasError: false,
                                  phoneNumberHasError: false,
                                  employeeIdHasError: false,
                                  staffCodeHasError: false,
                                  bankAccountNumberHasError: false,
                                  hasError: false,
                                });
                              }}
                            />

                            {errors.nidNumberHasError && (
                              <small className="text-danger mb-1">
                                {!!teacherForm.nidNumber
                                  ? "Invalid NID Number"
                                  : " NID Number is required"}
                              </small>
                            )}
                          </div>
                          <div className="col-4 text-left">
                            <TextField
                              fullWidth
                              size="small"
                              autoComplete="false"
                              name="passportNumber"
                              label="Passport number"
                              placeholder="Passport number"
                              variant="outlined"
                              value={teacherForm.passportNumber || ""}
                              onChange={(e) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  passportNumber: e.target.value,
                                });

                                setErrors({
                                  ...errors,
                                  passportNumberHasError: false,
                                });
                              }}
                            />
                            {errors.passportNumberHasError && (
                              <small className="text-danger mt-1">
                                Passport number is required
                              </small>
                            )}
                          </div>

                          <div className="col-2 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="civilStatus"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Civil Status"
                              variant="outlined"
                              value={teacherForm.civilStatus || ""}
                            />
                          </div>
                          <div className="col-2 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="gender"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Gender"
                              variant="outlined"
                              className=" font-weight-bold"
                              value={teacherForm.gender || ""}
                            />
                          </div>
                          <div className="col-4  mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="lastName"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Last Name"
                              variant="outlined"
                              className=""
                              value={teacherForm.lastName || ""}
                            />
                          </div>
                          <div className="col-4  mt-3  text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="firstName"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="First Name"
                              variant="outlined"
                              className=""
                              value={teacherForm.firstName || ""}
                            />
                          </div>

                          <div className="col-4  mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="dateOfBirth"
                              value={
                                !!teacherForm.dateOfBirth
                                  ? moment(teacherForm.dateOfBirth).format(
                                      "DD/MM/yyyy"
                                    )
                                  : ""
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Date Of Birth"
                              variant="outlined"
                            />
                          </div>
                          <div className="col-4  mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="countryOfBirth"
                              value={teacherForm.countryOfBirth || ""}
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Country of Birth"
                              variant="outlined"
                              className=""
                            />
                          </div>
                          <div className="col-4  mt-3 text-left">
                            <TextField
                              InputProps={{
                                readOnly: true,
                              }}
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="placeOfBirth"
                              value={teacherForm.placeOfBirth || ""}
                              label="Place of Birth"
                              variant="outlined"
                              className=""
                            />
                          </div>

                          <div className="col-4  mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="nationality"
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Nationality"
                              placeholder="Nationality"
                              variant="outlined"
                              className=" font-weight-bold"
                              value={teacherForm.nationalityId || ""}
                            />
                          </div>

                          <div className="col-4 mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="employeeId"
                              value={teacherForm?.employeeId || ""}
                              InputProps={{
                                readOnly: true,
                              }}
                              label="IPPIS Employee ID"
                              placeholder="IPPIS Employee ID"
                              variant="outlined"
                            />

                            {errors.employeeIdHasError && (
                              <small className="text-danger mt-1">
                                Employee Id is required
                              </small>
                            )}
                          </div>
                          <div className="col-4 mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="sdmsStaffCode"
                              value={teacherForm?.sdmsStaffCode || ""}
                              label="TMIS Staff Code"
                              placeholder="TMIS Staff Code"
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                              }}
                              onChange={(e) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  sdmsStaffCode: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  sdmsStaffCodeHasError: false,
                                });
                              }}
                            />

                            {errors.staffCodeHasError && (
                              <small className="text-danger mt-1">
                                Staff Code is required
                              </small>
                            )}
                          </div>

                          <div className="col-4 mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="rssbNumber"
                              value={teacherForm?.rssbNumber || ""}
                              InputProps={{
                                readOnly: true,
                              }}
                              label="RSSB Number"
                              placeholder="RSSB Number"
                              variant="outlined"
                              className=""
                            />

                            {errors.employeeIdHasError && (
                              <small className="text-danger mt-1">
                                RSSB Number is required
                              </small>
                            )}
                          </div>

                          {/* <div className="col-3  mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="employmentDate"
                              value={
                                !!teacherForm.employmentDate
                                  ? moment(teacherForm.employmentDate).format(
                                      "DD/MM/yyyy"
                                    )
                                  : ""
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Employment date in education"
                              variant="outlined"
                            />

                            {errors.employmentDateHasError && (
                              <small className="text-danger mt-1">
                                Employment date is required
                              </small>
                            )}
                          </div> */}

                          <div className="col-4 mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="bankAccount"
                              value={
                                !teacherForm.accountNumber
                                  ? ""
                                  : teacherForm.accountNumber +
                                    " (" +
                                    teacherForm.bankName +
                                    ")"
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Bank Account"
                              placeholder="Bank Account"
                              variant="outlined"
                            />

                            {!!errors.bankAccountNumberHasError && (
                              <small className="text-danger">
                                Bank account is required
                              </small>
                            )}
                          </div>

                          <div className="col-8 mt-3 ">
                            <Autocomplete
                              style={{
                                backgroundColor:
                                  ["REB", "RTB"].includes(
                                    user.selectedEntity?.role
                                  ) ||
                                  (["HEAD_TEACHER"].includes(
                                    user.selectedEntity?.role
                                  ) &&
                                    user.selectedEntity.scopeId === "RTB")
                                    ? ""
                                    : "#e9ecef",
                              }}
                              readOnly={
                                !(
                                  ["REB", "RTB"].includes(
                                    user.selectedEntity?.role
                                  ) ||
                                  (["HEAD_TEACHER"].includes(
                                    user.selectedEntity?.role
                                  ) &&
                                    user.selectedEntity.scopeId === "RTB")
                                )
                              }
                              size="small"
                              defaultValue={null}
                              value={position || null}
                              options={schoolPositions}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <div>
                                    <span className="d-block text-primary">
                                      {option.name}
                                    </span>

                                    <small className="d-block mb-1 ">
                                      <span
                                        className={`badge badge badge-${
                                          +option.budgetPosts -
                                            +option.numberOfEmployees >
                                          0
                                            ? "success"
                                            : "danger"
                                        } mr-1`}
                                      >
                                        {+option.budgetPosts -
                                          +option.numberOfEmployees}{" "}
                                        Vacant
                                        {+option.budgetPosts -
                                          +option.numberOfEmployees >
                                        1
                                          ? "s"
                                          : ""}
                                      </span>
                                      /
                                      <span className="badge badge-secondary ml-1">
                                        {option.budgetPosts} Budget post
                                        {option.budgetPosts > 1 ? "s" : ""}
                                      </span>
                                    </small>
                                    <Divider />
                                    <Divider />
                                  </div>
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="position"
                                  label="Position"
                                  placeholder="Position"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                              onChange={(event, position) => {
                                setPosition(position || null);

                                setErrors({
                                  ...errors,
                                  positionHasError:
                                    (!!position &&
                                      +position?.budgetPosts -
                                        +position?.numberOfEmployees >
                                        0) ||
                                    position.id === teacher.schoolPositionId
                                      ? false
                                      : true,
                                });
                              }}
                            />
                            {errors.positionHasError && (
                              <small className="text-danger">
                                {!!position
                                  ? "No vacant post available"
                                  : "Position is required"}
                              </small>
                            )}
                          </div>

                          <div className="col-2 mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="qualificationLevel"
                              value={position?.qualificationLevelName || ""}
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Qualification"
                              placeholder="Qualification"
                              variant="outlined"
                            />
                          </div>

                          <div className="col-2 mt-3 text-left">
                            <TextField
                              style={{ backgroundColor: "#e9ecef" }}
                              fullWidth
                              size="small"
                              name="teachingLevel"
                              value={position?.teachingLevelName || ""}
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Teaching Level"
                              placeholder="Teaching Level"
                              variant="outlined"
                            />
                          </div>

                          <div className="col-4 mt-3 ">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                readOnly={
                                  !(
                                    ["REB", "RTB"].includes(
                                      user.selectedEntity?.role
                                    ) ||
                                    (["HEAD_TEACHER"].includes(
                                      user.selectedEntity?.role
                                    ) &&
                                      user.selectedEntity.scopeId === "RTB")
                                  )
                                }
                                label="Position appointment date"
                                placeholder="Position appointment date"
                                inputFormat="dd/MM/yyyy"
                                maxDate={new Date()}
                                value={
                                  teacherForm.positionAppointmentDate || null
                                }
                                onChange={(date) => {
                                  setTeacherForm({
                                    ...teacherForm,
                                    positionAppointmentDate: date,
                                  });

                                  setErrors({
                                    ...errors,
                                    positionAppointmentDateHasError: false,
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    style={{
                                      backgroundColor:
                                        ["REB", "RTB"].includes(
                                          user.selectedEntity?.role
                                        ) ||
                                        (["HEAD_TEACHER"].includes(
                                          user.selectedEntity?.role
                                        ) &&
                                          user.selectedEntity.scopeId === "RTB")
                                          ? ""
                                          : "#e9ecef",
                                    }}
                                    size="small"
                                    fullWidth
                                    {...params}
                                  />
                                )}
                              />

                              {errors.positionAppointmentDateHasError && (
                                <small className="text-danger mt-1">
                                  {!!teacherForm.positionAppointmentDate
                                    ? "Invalid date"
                                    : "Position appointment date is required"}
                                </small>
                              )}
                            </LocalizationProvider>
                          </div>

                          <div className="col-4 mt-3 ">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                readOnly={
                                  !(
                                    ["REB", "RTB"].includes(
                                      user.selectedEntity?.role
                                    ) ||
                                    (["HEAD_TEACHER"].includes(
                                      user.selectedEntity?.role
                                    ) &&
                                      user.selectedEntity.scopeId === "RTB")
                                  )
                                }
                                label="Employment date in education"
                                placeholder="Employment date in education"
                                inputFormat="dd/MM/yyyy"
                                maxDate={new Date()}
                                value={
                                  teacherForm.employmentDateInEducation || null
                                }
                                onChange={(date) => {
                                  setTeacherForm({
                                    ...teacherForm,
                                    employmentDateInEducation: date,
                                  });

                                  setErrors({
                                    ...errors,
                                    employmentDateInEducationHasError: false,
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    style={{
                                      backgroundColor: !(
                                        ["REB", "RTB"].includes(
                                          user.selectedEntity?.role
                                        ) ||
                                        (["HEAD_TEACHER"].includes(
                                          user.selectedEntity?.role
                                        ) &&
                                          user.selectedEntity.scopeId === "RTB")
                                      )
                                        ? "#e9ecef"
                                        : "",
                                    }}
                                    size="small"
                                    fullWidth
                                    {...params}
                                  />
                                )}
                              />

                              {errors.employmentDateInEducationHasError && (
                                <small className="text-danger mt-1">
                                  {!!teacherForm.employmentDateInEducation
                                    ? "Invalid date"
                                    : "Employment date is required"}
                                </small>
                              )}
                            </LocalizationProvider>
                          </div>

                          <div className="col-4  mt-3 text-left">
                            <TextField
                              fullWidth
                              size="small"
                              autoComplete="false"
                              name="phone"
                              label="Phone number"
                              placeholder="Phone number"
                              variant="outlined"
                              value={teacherForm.phoneNumber || ""}
                              onChange={(e) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  phoneNumber: e.target.value,
                                });

                                setErrors({
                                  ...errors,
                                  phoneNumberHasError: false,
                                });
                              }}
                            />
                            {errors.phoneNumberHasError && (
                              <small className="text-danger mt-1">
                                {!!teacherForm.phoneNumber
                                  ? "Invalid Phone Number"
                                  : " Phone Number is required"}
                              </small>
                            )}
                          </div>

                          <div className="col-4  mt-3 text-left">
                            <TextField
                              fullWidth
                              size="small"
                              autoComplete="false"
                              name="email"
                              label="Personal email"
                              placeholder="Personal email"
                              variant="outlined"
                              value={teacherForm.email || ""}
                              onChange={(e) => {
                                setTeacherForm({
                                  ...teacherForm,
                                  email: e.target.value,
                                });
                                setErrors({
                                  ...errors,
                                  emailHasError: false,
                                });
                              }}
                            />

                            {errors.emailHasError && (
                              <small className="text-danger">
                                {!!teacherForm.email
                                  ? "Invalid Personal email"
                                  : " Personal email is required"}
                              </small>
                            )}
                          </div>

                          <div className="mt-3 col-4">
                            <Autocomplete
                              size="small"
                              defaultValue={null}
                              value={selectedEmploymentType}
                              options={employmentTypes}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="employmentType"
                                  label="Employment Type"
                                  placeholder="Employment Type"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                              onChange={(event, employmentType) => {
                                setSelectedEmploymentType(
                                  employmentType || null
                                );

                                setErrors({
                                  ...errors,
                                  employmentTypeHasError: false,
                                });
                              }}
                            />
                            {errors.employmentTypeHasError && (
                              <small className="text-danger">
                                Employment Type is required
                              </small>
                            )}
                          </div>

                          <div className="mt-3 col-4">
                            <Autocomplete
                              size="small"
                              defaultValue={null}
                              value={selectedDegree}
                              options={degrees}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Highest Degree"
                                  name="degree"
                                  placeholder="Highest Degree"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                              onChange={(event, degree) => {
                                setSelectedDegree(degree || null);

                                setErrors({
                                  ...errors,
                                  degreeHasError: false,
                                });
                              }}
                            />
                            {errors.degreeHasError && (
                              <small className="text-danger">
                                Highest Degree is required
                              </small>
                            )}
                          </div>
                          <div className="mt-3 col-4">
                            <Autocomplete
                              size="small"
                              defaultValue={null}
                              value={selectedSpecialization}
                              options={specializations}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  {option.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Specialization"
                                  name="specialization"
                                  placeholder="Specialization"
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                              onChange={(event, specialization) => {
                                setSelectedSpecialization(
                                  specialization || null
                                );

                                setErrors({
                                  ...errors,
                                  specializationHasError: false,
                                });
                              }}
                            />
                            {errors.specializationHasError && (
                              <small className="text-danger">
                                Specialization is required
                              </small>
                            )}
                          </div>
                          <div className="mt-3 col-4">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                label="Graduation date"
                                inputFormat="dd/MM/yyyy"
                                maxDate={new Date()}
                                value={teacherForm.graduationDate || null}
                                onChange={(date) => {
                                  setTeacherForm({
                                    ...teacherForm,
                                    graduationDate: date,
                                  });

                                  setErrors({
                                    ...errors,
                                    graduationDateHasError: false,
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    fullWidth
                                    {...params}
                                  />
                                )}
                              />

                              {errors.graduationDateHasError && (
                                <small className="text-danger mt-1">
                                  {!!teacherForm.graduationDate
                                    ? "Invalid Date"
                                    : "Graduation Date is required"}
                                </small>
                              )}
                            </LocalizationProvider>
                          </div>
                        </div>

                        <div className="col-12 px-4 mt-2">
                          <fieldset
                            style={{ backgroundColor: "#ccefff75" }}
                            className={`row p-3 rounded form-group border border-primary justify-content-center`}
                          >
                            <legend
                              className={`col-12 w-auto px-2 text-primary`}
                              style={{
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Physical address{" "}
                              {!!teacher.isProfileLocked && (
                                <Tooltip title="Updated">
                                  <span className="text-success">
                                    <DoneAllIcon />
                                  </span>
                                </Tooltip>
                              )}
                            </legend>

                            <div className="col-12 col-md-6 mb-2 mt-2">
                              <Autocomplete
                                size="small"
                                defaultValue={null}
                                value={district}
                                options={districts || []}
                                disabled={loading}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                onChange={(event, district) => {
                                  setDistrict(district || null);

                                  setSector(null);
                                  setCell(null);
                                  setVillage(null);

                                  setSectors([]);
                                  setCells([]);
                                  setVillages([]);
                                }}
                                getOptionLabel={(option) => option.name || ""}
                                renderOption={(props, district) => (
                                  <Box
                                    component="li"
                                    {...props}
                                    key={district.id}
                                  >
                                    {district.name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    style={{
                                      height: "25px !important",
                                      position: "relative",
                                      borderRadius: "8px",
                                      padding: "1px !important",
                                    }}
                                    {...params}
                                    label="Residence district"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "name", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                              />
                            </div>
                            <div className="col-12 col-md-6 mb-2 mt-2">
                              <Autocomplete
                                size="small"
                                defaultValue={null}
                                value={sector}
                                options={sectors}
                                disabled={loading || !sectors.length}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                onChange={(event, sector) => {
                                  setSector(sector || null);

                                  setCell(null);
                                  setVillage(null);

                                  setCells([]);
                                  setVillages([]);
                                }}
                                getOptionLabel={(option) => option.name || ""}
                                renderOption={(props, sector) => (
                                  <Box
                                    component="li"
                                    {...props}
                                    key={sector.id}
                                  >
                                    {sector.name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    style={{
                                      height: "25px !important",
                                      position: "relative",
                                      borderRadius: "8px",
                                      padding: "1px !important",
                                    }}
                                    {...params}
                                    label="Residence sector"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "name", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                              />
                            </div>
                            <div className="col-12 col-md-6 mb-2 mt-2">
                              <Autocomplete
                                size="small"
                                defaultValue={null}
                                value={cell || null}
                                options={cells}
                                disabled={loading || !cells.length}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                onChange={(event, cell) => {
                                  setCell(cell || null);
                                  setVillage(null);
                                  setVillages([]);
                                }}
                                getOptionLabel={(option) => option.name || ""}
                                renderOption={(props, cell) => (
                                  <Box component="li" {...props} key={cell.id}>
                                    {cell.name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    style={{
                                      height: "25px !important",
                                      position: "relative",
                                      borderRadius: "8px",
                                      padding: "1px !important",
                                    }}
                                    {...params}
                                    label="Residence cell"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "name", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                              />
                            </div>
                            <div className="col-12 col-md-6 mb-2 mt-2">
                              <Autocomplete
                                size="small"
                                defaultValue={null}
                                value={village || null}
                                options={villages.filter(
                                  (v) => !!v.ippisVillageCode
                                )}
                                disabled={loading || !villages.length}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                onChange={(event, village) =>
                                  setVillage(village || null)
                                }
                                getOptionLabel={(option) => option.name || ""}
                                renderOption={(props, village) => (
                                  <Box
                                    component="li"
                                    {...props}
                                    key={village.id}
                                  >
                                    {village.name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    style={{
                                      height: "25px !important",
                                      position: "relative",
                                      borderRadius: "8px",
                                      padding: "1px !important",
                                    }}
                                    {...params}
                                    label="Residence village"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "name", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                              />
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className=" d-flex justify-content-center py-4">
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            disableElevation
            size="sm"
            className="px-5"
            onClick={onSave}
          >
            {loading ? "Wait..." : "Save Changes"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  onWait,
  placementReportDetails,
  changeList,
  prevPlacementReport,
  signatories,
  roles,
  districts,
  degrees,
  specializations,
  schoolPositions,
}) => {
  return {
    user,
    loading,
    onWait,
    placementReportDetails,
    changeList,
    prevPlacementReport,
    signatories,
    roles,
    districts,
    degrees,
    specializations,
    schoolPositions,
  };
};
export default connect(mapStateToProps, {
  addOrUpdateTeacher,
  getSchoolPositions,
  getDistricts,
})(UpdateTeacherDialog);
