import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

import { connect } from "react-redux";
import { saveScholarshipSchedule } from "../../../../store/scholarship/actions";
import { useEffect } from "react";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";

const ScholarshipScheduleDialog = (props) => {
  const {
    loading,
    showDialog,
    setShowDialog,
    saveScholarshipSchedule,
    scholarshipSchedule,
  } = props;

  const [formData, setFormData] = useState({
    id: scholarshipSchedule?.id || null,
    startOn: scholarshipSchedule?.startOn || null,
    endOn: scholarshipSchedule?.endOn || null,
  });

  const onSave = (e) => {
    e.preventDefault();
    saveScholarshipSchedule(
      {
        startOn: moment(formData.startOn).format("yyyy-MM-DD"),
        endOn: moment(formData.endOn).format("yyyy-MM-DD"),
      },
      () => setShowDialog(false)
    );
  };

  return (
    <>
      <Dialog
        onClose={() => {
          setShowDialog(false);
        }}
        fullWidth
        maxWidth="xs"
        open={showDialog}
      >
        <DialogTitle>
          <Typography className="text-center text-uppercase text-primary">
            Scholarship Schedule
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="row align-items-center mx-0 justify-content-center mt-3">
            <div className="col-11 mb-2">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  minDate={new Date()}
                  label="Start on"
                  inputFormat="dd/MM/yyyy"
                  value={formData.startOn || null}
                  onChange={(date) => {
                    setFormData({
                      ...formData,
                      startOn: date,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      name="scholarshipGraduationDate"
                      onKeyDown={(e) => e.preventDefault()}
                      size="small"
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="col-11 mb-2">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  disabled={!formData.startOn}
                  minDate={new Date()}
                  label="End on"
                  inputFormat="dd/MM/yyyy"
                  value={formData.endOn || null}
                  onChange={(date) => {
                    setFormData({
                      ...formData,
                      endOn: date,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      name="scholarshipGraduationDate"
                      onKeyDown={(e) => e.preventDefault()}
                      size="small"
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4">
          <Button
            color="primary"
            variant="contained"
            onClick={onSave}
            type="button"
            disabled={loading || !formData.startOn || !formData.endOn}
            className="px-5"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return { loading };
};
export default connect(mapStateToProps, {
  saveScholarshipSchedule,
  // deletescholarshipSchedule,
})(ScholarshipScheduleDialog);
