import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import ChartCard from "../../../common/components/ChartCard";
import SexDistributionChart from "./charts/SexDistributionChart";
import AgeDistributionChart from "./charts/AgeDistributionChart";
import QualificationDistributionChart from "./charts/QualificationDistributionChart";
import PositionDistributionChart from "./charts/PositionDistributionChart";
import HighestDegreeDistributionChart from "./charts/HighestDegreeDistributionChart";
import NationalityDistributionChart from "./charts/NationalityDistributionChart";
import EmploymentDateChart from "./charts/EmploymentDateChart";
import ExitReasonsChart from "./charts/ExitReasonsChart";
import ExitDateChart from "./charts/ExitDateChart";
import TeacherDistrictSuspensionChart from "./charts/TeacherDistrictSuspensionChart";
import TeacherDistrictDistributionChart from "./charts/TeacherDistrictDistributionChart";
import TeachingLevelDistributionChart from "./charts/TeachingLevelDistributionChart";
import TeacherPositionSuspension from "./charts/TeacherPositionSuspension";
import OversightSkeleton from "../../../common/components/OversightSkeleton"; // Import the new Skeleton component

const PlacementOversight = (props) => {
  const { user, loading } = props;

  const [isLoading1, setIsLoading1] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    if (user?.selectedEntity) {
      getPlacementOversights();
    }
  }, [user?.selectedEntity]);

  const getPlacementOversights = async () => {
    try {
      setIsLoading1(true);
      const { data } = await axios.get(
        `/api/oversight/staff-placements/${user?.selectedEntity?.id}/${user?.selectedEntity?.scopeId}`
      );

      setData(data);
      setIsLoading1(false);
    } catch (error) {
      setIsLoading1(false);
    }
  };

  if (isLoading1 || loading) {
    return <OversightSkeleton />;
  }

  return (
    <div className="pl-4 pt-2">
      <div className="row w-100">
        <div className="col-12 col-md-6 p-0">
          <ChartCard
            title="Gender Distribution"
            subheader="Gender distribution of teachers"
          >
            <SexDistributionChart data={data?.genderDistributions} />
          </ChartCard>
        </div>
        <div className="col-12 col-md-6 p-0">
          <ChartCard
            title="Age Distribution"
            subheader="Age distribution of teachers"
          >
            <AgeDistributionChart data={data?.ageDistributions} />
          </ChartCard>
        </div>
        <div className="col-12 col-md-6 p-0">
          <ChartCard
            title="Teacher Suspensions by District"
            subheader="Number of teacher suspensions by district"
          >
            <TeacherDistrictSuspensionChart
              data={data?.teachersDistrictSuspensionDistributions}
            />
          </ChartCard>
        </div>
        <div className="col-12 col-md-6 p-0">
          <ChartCard
            title="Teaching Level Distribution"
            subheader="Distribution of teachers by teaching level"
          >
            <TeachingLevelDistributionChart
              data={data?.teachingLevelDistributions}
            />
          </ChartCard>
        </div>
        <div className="col-12 col-md-6 p-0">
          <ChartCard
            title="Highest Degree Distribution"
            subheader="Distribution of teachers by highest degree"
          >
            <HighestDegreeDistributionChart
              data={data?.highestDegreeDistributions}
            />
          </ChartCard>
        </div>
        <div className="col-12 col-md-6 p-0">
          <ChartCard
            title="Nationality Distribution"
            subheader="Nationality breakdown of teachers"
          >
            <NationalityDistributionChart
              data={data?.nationalityDistributions}
            />
          </ChartCard>
        </div>
        <div className="col-12 col-md-6 p-0">
          <ChartCard
            title="Exit Reasons"
            subheader="Reasons for teacher exits (resignation, retirement, etc.)"
          >
            <ExitReasonsChart data={data?.exitReasonDistributions} />
          </ChartCard>
        </div>
        <div className="col-12 col-md-6 p-0">
          <ChartCard
            title="Employment Date"
            subheader="Teacher hire date distribution"
          >
            <EmploymentDateChart data={data?.employmentDateDistributions} />
          </ChartCard>
        </div>
        <div className="col-12 col-md-6 p-0">
          <ChartCard title="Exit Date" subheader="Exit date of teachers">
            <ExitDateChart data={data?.exitDateDistributions} />
          </ChartCard>
        </div>
        <div className="col-12 col-md-6 p-0">
          <ChartCard
            title="Teacher Distribution by District"
            subheader="Geographic distribution of teachers by district"
          >
            <TeacherDistrictDistributionChart
              data={data?.teachersDistrictDistributions}
            />
          </ChartCard>
        </div>

        <div className="col-12 col-md-6 p-0">
          <ChartCard
            title="Qualification Distribution"
            subheader="Distribution of teachers by qualification"
          >
            <QualificationDistributionChart
              data={data?.qualificationDistributions}
            />
          </ChartCard>
        </div>
        <div className="col-12 col-md-6 p-0">
          <ChartCard
            title="Teacher Suspensions by Position"
            subheader="Number of teacher suspensions by position"
          >
            <TeacherPositionSuspension
              data={data?.teachersPositionSuspensionDistributions}
            />
          </ChartCard>
        </div>
        <div className="col-12 p-0">
          <ChartCard
            title="Position Distribution"
            subheader="Distribution of staff by position"
          >
            <PositionDistributionChart data={data?.positionDistributions} />
          </ChartCard>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return { user, loading };
};

export default connect(mapStateToProps, {})(PlacementOversight);
