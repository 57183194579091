import {
  CartesianGrid,
  Line,
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function MaternityLeaveRequestsChart({ data }) {
  const getChartData = () => {
    return data.map((item) => ({ month: item.month, count: item.count }));
  };

  const chartData = getChartData();

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        height={300}
        data={chartData}
        margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip
          formatter={(value, label) => [`${value?.toLocaleString()}`, label]}
        />
        <Legend />
        <Line
          type="monotone"
          dataKey="count"
          stroke="hsl(var(--chart-1))"
          strokeWidth={2}
          dot={{ r: 4 }}
          activeDot={{ r: 6 }}
          name="Requests"
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
