import structure from "./action-types";
import axios from "axios";
import { showSuccess, showError } from "../../app/toastify";
import commonTypes from "../common/action-types";
import download from "downloadjs";

const types = { ...commonTypes, ...structure };

export const getRoles = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/users/roles");

      dispatch({
        type: types.SET_ROLES,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const getDegrees = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/users/degrees");

      dispatch({
        type: types.SET_DEGREES,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const getSpecializations = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/users/specializations");

      dispatch({
        type: types.SET_SPECIALIZATIONS,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const getSystemUsers = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({
        type: types.SET_SYSTEM_USERS,
        data: [],
      });

      const { data } = await axios.get("/api/users/system-users");

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_SYSTEM_USERS,
        data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const addNewSystemUser = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/users/system-users", formData);
      onClose();

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.ADD_OR_UPDATE_SYSTEM_USER,
        data,
      });
      showSuccess("Saved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const removeSystemUser = ({ userEntityId, role }, setShowDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.post("/api/users/delete-system-users", {
        userEntityId,
        role,
      });

      setShowDialog(false);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.REMOVE_SYSTEM_USER,
        userEntityId,
      });
      showSuccess("Removed successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const ressetPassword = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/users/resset-password", formData);
      onClose();

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.ADD_OR_UPDATE_SYSTEM_USER,
        data,
      });

      showSuccess("Saved successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getTeacherProfile = (teacherId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_TEACHER_PROFILE,
        data: {},
      });

      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get(
        "/api/users/teacher-profile/" + teacherId
      );

      dispatch({
        type: types.SET_TEACHER_PROFILE,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const updateTeacherResidence = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(
        "/api/users/teacher-residence",
        payload
      );

      dispatch({
        type: types.SET_TEACHER_PROFILE,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
