import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import ChartCard from "../../../common/components/ChartCard";
import TransferTypeChart from "./charts/TransferTypeChart";
import OversightSkeleton from "../../../common/components/OversightSkeleton";

const TransferOversight = (props) => {
  const { user, loading } = props;

  const [isLoading1, setIsLoading1] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    if (user?.selectedEntity) {
      getPlacementOversights();
    }
  }, [user?.selectedEntity]);

  const getPlacementOversights = async () => {
    try {
      setIsLoading1(true);
      const { data } = await axios.get(
        `/api/oversight/transfers/${user?.selectedEntity?.id}/${user?.selectedEntity?.scopeId}`
      );

      setData(data);
      setIsLoading1(false);
    } catch (error) {
      setIsLoading1(false);
    }
  };

  if (isLoading1 || loading) {
    return <OversightSkeleton />;
  }

  return (
    <div className="pl-4 pt-2">
      <div className="row w-100">
        <div className="col-12 col-md-6 p-0">
          <ChartCard
            title="Transfer Type"
            subheader="Breakdown of internal vs external transfers"
          >
            <TransferTypeChart data={data?.transferTypeDistributions} />
          </ChartCard>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return { user, loading };
};

export default connect(mapStateToProps, {})(TransferOversight);
