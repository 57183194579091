import React from "react";
import { Skeleton } from "@mui/material";

const OversightSkeleton = () => {
  return (
    <div className="pl-4 pt-2">
      <div className="row w-100">
        <div className="col-12 col-md-6 p-0 ">
          <Skeleton
            variant="rectangle"
            width="98%"
            height={400}
            className="mx-auto mt-2 mb-2"
            style={{ borderRadius: 4 }}
          />
        </div>
        <div className="col-12 col-md-6 p-0 ">
          <Skeleton
            variant="rectangle"
            width="98%"
            height={400}
            className="mx-auto mt-2 mb-2"
            style={{ borderRadius: 4 }}
          />
        </div>
        <div className="col-12 col-md-6 p-0 ">
          <Skeleton
            variant="rectangle"
            width="98%"
            height={400}
            className="mx-auto mt-2 mb-2"
            style={{ borderRadius: 4 }}
          />
        </div>
        <div className="col-12 col-md-6 p-0 ">
          <Skeleton
            variant="rectangle"
            width="98%"
            height={400}
            className="mx-auto mt-2 mb-2"
            style={{ borderRadius: 4 }}
          />
        </div>
        <div className="col-12 col-md-6 p-0 ">
          <Skeleton
            variant="rectangle"
            width="98%"
            height={400}
            className="mx-auto mt-2 mb-2"
            style={{ borderRadius: 4 }}
          />
        </div>
        <div className="col-12 col-md-6 p-0 ">
          <Skeleton
            variant="rectangle"
            width="98%"
            height={400}
            className="mx-auto mt-2 mb-2"
            style={{ borderRadius: 4 }}
          />
        </div>
        <div className="col-12 col-md-6 p-0 ">
          <Skeleton
            variant="rectangle"
            width="98%"
            height={400}
            className="mx-auto mt-2 mb-2"
            style={{ borderRadius: 4 }}
          />
        </div>
        <div className="col-12 col-md-6 p-0 ">
          <Skeleton
            variant="rectangle"
            width="98%"
            height={400}
            className="mx-auto mt-2 mb-2"
            style={{ borderRadius: 4 }}
          />
        </div>
      </div>
    </div>
  );
};

export default OversightSkeleton;
