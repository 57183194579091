import { ResponsiveContainer, Cell, Pie, PieChart, Tooltip, Legend } from "recharts";

export default function SexDistributionChart({ data }) {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          nameKey="name"
          label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={index === 0 ? "hsl(var(--chart-1))" : "hsl(var(--chart-2))"}
            />
          ))}
        </Pie>
        <Tooltip formatter={(value, label) => [`${value?.toLocaleString()} ${value == 1 ? "Teacher" : "Teachers"}`, label]}/>
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
}
