import {
  ResponsiveContainer,
  Cell,
  Pie,
  PieChart,
  Tooltip,
  Legend,
} from "recharts";

const convertToPieData = (data) => {
  return data.map((item) => ({
    name: item.qualification,
    value: item.count,
  }));
};

export default function QualificationDistributionChart({ data }) {
  const pieData = convertToPieData(data);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={pieData}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius={100}
          fill="#8884d8"
          dataKey="value"
          nameKey="name"
          label={({ name, percent }) =>
            `${name}: ${(percent * 100).toFixed(0)}%`
          }
        >
          {pieData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={`hsl(var(--chart-${index + 1}))`}
            />
          ))}
        </Pie>
        <Tooltip
          formatter={(value, label) => [
            `${value?.toLocaleString()} ${value == 1 ? "Teacher" : "Teachers"}`,
            label,
          ]}
        />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
}
