import {
  CartesianGrid,
  Line,
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const convertToLineData = (data) => {
  return data.map((item) => ({
    name: item.nationality,
    value: item.count,
  }));
};

export default function NationalityDistributionChart({ data }) {
  const lineData = convertToLineData(data);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={lineData}
        margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip
          formatter={(value, label) => [
            `${value?.toLocaleString()} ${
              value == 1 ? "Teacher" : "Teachers"
            }`,
            label,
          ]}
        />
        <Legend />
        <Line
          type="monotone"
          dataKey="value"
          stroke="hsl(var(--chart-1))"
          strokeWidth={2}
          dot={{ r: 4 }}
          activeDot={{ r: 6 }}
          name="Nationality"
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
