import {
  IconButton,
  Dialog,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  Box,
  CircularProgress,
  DialogContent,
  DialogActions,
  Tooltip,
  Button,
  TextField,
  Autocomplete,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { currencyFormat } from "../../common/components/Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PreviewIcon from "@mui/icons-material/Preview";
import {
  addOrUpdateTeacher,
  getSchoolPositions,
  getDistricts,
} from "../../../../store/posts/actions";
import { getSectors } from "../../../../store/posts/actions";
import {
  getTeacherProfile,
  updateTeacherResidence,
} from "../../../../store/users/actions";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";

import { isEmpty } from "lodash";
import axios from "axios";
import { CSVLink } from "react-csv";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import moment from "moment";
import { showError } from "../../../toastify";
import defaultPofile from "../../../assets/default-profile.jpg";
// import { idNumberPattern } from "../../common/components/Utils";
import LockIcon from "@mui/icons-material/Lock";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import MaskedInput from "react-text-mask";
import joi from "joi";

import { Link } from "react-router-dom";

const personalEmailValidator = joi.object({
  email: joi.string().email({
    minDomainSegments: 2,
    tlds: { allow: ["com", "fr", "rw"] },
  }),
});

const TeacherProfile = (props) => {
  useEffect(() => {
    document.title = "TMIS | Teacher profile";
  }, []);

  const {
    user,
    loading,
    teacherProfile,
    getTeacherProfile,
    getDistricts,
    districts,
    updateTeacherResidence,
  } = props;

  const [sectors, setSectors] = useState([]);
  const [cells, setCells] = useState([]);
  const [villages, setVillages] = useState([]);

  const [district, setDistrict] = useState(null);
  const [sector, setSector] = useState(null);
  const [cell, setCell] = useState(null);
  const [village, setVillage] = useState(null);

  useEffect(() => {
    getTeacherProfile(user.id);
    if (!districts.length) getDistricts();

    if (!!teacherProfile?.residenceDistrictId)
      getSectors(teacherProfile?.residenceDistrictId);

    if (!!teacherProfile?.residenceSectorId)
      getCells(teacherProfile?.residenceSectorId);

    if (!!teacherProfile?.residenceCellId)
      getVillages(teacherProfile?.residenceCellId);
  }, []);

  useEffect(() => {
    if (!!district?.id) getSectors(district.id);
  }, [district]);

  useEffect(() => {
    if (!!sector?.id) getCells(sector.id);
  }, [sector]);

  useEffect(() => {
    if (!!cell?.id) getVillages(cell.id);
  }, [cell]);

  const getSectors = async (districtId) => {
    try {
      const { data } = await axios.get(`/api/posts/sectors/` + districtId);
      setSectors(data);
    } catch (error) {}
  };

  const getCells = async (sectorId) => {
    try {
      const { data } = await axios.get(`/api/posts/cells/` + sectorId);
      setCells(data);
    } catch (error) {}
  };

  const getVillages = async (cellId) => {
    try {
      const { data } = await axios.get(`/api/posts/villages/` + cellId);
      setVillages(data);
    } catch (error) {}
  };

  useEffect(() => {
    if (!!teacherProfile?.residenceDistrictId && districts.length)
      setDistrict(
        districts.find((d) => d.id === teacherProfile?.residenceDistrictId) ||
          null
      );
  }, [teacherProfile, districts]);

  useEffect(() => {
    if (!!teacherProfile?.residenceSectorId && sectors.length)
      setSector(
        sectors.find((d) => d.id === teacherProfile?.residenceSectorId) || null
      );
  }, [teacherProfile, sectors]);

  useEffect(() => {
    if (!!teacherProfile?.residenceCellId && cells.length)
      setCell(
        cells.find((d) => d.id === teacherProfile?.residenceCellId) || null
      );
  }, [teacherProfile, cells]);

  useEffect(() => {
    if (!!teacherProfile?.residenceVillageId && villages.length)
      setVillage(
        villages.find(
          (v) => v.ippisVillageCode === teacherProfile?.residenceVillageId
        ) || null
      );
  }, [teacherProfile, villages]);

  const onSave = () => {
    updateTeacherResidence({
      teacherId: user.id,
      residenceVillageId: village?.ippisVillageCode || null,
    });
  };

  return (
    <>
      <div
      // style={{
      //   height: "74vh",
      //   backgroundColor: "#f3f4f6",
      //   overflowY: "auto",
      // }}
      >
        <div className="row mx-0 mt-3">
          <div className="col-sm-12">
            <React.Fragment>
              <div>
                <div className="row no-gutters">
                  <div className="col-2 justify-content-center  text-center">
                    <div
                      style={{
                        minHeight: "150px",
                        overflow: "hidden",
                      }}
                    >
                      <>
                        <img
                          className="mt-0 rounded  text-center"
                          src={
                            !!teacherProfile.profilePicture
                              ? "data:image/jpg;base64," +
                                teacherProfile.profilePicture
                              : defaultPofile
                          }
                          style={{
                            border: "1px solid #a7a7a7",
                            height: "150px",
                            width: "120px",
                            overflow: "hidden",
                            borderRadius: "50%",
                          }}
                        />
                      </>
                    </div>
                    <div className="text-primary">
                      <strong>NID PHOTO</strong>{" "}
                    </div>
                    {!!teacherProfile?.id && (
                      <div className="text-primary">
                        <strong>{teacherProfile?.id}</strong>{" "}
                      </div>
                    )}
                    <label className="text-center text-uppercase  mt-n2">
                      <span style={{ fontSize: "12px" }}>
                        {teacherProfile?.lastName} {teacherProfile?.firstName}
                      </span>
                    </label>{" "}
                    {!isEmpty(teacherProfile) && (
                      <FormControl
                        className="mt-3 d-block border rounded pt-2"
                        style={{ backgroundColor: "#eee" }}
                      >
                        <FormLabel className="text-primary  px-2">
                          Education certficate
                        </FormLabel>
                        <RadioGroup
                          className="mt-0 d-flex justify-content-start px-4"
                          row
                          name="hasEducationCertficate"
                          value={
                            teacherProfile?.hasEducationCertficate || false
                          }
                        >
                          {!!JSON.parse(
                            teacherProfile.hasEducationCertficate
                          ) && (
                            <FormControlLabel
                              value={true}
                              control={<Radio disableRipple size="small" />}
                              label="Certfied"
                            />
                          )}
                          {!JSON.parse(
                            teacherProfile.hasEducationCertficate
                          ) && (
                            <FormControlLabel
                              value={false}
                              control={<Radio disableRipple size="small" />}
                              label="Not certfied"
                            />
                          )}
                        </RadioGroup>
                      </FormControl>
                    )}
                    <span className="text-uppercase mt-1 mb-2 d-block">
                      <Link to="/in/security/change-password">
                        Change password
                      </Link>
                    </span>
                  </div>

                  <div
                    className="col-10 row mx-0 "
                    style={{
                      height: "70vh",
                      backgroundColor: "#f3f4f6",
                      overflowY: "auto",
                      // borderRadius: "0.5rem",
                      // boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                      // MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                    }}
                  >
                    <div className="col-4 justify-content-center mt-2">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <>
                              {!teacherProfile.isNIDVerified && !loading && (
                                <span className="loaderIcon text-danger d-flex align-items-center">
                                  <span className="material-icons text-danger">
                                    report_problem
                                  </span>
                                  <span style={{ fontSize: "10px" }}>
                                    Error
                                  </span>
                                </span>
                              )}

                              {!!teacherProfile.isNIDVerified && !loading && (
                                <span className="loaderIcon text-success d-flex align-items-center">
                                  <span className="material-icons text-success ">
                                    verified
                                  </span>
                                  <span style={{ fontSize: "10px" }}>
                                    Verified
                                  </span>
                                </span>
                              )}
                            </>
                          ),
                        }}
                        fullWidth
                        size="small"
                        autoComplete="false"
                        name="nidNumber"
                        label="NID Number"
                        placeholder="NID Number"
                        variant="outlined"
                        value={teacherProfile.nidNumber || ""}
                      />
                    </div>
                    <div className="col-4 text-left mt-2">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        size="small"
                        autoComplete="false"
                        name="passportNumber"
                        label="Passport number"
                        placeholder="Passport number"
                        variant="outlined"
                        value={teacherProfile.passportNumber || ""}
                      />
                    </div>

                    <div className="col-2 text-left mt-2">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        size="small"
                        name="civilStatus"
                        label="Civil Status"
                        variant="outlined"
                        value={teacherProfile.civilStatus || ""}
                      />
                    </div>
                    <div className="col-2 text-left mt-2">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="gender"
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Gender"
                        variant="outlined"
                        className=" font-weight-bold"
                        value={teacherProfile.gender || ""}
                      />
                    </div>
                    <div className="col-4  mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="lastName"
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Last Name"
                        variant="outlined"
                        className=""
                        value={teacherProfile.lastName || ""}
                      />
                    </div>
                    <div className="col-4  mt-3  text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="firstName"
                        InputProps={{
                          readOnly: true,
                        }}
                        label="First Name"
                        variant="outlined"
                        className=""
                        value={teacherProfile.firstName || ""}
                      />
                    </div>

                    <div className="col-4  mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="dateOfBirth"
                        value={
                          !!teacherProfile.dateOfBirth
                            ? moment(teacherProfile.dateOfBirth).format(
                                "DD/MM/yyyy"
                              )
                            : ""
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Date Of Birth"
                        variant="outlined"
                      />
                    </div>
                    <div className="col-4  mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="countryOfBirth"
                        value={teacherProfile.countryOfBirth || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Country of Birth"
                        variant="outlined"
                        className=""
                      />
                    </div>
                    <div className="col-4  mt-3 text-left">
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="placeOfBirth"
                        value={teacherProfile.placeOfBirth || ""}
                        label="Place of Birth"
                        variant="outlined"
                        className=""
                      />
                    </div>

                    <div className="col-4  mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="nationality"
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Nationality"
                        placeholder="Nationality"
                        variant="outlined"
                        className=" font-weight-bold"
                        value={teacherProfile.nationalityId || ""}
                      />
                    </div>

                    <div className="col-4 mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="spouseNames"
                        value={teacherProfile?.spouseNames || "N/A"}
                        label="Your spouse names"
                        placeholder="Your spouse names"
                        variant="outlined"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </div>
                    <div className="col-2 mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="employeeId"
                        value={teacherProfile?.id || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Employee ID"
                        placeholder="Employee ID"
                        variant="outlined"
                      />
                    </div>
                    <div className="col-2 mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="rssbNumber"
                        value={teacherProfile?.rssbNumber || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                        label="RSSB Number"
                        placeholder="RSSB Number"
                        variant="outlined"
                        className=""
                      />
                    </div>
                    <div className="col-4 mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="bankAccount"
                        value={
                          !teacherProfile.accountNumber
                            ? ""
                            : teacherProfile.accountNumber +
                              " (" +
                              teacherProfile.bankName +
                              ")"
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Bank Account"
                        placeholder="Bank Account"
                        variant="outlined"
                      />
                    </div>
                    <div className="col-6 mt-3 ">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="rssbNumber"
                        value={teacherProfile?.positionName || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Position"
                        placeholder="Position"
                        variant="outlined"
                        className=""
                      />
                    </div>
                    <div className="col-6 mt-3 ">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="schoolName"
                        value={
                          !!teacherProfile?.schoolName
                            ? teacherProfile?.schoolName +
                              "/" +
                              teacherProfile?.sectorName +
                              "/" +
                              teacherProfile?.districtName
                            : ""
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        label="School"
                        placeholder="School"
                        variant="outlined"
                        className=""
                      />
                    </div>

                    <div className="col-3 mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="teachingLevel"
                        value={teacherProfile?.teachingLevelName || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Teaching Level"
                        placeholder="Teaching Level"
                        variant="outlined"
                      />
                    </div>

                    <div className="col-3 mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="qualificationLevel"
                        value={teacherProfile?.qualificationLevelName || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Position qualification"
                        placeholder="Position qualification"
                        variant="outlined"
                      />
                    </div>

                    <div className="col-6 mt-3 ">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="appointmentDate"
                        value={
                          !!teacherProfile.appointmentDate
                            ? moment(teacherProfile.appointmentDate).format(
                                "DD/MM/yyyy"
                              )
                            : ""
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Position appointment date"
                        variant="outlined"
                      />
                    </div>

                    <div className="col-4  mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        size="small"
                        autoComplete="false"
                        name="phone"
                        label="Phone number"
                        placeholder="Phone number"
                        variant="outlined"
                        value={teacherProfile.phoneNumber || ""}
                      />
                    </div>

                    <div className="col-4  mt-3 text-left">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        size="small"
                        autoComplete="false"
                        name="email"
                        label="Personal email"
                        placeholder="Personal email"
                        variant="outlined"
                        value={teacherProfile.email || ""}
                      />
                    </div>

                    <div className="mt-3 col-4">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        size="small"
                        autoComplete="false"
                        name="employmentTypeName"
                        label="Employment Type"
                        placeholder="Employment Type"
                        variant="outlined"
                        value={teacherProfile.employmentTypeName || ""}
                      />
                    </div>

                    <div className="mt-3 col-4 mb-3">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        size="small"
                        autoComplete="false"
                        name="degreeName"
                        label="Your degree"
                        placeholder="Your degree"
                        variant="outlined"
                        value={teacherProfile.degreeName || ""}
                      />
                    </div>
                    <div className="mt-3 col-4 mb-3">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        size="small"
                        autoComplete="false"
                        name="specialization"
                        label="Specialization"
                        placeholder="Specialization"
                        variant="outlined"
                        value={teacherProfile.specialization || ""}
                      />
                    </div>
                    <div className="mt-3 col-4 mb-3">
                      <TextField
                        style={{ backgroundColor: "#e9ecef" }}
                        fullWidth
                        size="small"
                        name="graduationDate"
                        value={
                          !!teacherProfile.graduationDate
                            ? moment(teacherProfile.graduationDate).format(
                                "DD/MM/yyyy"
                              )
                            : ""
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Graduation date"
                        variant="outlined"
                      />
                    </div>
                  </div>
                  <div className="col-12 px-3">
                    <fieldset
                      style={{ backgroundColor: "#ccefff75" }}
                      className={`row p-3 rounded form-group border border-primary justify-content-center`}
                    >
                      <legend
                        className={`col-12 w-auto px-2 text-primary`}
                        style={{
                          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                          fontWeight: 400,
                          fontSize: "0.82rem",
                          lineHeight: "1em",
                          letterSpacing: "0.00938em",
                        }}
                      >
                        Physical address{" "}
                        {!!teacherProfile.isProfileLocked && (
                          <Tooltip title="Updated">
                            <span className="text-success">
                              <DoneAllIcon />
                            </span>
                          </Tooltip>
                        )}
                      </legend>

                      <div className="col-12 col-md-6 mb-2 mt-2">
                        <Autocomplete
                          size="small"
                          readOnly={!!teacherProfile.isProfileLocked}
                          defaultValue={null}
                          value={district}
                          options={districts || []}
                          disabled={loading}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, district) => {
                            setDistrict(district || null);

                            setSector(null);
                            setCell(null);
                            setVillage(null);

                            setSectors([]);
                            setCells([]);
                            setVillages([]);
                          }}
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(props, district) => (
                            <Box component="li" {...props} key={district.id}>
                              {district.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              style={{
                                height: "25px !important",
                                position: "relative",
                                borderRadius: "8px",
                                padding: "1px !important",
                              }}
                              {...params}
                              label="Residence district"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "name", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="col-12 col-md-6 mb-2 mt-2">
                        <Autocomplete
                          readOnly={!!teacherProfile.isProfileLocked}
                          size="small"
                          defaultValue={null}
                          value={sector}
                          options={sectors}
                          disabled={loading || !sectors.length}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, sector) => {
                            setSector(sector || null);

                            setCell(null);
                            setVillage(null);

                            setCells([]);
                            setVillages([]);
                          }}
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(props, sector) => (
                            <Box component="li" {...props} key={sector.id}>
                              {sector.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              style={{
                                height: "25px !important",
                                position: "relative",
                                borderRadius: "8px",
                                padding: "1px !important",
                              }}
                              {...params}
                              label="Residence sector"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "name", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="col-12 col-md-6 mb-2 mt-2">
                        <Autocomplete
                          readOnly={!!teacherProfile.isProfileLocked}
                          size="small"
                          defaultValue={null}
                          value={cell || null}
                          options={cells}
                          disabled={loading || !cells.length}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, cell) => {
                            setCell(cell || null);
                            setVillage(null);
                            setVillages([]);
                          }}
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(props, cell) => (
                            <Box component="li" {...props} key={cell.id}>
                              {cell.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              style={{
                                height: "25px !important",
                                position: "relative",
                                borderRadius: "8px",
                                padding: "1px !important",
                              }}
                              {...params}
                              label="Residence cell"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "name", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="col-12 col-md-6 mb-2 mt-2">
                        <Autocomplete
                          readOnly={!!teacherProfile.isProfileLocked}
                          size="small"
                          defaultValue={null}
                          value={village || null}
                          options={villages.filter((v) => !!v.ippisVillageCode)}
                          disabled={loading || !villages.length}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, village) =>
                            setVillage(village || null)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(props, village) => (
                            <Box component="li" {...props} key={village.id}>
                              {village.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              style={{
                                height: "25px !important",
                                position: "relative",
                                borderRadius: "8px",
                                padding: "1px !important",
                              }}
                              {...params}
                              label="Residence village"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "name", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                      </div>

                      <div className="col-12 col-md-4 border-right-sm">
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          type="button"
                          className={`btn btn-primary btn-sm w-100`}
                          disabled={
                            loading ||
                            !village ||
                            !!teacherProfile.isProfileLocked ||
                            teacherProfile.residenceVillageId ===
                              village?.ippisVillageCode
                          }
                          onClick={onSave}
                        >
                          Save
                        </Button>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>
        </div>
      </div>

      {/* {showUploadedDocument && uploadedDocument && (
        <PreviewPdfUrl
          isBase64={true}
          showDocument={showUploadedDocument}
          setShowDocument={setShowUploadedDocument}
          document={{
            name: `${uploadedDocument?.name || ""}`,
            teacherName: `${teacherProfile?.lastName || ""} ${
              teacherProfile?.firstName || ""
            }`,
            url: uploadedDocument?.url || "",
          }}
        />
      )} */}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  onWait,
  placementReportDetails,
  changeList,
  prevPlacementReport,
  signatories,
  roles,
  districts,
  sectors,
  degrees,
  specializations,
  schoolPositions,
  teacherProfile,
}) => {
  return {
    user,
    loading,
    onWait,
    placementReportDetails,
    changeList,
    prevPlacementReport,
    signatories,
    roles,
    districts,
    sectors,
    degrees,
    specializations,
    schoolPositions,
    teacherProfile,
  };
};
export default connect(mapStateToProps, {
  addOrUpdateTeacher,
  getSectors,
  getSchoolPositions,
  getTeacherProfile,
  getDistricts,
  updateTeacherResidence,
})(TeacherProfile);
