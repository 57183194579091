import {
  Cell,
  Pie,
  PieChart,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const convertToPieData = (tableData) => {
  const levelGroups = {};

  tableData.forEach((item) => {
    if (levelGroups[item.level]) {
      levelGroups[item.level] += item.count;
    } else {
      levelGroups[item.level] = item.count;
    }
  });

  return Object.entries(levelGroups).map(([name, value]) => ({
    name,
    value,
  }));
};

export default function TeachingLevelVacanciesChart({ data }) {
  const pieData = convertToPieData(data);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart height={300}>
        <Pie
          data={pieData}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius={100}
          fill="#8884d8"
          dataKey="value"
          nameKey="name"
          label={({ name, percent }) =>
            `${name}: ${(percent * 100).toFixed(0)}%`
          }
        >
          {pieData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={`hsl(var(--chart-${index + 1}))`}
            />
          ))}
        </Pie>
        <Tooltip
          formatter={(value, label) => [
            `${value?.toLocaleString()} ${
              value == 1 ? "Vacancy" : "Vacancies"
            }`,
            label,
          ]}
        />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
}
