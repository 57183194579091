import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";

const convertToBarData = (data) => {
  return data.map((item) => ({
    name: item.position,
    value: item.count,
  }));
};

export default function PositionDistributionChart({ data }) {
  const barData = convertToBarData(data);

  return (
    <BarChart
      width={1400}
      height={300}
      data={barData}
      margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
    >
      <CartesianGrid strokeDasharray="3 3" vertical={false} />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip
        formatter={(value, label) => [
          `${value?.toLocaleString()} ${value == 1 ? "Teacher" : "Teachers"}`,
          label,
        ]}
      />
      <Legend />
      <Bar
        dataKey="value"
        fill="hsl(var(--chart-1))"
        radius={4}
        name="Positions"
      />
    </BarChart>
  );
}
