import React, { useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import REB_Logo from "../../../assets/REB_Logo.png";
import RTB_Logo from "../../../assets/RTB_Logo.png";

import {
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { connect } from "react-redux";
import { logout } from "../../../../store/common/actions";
import { isEmpty } from "lodash";
import { TreeItem, TreeView } from "@mui/lab";

const DrawerContent = (props) => {
  const {
    drawerState,
    setDrawerState,
    onDrowerItemClicked,
    user,
    logout,
    selectedModule,
    env,
    version,
  } = props;

  const location = useLocation();
  const history = useHistory();

  const [expanded, setExpanded] = useState([
    user?.selectedEntity?.role !== "TEACHER" ? "16" : "6",
  ]);

  const handleToggleNode = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  return (
    <React.Fragment>
      <div
        id="drawer"
        className={`position-fixed ${drawerState ? "active" : ""}`}
      >
        <div>
          <div className="drawer-logo">
            {user?.selectedScope?.id === "REB" && (
              <img src={REB_Logo} className="" alt="Linda Miller" />
            )}
            {user?.selectedScope?.id === "RTB" && (
              <img src={RTB_Logo} className="" alt="Linda Miller" />
            )}
          </div>
          <div style={{ marginTop: "0px" }}>
            <div style={{ backgroundColor: "#015198", height: "7px" }}></div>
          </div>
          <Box
            sx={{ height: "73vh", flexGrow: 1, overflowY: "auto" }}
            className="mt-2 pr-2"
          >
            <TreeView
              aria-label="controlled"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expanded}
              onNodeToggle={handleToggleNode}
            >
              <TreeItem
                className={`nav-item ${
                  location.pathname.includes("/in/welcome") ? "active" : ""
                }`}
                nodeId="1"
                label={
                  <Link onClick={onDrowerItemClicked} to="/in/welcome">
                    <span
                      className="d-flex justify-content-between  align-items-center"
                      style={{ maxWidth: "100%" }}
                    >
                      <span
                        style={{
                          cursor: "default",
                        }}
                      >
                        Welcome
                      </span>
                    </span>
                  </Link>
                }
              />

              {user?.selectedEntity?.role !== "TEACHER" && (
                <TreeItem
                  className={`nav-item`}
                  nodeId="16"
                  label={
                    <span
                      className="d-flex justify-content-between  align-items-center"
                      style={{ maxWidth: "100%" }}
                    >
                      <span className={`d-block text-truncate`}>
                        <span
                          className="mr-2 menu-header"
                          style={{
                            cursor: "default",
                          }}
                        >
                          School Management
                        </span>
                      </span>
                    </span>
                  }
                >
                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/schools/dashboard")
                        ? "active"
                        : ""
                    }`}
                    nodeId="19"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/schools/dashboard"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              Dashboard
                            </span>
                          </span>
                        </span>
                      </Link>
                    }
                  />

                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/schools/posts")
                        ? "active"
                        : ""
                    }`}
                    nodeId="17"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/schools/posts"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              Post Mgmt
                            </span>
                          </span>
                        </span>
                      </Link>
                    }
                  />

                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/schools/staff-list")
                        ? "active"
                        : ""
                    }`}
                    nodeId="28"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/schools/staff-list"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              Staff List
                            </span>
                          </span>
                        </span>
                      </Link>
                    }
                  />

                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/schools/post-requests")
                        ? "active"
                        : ""
                    }`}
                    nodeId="14"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/schools/post-requests"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              Post requests{" "}
                            </span>
                          </span>
                        </span>
                      </Link>
                    }
                  />

                  {user?.selectedEntity?.role !== "HEAD_TEACHER" && (
                    <TreeItem
                      className={`nav-item ${
                        location.pathname.includes("/in/schools/distributions")
                          ? "active"
                          : ""
                      }`}
                      nodeId="18"
                      label={
                        <Link
                          onClick={onDrowerItemClicked}
                          to="/in/schools/distributions"
                        >
                          <span
                            className="d-flex justify-content-between  align-items-center"
                            style={{ maxWidth: "100%" }}
                          >
                            <span className={`d-block text-truncate`}>
                              <span
                                className="mr-2"
                                style={{
                                  cursor: "default",
                                }}
                              >
                                Posts distribution
                              </span>
                            </span>
                          </span>
                        </Link>
                      }
                    />
                  )}

                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/schools/user-guide")
                        ? "active"
                        : ""
                    }`}
                    nodeId="20"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/schools/user-guide"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span
                            style={{
                              cursor: "default",
                            }}
                          >
                            User-guide
                          </span>
                        </span>
                      </Link>
                    }
                  />
                </TreeItem>
              )}

              {!user?.selectedEntity?.isActingTeacher && (
                <TreeItem
                  className={`nav-item`}
                  nodeId="6"
                  label={
                    <span
                      className="d-flex justify-content-between  align-items-center"
                      style={{ maxWidth: "100%" }}
                    >
                      <span className={`d-block text-truncate`}>
                        <span
                          className="mr-2 menu-header"
                          style={{
                            cursor: "default",
                          }}
                        >
                          Transfers & Permutations
                        </span>
                      </span>
                    </span>
                  }
                >
                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/transfers/permutations")
                        ? "active"
                        : ""
                    }`}
                    nodeId="9"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/transfers/permutations"
                      >
                        <span
                          className="d-flex justify-content-between align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              Permutations
                            </span>
                          </span>
                        </span>
                      </Link>
                    }
                  />

                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/transfers/requests")
                        ? "active"
                        : ""
                    }`}
                    nodeId="8"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/transfers/requests"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              Transfers
                            </span>
                          </span>
                        </span>
                      </Link>
                    }
                  />

                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/transfers/user-guide")
                        ? "active"
                        : ""
                    }`}
                    nodeId="31"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/transfers/user-guide"
                      >
                        <span
                          className="d-flex justify-content-between align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              User-guide
                            </span>
                          </span>
                        </span>
                      </Link>
                    }
                  />
                </TreeItem>
              )}

              {user?.selectedEntity?.role !== "TEACHER" && (
                <TreeItem
                  className={`nav-item`}
                  nodeId="11"
                  label={
                    <span
                      className="d-flex justify-content-between  align-items-center"
                      style={{ maxWidth: "100%" }}
                    >
                      <span className={`d-block text-truncate`}>
                        <span
                          className="mr-2 menu-header"
                          style={{
                            cursor: "default",
                          }}
                        >
                          Placement reports
                        </span>
                      </span>
                    </span>
                  }
                >
                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes(
                        "/in/placement/new-placement-reports"
                      )
                        ? "active"
                        : ""
                    }`}
                    nodeId="35"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/placement/new-placement-reports"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              New Reports
                            </span>
                          </span>
                        </span>
                      </Link>
                    }
                  />

                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes(
                        "/in/placement/archived-placement-reports"
                      )
                        ? "active"
                        : ""
                    }`}
                    nodeId="13"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/placement/archived-placement-reports"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              Archived Reports
                            </span>
                          </span>
                        </span>
                      </Link>
                    }
                  />

                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/placement/unsubmitted")
                        ? "active"
                        : ""
                    }`}
                    nodeId="15"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/placement/unsubmitted"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate text-danger`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              Unsubmitted
                            </span>
                          </span>
                        </span>
                      </Link>
                    }
                  />

                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/placement/user-guide")
                        ? "active"
                        : ""
                    }`}
                    nodeId="30"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/placement/user-guide"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              User-guide
                            </span>
                          </span>
                        </span>
                      </Link>
                    }
                  />
                </TreeItem>
              )}

              {(user?.selectedEntity?.role !== "TEACHER" ||
                user.gender === "F") && (
                <TreeItem
                  className={`nav-item`}
                  nodeId="33"
                  label={
                    <span
                      className="d-flex justify-content-between align-items-center"
                      style={{ maxWidth: "100%" }}
                    >
                      <span className={`d-block text-truncate`}>
                        <span
                          className="mr-2 menu-header"
                          style={{
                            cursor: "default",
                          }}
                        >
                          Maternity Leave
                        </span>
                      </span>
                    </span>
                  }
                >
                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes(
                        "/in/maternity-leave/in-progress"
                      )
                        ? "active"
                        : ""
                    }`}
                    nodeId="36"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/maternity-leave/in-progress"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              New requests
                            </span>
                          </span>
                        </span>
                      </Link>
                    }
                  />
                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/maternity-leave/archived")
                        ? "active"
                        : ""
                    }`}
                    nodeId="37"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/maternity-leave/archived"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              Archived
                            </span>
                          </span>
                        </span>
                      </Link>
                    }
                  />

                  {user?.selectedEntity?.role !== "TEACHER" && (
                    <TreeItem
                      className={`nav-item ${
                        location.pathname.includes(
                          "/in/maternity-leave/replacing-staff"
                        )
                          ? "active"
                          : ""
                      }`}
                      nodeId="38"
                      label={
                        <Link
                          onClick={onDrowerItemClicked}
                          to="/in/maternity-leave/replacing-staff"
                        >
                          <span
                            className="d-flex justify-content-between  align-items-center"
                            style={{ maxWidth: "100%" }}
                          >
                            <span className={`d-block text-truncate`}>
                              <span
                                className="mr-2"
                                style={{
                                  cursor: "default",
                                }}
                              >
                                Replacing staff
                              </span>
                            </span>
                          </span>
                        </Link>
                      }
                    />
                  )}

                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes(
                        "/in/maternity-leave/user-guide"
                      )
                        ? "active"
                        : ""
                    }`}
                    nodeId="39"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/maternity-leave/user-guide"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span
                            style={{
                              cursor: "default",
                            }}
                          >
                            User-guide
                          </span>
                        </span>
                      </Link>
                    }
                  />
                </TreeItem>
              )}

              {env !== "prod" && !user?.selectedEntity?.isActingTeacher && (
                <TreeItem
                  className={`nav-item`}
                  nodeId="21"
                  label={
                    <span
                      className="d-flex justify-content-between align-items-center"
                      style={{ maxWidth: "100%" }}
                    >
                      <span className={`d-block text-truncate`}>
                        <span
                          className="mr-2 menu-header"
                          style={{
                            cursor: "default",
                          }}
                        >
                          CPD Module
                        </span>
                      </span>
                    </span>
                  }
                >
                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/cpd/dashboard")
                        ? "active"
                        : ""
                    }`}
                    nodeId="24"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/cpd/dashboard"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              Dashboard
                            </span>
                          </span>
                        </span>
                      </Link>
                    }
                  />

                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes(
                        "/in/cpd/in-progress-trainings"
                      )
                        ? "active"
                        : ""
                    }`}
                    nodeId="22"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/cpd/in-progress-trainings"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              InProgress
                            </span>
                          </span>
                        </span>
                      </Link>
                    }
                  />
                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/cpd/archived-trainings")
                        ? "active"
                        : ""
                    }`}
                    nodeId="23"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/cpd/archived-trainings"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span className={`d-block text-truncate`}>
                            <span
                              className="mr-2"
                              style={{
                                cursor: "default",
                              }}
                            >
                              Archived
                            </span>
                          </span>
                        </span>
                      </Link>
                    }
                  />

                  <TreeItem
                    className={`nav-item ${
                      location.pathname.includes("/in/cpd/user-guide")
                        ? "active"
                        : ""
                    }`}
                    nodeId="32"
                    label={
                      <Link
                        onClick={onDrowerItemClicked}
                        to="/in/cpd/user-guide"
                      >
                        <span
                          className="d-flex justify-content-between  align-items-center"
                          style={{ maxWidth: "100%" }}
                        >
                          <span
                            style={{
                              cursor: "default",
                            }}
                          >
                            User-guide
                          </span>
                        </span>
                      </Link>
                    }
                  />
                </TreeItem>
              )}

              {!user?.selectedEntity?.isActingTeacher && (
                <TreeItem
                  className={`nav-item ${
                    location.pathname.includes("/in/scholarships")
                      ? "active"
                      : ""
                  }`}
                  nodeId="12"
                  label={
                    <Link onClick={onDrowerItemClicked} to="/in/scholarships">
                      <span
                        className="d-flex justify-content-between  align-items-center"
                        style={{ maxWidth: "100%" }}
                      >
                        <span
                          style={{
                            cursor: "default",
                          }}
                        >
                          Scholarships{" "}
                        </span>
                      </span>
                    </Link>
                  }
                />
              )}

              {["ADMIN", "DDE", "REB", "RTB"].includes(
                user?.selectedEntity?.role
              ) && (
                <TreeItem
                  className={`nav-item ${
                    location.pathname.includes("/in/security/system-users")
                      ? "active"
                      : ""
                  }`}
                  nodeId="5"
                  label={
                    <Link
                      onClick={onDrowerItemClicked}
                      to="/in/security/system-users"
                    >
                      <span
                        className="d-flex justify-content-between  align-items-center"
                        style={{ maxWidth: "100%" }}
                      >
                        <span
                          style={{
                            cursor: "default",
                          }}
                        >
                          System Users
                        </span>
                      </span>
                    </Link>
                  }
                />
              )}

              {(user?.selectedEntity?.role === "TEACHER" ||
                user?.selectedEntity?.role === "HEAD_TEACHER") && (
                <TreeItem
                  className={`nav-item mt-4 ${
                    location.pathname.includes("/in/security/my-profile")
                      ? "active"
                      : ""
                  }`}
                  nodeId="100"
                  label={
                    <Link
                      onClick={onDrowerItemClicked}
                      to="/in/security/my-profile"
                    >
                      <span
                        className="d-flex justify-content-between  align-items-center"
                        style={{ maxWidth: "100%" }}
                      >
                        <span
                          style={{
                            cursor: "default",
                          }}
                        >
                          My Profile
                        </span>
                      </span>
                    </Link>
                  }
                />
              )}

              {["REB", "RTB"].includes(user?.selectedEntity?.role) && (
                <TreeItem
                  className={`nav-item mt-1 ${
                    location.pathname.includes("/in/security/global-settings")
                      ? "active"
                      : ""
                  }`}
                  nodeId="102"
                  label={
                    <Link
                      onClick={onDrowerItemClicked}
                      to="/in/security/global-settings"
                    >
                      <span
                        className="d-flex justify-content-between  align-items-center text-dark"
                        style={{ maxWidth: "100%" }}
                      >
                        <span
                          style={{
                            cursor: "default",
                          }}
                        >
                          Global settings
                        </span>
                      </span>
                    </Link>
                  }
                />
              )}

              {env !== "prod" &&
                ["REB", "RTB"].includes(user?.selectedEntity?.role) && (
                  <TreeItem
                    className={`nav-item mt-1 ${
                      location.pathname.includes("/in/oversight")
                        ? "active"
                        : ""
                    }`}
                    nodeId="103"
                    label={
                      <Link onClick={onDrowerItemClicked} to="/in/oversight">
                        <span
                          className="d-flex justify-content-between  align-items-center "
                          style={{ maxWidth: "100%" }}
                        >
                          <span
                            style={{
                              cursor: "default",
                              color: "#007bff",
                            }}
                          >
                            Oversight
                          </span>
                        </span>
                      </Link>
                    }
                  />
                )}
            </TreeView>
          </Box>
        </div>

        <div className="text-center pt-3">
          {!!version && <span>TMIS V.{version}</span>}
        </div>
      </div>

      <div
        id="drawer-void"
        className={`position-fixed ${
          drawerState ? "d-block d-lg-none" : "d-none"
        } `}
        onClick={() => {
          setDrawerState(false);
          onDrowerItemClicked();
        }}
      ></div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user, selectedModule, env, version }) => {
  return { user, selectedModule, env, version };
};

export default connect(mapStateToProps, { logout })(DrawerContent);
