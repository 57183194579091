import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";

export default function TeacherPositionSuspension({ data }) {
  return (
    <BarChart
      width={1000}
      height={300}
      data={data}
      margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
    >
      <CartesianGrid strokeDasharray="3 3" vertical={false} />
      <XAxis dataKey="position" />
      <YAxis />
      <Tooltip
        formatter={(value, label) => [
          `${value?.toLocaleString()} ${value == 1 ? "Teacher" : "Teachers"}`,
          label,
        ]}
      />
      <Legend />
      <Bar
        dataKey="count"
        fill="hsl(var(--chart-1))"
        radius={4}
        name="Suspensions"
      />
    </BarChart>
  );
}
