import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import SearchBox from "../../common/components/SearchBox";
import {
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  Skeleton,
} from "@mui/material";
import { connect } from "react-redux";
import { CardContent, Toolbar } from "@mui/material";
import { useHistory } from "react-router-dom";
import { getDistricts } from "../../../../store/posts/actions";
import { Line, Doughnut, Pie, Bar } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { currencyFormat } from "../../common/components/Utils";
import TableSkeleton from "../../common/components/TableSkeleton";
import { SkeletonPieChart } from "../../common/components/SkeletonPieChart";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const Dashboard = (props) => {
  useEffect(() => {
    document.title = "TMIS | Post Management - Dashboard";
  }, []);

  const [showLoader, setShowLoader] = useState(false);
  const [summary, setSummary] = useState({});

  const { user, loading } = props;

  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);

  const [searchTeam, setSearchTeam] = useState("");
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    getSchoolsDataSummary();
    getDashboardEntities();
  }, [user?.selectedEntity]);

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  const getSchoolsDataSummary = async () => {
    try {
      setIsLoading1(true);
      const { data } = await axios.get("/api/posts/dashboard-data-summary");

      setSummary(data);
      setIsLoading1(false);
    } catch (error) {
      setIsLoading1(false);
    }
  };

  const getDashboardEntities = async () => {
    try {
      setIsLoading2(true);

      setEntities([]);

      const { data } = await axios.get("/api/posts/dashboard-entities");
      setEntities(data);
      setIsLoading2(false);
    } catch (error) {
      setIsLoading2(false);
    }
  };

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(entities);
  }, [itemOffset, itemsPerPage, entities]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % entities.length || 0;

    setItemOffset(newOffset);
  };

  const getNo = (data, key, idToSearch) =>
    data.findIndex((item) => item[key] === idToSearch);

  return (
    <div className="px-4 pt-2">
      <div className="border">
        <AppBar position="static" elevation={0} className="app-bar w-100">
          <Toolbar
            style={{
              minHeight: "0px",
              borderBottom: "3px solid #e9ecef",
            }}
            className=" d-flex my-0 py-1 bg-light"
          >
            <Typography
              variant="p"
              noWrap
              component="div"
              className="d-flex align-items-center text-uppercase text-primary font-weight-bold"
            >
              <span className="text-dark ">Dashboard </span>
              <span className="material-icons" style={{ color: "#cecece" }}>
                chevron_right
              </span>

              <span
                className="d-block text-truncate"
                style={{ maxWidth: "70%" }}
              >
                {user?.selectedEntity?.name}{" "}
                {!!user?.selectedEntity?.type &&
                !(user?.selectedEntity?.name)
                  .toUpperCase()
                  .includes(user?.selectedEntity?.type)
                  ? user?.selectedEntity?.type
                  : ""}
                {user?.selectedEntity?.id === "00000000" && <>S</>}
              </span>

              {/* <span className="d-flex  align-items-center badge badge-danger ml-2">
                <span className="material-icons mr-1">not_interested</span>
                Not Real Data
              </span> */}
            </Typography>
            {/* <div className="alert alert-warning py-1 mb-0 ml-2" role="alert">
              Some data require cleaning to produce accurate information.
            </div> */}
            {/* <Box sx={{ flexGrow: 1 }} /> */}

            {/* <Box sx={{ display: { xs: "flex", md: "flex" } }}>
              <SearchBox onSearch={onSearch} placeholder="Search…" />
            </Box> */}
          </Toolbar>
        </AppBar>

        <div
          style={{
            backgroundColor: "#f3f4f6",
            borderRadius: "0.5rem",
            boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
            MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            height: "69vh",
            overflowY: "auto",
          }}
          className="px-3 pb-4 pt-2"
        >
          <div className="d-flex flex-column mb-3">
            <div className="d-flex  flex-wrap justify-content-center">
              <div
                style={{ color: "#f8ff90", backgroundColor: "#4b1b93" }}
                className=" rounded border-gray-300 min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
              >
                <div className="fw-semibold font-weight-bold  text-center">
                  Total schools
                </div>
                <div
                  style={{ color: "#9cff71" }}
                  className="fs-6 font-weight-bolder  text-center"
                >
                  {currencyFormat(summary.totalSchools || 0)}
                </div>
              </div>

              {user?.selectedScope?.id === "REB" && (
                <div
                  style={{ color: "#f8ff90" }}
                  className="bg-primary rounded border-gray-300 min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
                >
                  <div className="fw-semibold font-weight-bold  text-center">
                    Pre-primary
                  </div>
                  <div
                    style={{ color: "#9cff71" }}
                    className="fs-6 font-weight-bolder  text-center"
                  >
                    {currencyFormat(summary.prePrimary || 0)}
                  </div>
                </div>
              )}
              {user?.selectedScope?.id === "REB" && (
                <div
                  style={{ color: "#f8ff90" }}
                  className="bg-primary rounded border-gray-300 min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
                >
                  <div className="fw-semibold font-weight-bold  text-center">
                    Primary
                  </div>
                  <div
                    style={{ color: "#9cff71" }}
                    className="fs-6 font-weight-bolder  text-center"
                  >
                    {currencyFormat(summary.primary || 0)}
                  </div>
                </div>
              )}
              {user?.selectedScope?.id === "REB" && (
                <div
                  style={{ color: "#f8ff90" }}
                  className="bg-primary rounded border-gray-300 min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
                >
                  <div className="fw-semibold font-weight-bold  text-center">
                    Secondary
                  </div>
                  <div
                    style={{ color: "#9cff71" }}
                    className="fs-6 font-weight-bolder  text-center"
                  >
                    {currencyFormat(summary.secondary || 0)}
                  </div>
                </div>
              )}

              <div
                style={{ color: "#fff6ca", backgroundColor: "#4caf50" }}
                className="rounded border-gray-300 min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
              >
                <div className="fw-semibold font-weight-bold  text-center">
                  Budget posts
                </div>
                <div
                  style={{ color: "#9cff71" }}
                  className="fs-6 font-weight-bolder  text-center"
                >
                  {currencyFormat(summary.totalBudgetPosts || 0)}
                </div>
              </div>

              <div
                style={{ color: "#fff6ca", backgroundColor: "#4caf50" }}
                className="rounded border-gray-300 min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
              >
                <div className="fw-semibold font-weight-bold  text-center">
                  Active teachers
                </div>
                <div
                  style={{ color: "#9cff71" }}
                  className="fs-6 font-weight-bolder  text-center"
                >
                  {currencyFormat(summary.filledPosts || 0)}
                </div>
              </div>

              <div
                style={{ color: "#fff6ca", backgroundColor: "#ee6c02" }}
                className="rounded border-gray-300 min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
              >
                <div className="fw-semibold font-weight-bold  text-center">
                  Vacant posts
                </div>
                <div
                  style={{ color: "#9cff71" }}
                  className="fs-6 font-weight-bolder  text-center"
                >
                  {currencyFormat(summary.vacantPosts || 0)}
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-around mx-0 px-4">
            <div className="col-6 row  mx-0 px-3">
              <div className="col-12 py-3 row mx-0 tmis-shadow rounded">
                <div className="col-6">
                  {isLoading1 ? (
                    <SkeletonPieChart title="Budget Posts" />
                  ) : (
                    <div className="border rounded p-2 dashboard-card">
                      {summary.totalBudgetPostsPieData && (
                        <Pie
                          redraw={true}
                          options={{
                            responsive: true,
                            plugins: {
                              legend: {
                                position: "bottom",
                              },
                              title: {
                                display: true,
                                text: "Budget Posts",
                              },
                            },
                          }}
                          data={summary.totalBudgetPostsPieData}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="col-6">
                  {isLoading1 ? (
                    <SkeletonPieChart title="Vacant Posts" />
                  ) : (
                    <div className="border rounded p-2 dashboard-card">
                      {summary.vacantBudgetPostsPieData && (
                        <Pie
                          redraw={true}
                          options={{
                            responsive: true,
                            plugins: {
                              legend: {
                                position: "bottom",
                              },
                              title: {
                                display: true,
                                text: "Vacant Posts",
                              },
                            },
                          }}
                          data={summary.vacantBudgetPostsPieData}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-6 row  mx-0 px-3">
              <div className="col-12 py-3 row mx-0 tmis-shadow rounded">
                <div className="col-6">
                  {isLoading1 ? (
                    <SkeletonPieChart title="Active teachers by qualifications" />
                  ) : (
                    <div className="border rounded p-2 dashboard-card">
                      {summary.activeBudgetPostsPieData && (
                        <Pie
                          redraw={true}
                          options={{
                            responsive: true,
                            plugins: {
                              legend: {
                                position: "bottom",
                              },
                              title: {
                                display: true,
                                text: "Active teachers by qualifications",
                              },
                            },
                          }}
                          data={summary.activeBudgetPostsPieData}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="col-6">
                  {isLoading1 ? (
                    <SkeletonPieChart title="Active teachers by gender" />
                  ) : (
                    <div className="border rounded p-2 dashboard-card">
                      {summary.pieGenderData && (
                        <Doughnut
                          redraw={true}
                          options={{
                            responsive: true,
                            plugins: {
                              legend: {
                                position: "bottom",
                              },
                              title: {
                                display: true,
                                text: "Active teachers by gender",
                              },
                            },
                          }}
                          data={summary.pieGenderData}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row mt-4 justify-content-center mx-3 tmis-shadow p-3 rounded">
            <div className="col-6">
              <div className="border rounded p-2 dashboard-card">
                <Line
                  redraw={true}
                  options={{
                    responsive: true,

                    plugins: {
                      legend: {
                        position: "bottom",
                      },
                      title: {
                        display: true,
                        text: "Budget Posts variations",
                      },
                      // filler: {
                      //   propagate: true,
                      // },
                    },

                    interaction: {
                      mode: "index",
                      intersect: false,
                    },

                    elements: {
                      line: {
                        tension: 0.4,
                      },
                    },
                  }}
                  data={lineChartData1}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="border rounded p-2 dashboard-card">
                <Line
                  redraw={true}
                  options={{
                    responsive: true,

                    plugins: {
                      legend: {
                        position: "bottom",
                      },
                      title: {
                        display: true,
                        text: "Teacher placement variations",
                      },
                      // filler: {
                      //   propagate: true,
                      // },
                    },

                    interaction: {
                      mode: "index",
                      intersect: false,
                    },

                    elements: {
                      line: {
                        tension: 0.4,
                      },
                    },
                  }}
                  data={lineChartData2}
                />
              </div>
            </div>
          </div> */}

          <div className="mt-4 mx-3 tmis-shadow p-3 rounded">
            <table className="table table-striped table-hover table-sm  fixTableHead ">
              <thead>
                <tr>
                  <th
                    scope="col"
                    rowSpan={2}
                    style={{
                      borderLeft: "solid 1px #c6c7ce",
                      borderBottom: "2px solid #a8b6c3",
                      borderTop: "1px solid #a8b6c3",
                    }}
                  >
                    #
                  </th>

                  <th
                    scope="col"
                    rowSpan={2}
                    style={{
                      borderLeft: "solid 1px #c6c7ce",
                      backgroundColor: "#dbeafe",
                      borderBottom: "2px solid #a8b6c3",
                      borderTop: "1px solid #a8b6c3",
                    }}
                  >
                    {user?.selectedEntity?.id === "00000000"
                      ? "DISTRICTS"
                      : user?.selectedEntity?.type === "DISTRICT"
                      ? "SECTORS"
                      : "SCHOOLS"}
                  </th>
                  <th
                    rowSpan={user?.selectedScope?.id === "REB" ? 1 : 2}
                    colSpan={3}
                    className="text-center text-uppercase"
                    style={{
                      backgroundColor: "#d1def1",
                      borderLeft: "solid 1px #c6c7ce",
                      borderBottom: "2px solid #a8b6c3",
                      borderTop: "1px solid #a8b6c3",
                    }}
                  >
                    Schools
                  </th>
                  <th
                    colSpan={3}
                    style={{
                      backgroundColor: "#dbeafe",
                      borderLeft: "solid 1px #c6c7ce",
                      borderBottom: "2px solid #a8b6c3",
                      borderTop: "1px solid #a8b6c3",
                    }}
                    className="text-center text-uppercase"
                  >
                    Budget Posts
                  </th>
                  <th
                    colSpan={3}
                    style={{
                      backgroundColor: "#d1fae5",
                      borderLeft: "solid 1px #c6c7ce",
                      borderBottom: "2px solid #a8b6c3",
                      borderTop: "1px solid #a8b6c3",
                    }}
                    className="text-center text-uppercase"
                  >
                    Active Teachers
                  </th>
                  <th
                    colSpan={3}
                    style={{
                      backgroundColor: "#fef3c7",
                      borderLeft: "solid 1px #c6c7ce",
                      borderRight: "solid 1px #c6c7ce",
                      borderBottom: "2px solid #a8b6c3",
                      borderTop: "1px solid #a8b6c3",
                    }}
                    className="text-center text-uppercase"
                  >
                    Vacant Posts
                  </th>
                  {/* <th scope="col" rowSpan={2} className="text-rcenteright"></th> */}
                </tr>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  {user?.selectedScope?.id === "REB" && (
                    <th
                      className="text-center"
                      scope="col"
                      style={{
                        backgroundColor: "#d1def1",
                        borderLeft: "solid 1px #c6c7ce",
                        borderRight: "solid 1px #c6c7ce",
                        borderBottom: "2px solid #a8b6c3",
                      }}
                    >
                      Pre-primary
                    </th>
                  )}
                  {user?.selectedScope?.id === "REB" && (
                    <th
                      className="text-center"
                      scope="col"
                      style={{
                        backgroundColor: "#d1def1",
                        borderRight: "solid 1px #c6c7ce",
                        borderBottom: "2px solid #a8b6c3",
                      }}
                    >
                      Primary
                    </th>
                  )}

                  {user?.selectedScope?.id === "REB" && (
                    <th
                      className="text-center"
                      scope="col"
                      style={{
                        backgroundColor: "#d1def1",
                        borderRight: "solid 1px #c6c7ce",
                        borderBottom: "2px solid #a8b6c3",
                      }}
                    >
                      Secondary
                    </th>
                  )}

                  <th
                    className="text-center"
                    scope="col"
                    style={{
                      backgroundColor: "#dbeafe",
                      borderLeft: "solid 1px #c6c7ce",
                      borderRight: "solid 1px #c6c7ce",
                      borderBottom: "2px solid #a8b6c3",
                    }}
                  >
                    A2
                  </th>

                  <th
                    className="text-center"
                    scope="col"
                    style={{
                      backgroundColor: "#dbeafe",
                      borderRight: "solid 1px #c6c7ce",
                      borderBottom: "2px solid #a8b6c3",
                    }}
                  >
                    A1
                  </th>

                  <th
                    className="text-center"
                    scope="col"
                    style={{
                      backgroundColor: "#dbeafe",
                      borderRight: "solid 1px #c6c7ce",
                      borderBottom: "2px solid #a8b6c3",
                    }}
                  >
                    A0
                  </th>

                  <th
                    className="text-center"
                    scope="col"
                    style={{
                      backgroundColor: "#d1fae5",
                      borderRight: "solid 1px #c6c7ce",
                      borderBottom: "2px solid #a8b6c3",
                    }}
                  >
                    A2
                  </th>

                  <th
                    className="text-center"
                    scope="col"
                    style={{
                      backgroundColor: "#d1fae5",
                      borderRight: "solid 1px #c6c7ce",
                      borderBottom: "2px solid #a8b6c3",
                    }}
                  >
                    A1
                  </th>

                  <th
                    className="text-center"
                    scope="col"
                    style={{
                      backgroundColor: "#d1fae5",
                      borderRight: "solid 1px #c6c7ce",
                      borderBottom: "2px solid #a8b6c3",
                    }}
                  >
                    A0
                  </th>
                  <th
                    className="text-center"
                    scope="col"
                    style={{
                      backgroundColor: "#fef3c7",
                      borderRight: "solid 1px #c6c7ce",
                      borderBottom: "2px solid #a8b6c3",
                    }}
                  >
                    A2
                  </th>
                  <th
                    className="text-center"
                    scope="col"
                    style={{
                      backgroundColor: "#fef3c7",
                      borderRight: "solid 1px #c6c7ce",
                      borderBottom: "2px solid #a8b6c3",
                    }}
                  >
                    A1
                  </th>

                  <th
                    className="text-center"
                    scope="col"
                    style={{
                      borderRight: "solid 1px #c6c7ce",
                      backgroundColor: "#fef3c7",
                      borderBottom: "2px solid #a8b6c3",
                    }}
                  >
                    A0
                  </th>
                </tr>
              </thead>

              {isLoading2 && <TableSkeleton rows={8} cols={14} />}

              {!!paginatedItems && (
                <tbody>
                  {paginatedItems.map((entity) => (
                    <EntityCard
                      key={entity.id}
                      index={getNo(entities, "id", entity.id)}
                      entity={entity}
                      user={user}
                    />
                  ))}
                </tbody>
              )}
            </table>
            <ReactPaginate
              breakLabel="..."
              nextLabel={
                <>
                  <span className="pr-1 d-none d-md-inline">Next</span>
                  <i className="fas fa-angle-double-right"></i>
                </>
              }
              previousLabel={
                <>
                  <i className="fas fa-angle-double-left"></i>
                  <span className="pl-1  d-none d-md-inline">Previous</span>
                </>
              }
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              onPageChange={handlePageClick}
              pageCount={pageCount}
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              pageLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
              previousLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
              nextLinkClassName="btn btn-outline-dark btn-sm ml-1"
              activeLinkClassName="active"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return { user, loading };
};
export default connect(mapStateToProps, {})(Dashboard);

const EntityCard = (props) => {
  const { entity, index, user } = props;

  return (
    <>
      <tr className={``}>
        <th
          scope="row"
          className="text-left"
          style={{
            borderLeft: "solid 1px #c6c7ce",
          }}
        >
          {index + 1}
        </th>

        <td
          className="text-uppercase"
          style={{
            borderLeft: "solid 1px #c6c7ce",
          }}
        >
          {entity.name}
        </td>
        {user?.selectedScope?.id === "REB" && (
          <td
            className="text-center"
            style={{
              borderLeft: "solid 1px #c6c7ce",
              borderRight: "solid 1px #c6c7ce",
            }}
          >
            {entity.prePrimary || "-"}
          </td>
        )}

        {user?.selectedScope?.id === "REB" && (
          <td
            className="text-center"
            style={{
              borderRight: "solid 1px #c6c7ce",
            }}
          >
            {entity.primary || "-"}
          </td>
        )}
        <td
          colSpan={user?.selectedScope?.id === "REB" ? 1 : 3}
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {entity.secondary || "-"}
        </td>
        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {entity.a2BudgetPosts || "-"}
        </td>

        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {entity.a1BudgetPosts || "-"}
        </td>
        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {entity.a0BudgetPosts || "-"}
        </td>
        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {entity.a2ActiveTeachers || "-"}
        </td>

        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {entity.a1ActiveTeachers || "-"}
        </td>
        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {entity.a0ActiveTeachers || "-"}
        </td>

        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {entity.a2VacantPosts || "-"}
        </td>

        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {entity.a1VacantPosts || "-"}
        </td>

        <td
          className="text-center"
          style={{
            borderRight: "solid 1px #c6c7ce",
          }}
        >
          {entity.a0VacantPosts || "-"}
        </td>
      </tr>
    </>
  );
};
