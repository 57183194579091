import {
  ResponsiveContainer,
  CartesianGrid,
  Line,
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";

export default function AgeDistributionChart({ data }) {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={data}
        margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="ageGroup" />
        <YAxis />
        <Tooltip
          formatter={(value) => [
            `${value?.toLocaleString()} ${value == 1 ? "Teacher" : "Teachers"}`,
            "Count",
          ]}
        />
        <Legend />
        <Line
          type="monotone"
          dataKey="count"
          stroke="hsl(var(--chart-1))"
          strokeWidth={2}
          dot={{ r: 4 }}
          activeDot={{ r: 6 }}
          name="Teachers"
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
