import axios from "axios";
import { showSuccess, showError } from "../../app/toastify";
import download from "downloadjs";

import commonTypes from "../common/action-types";
import otherTypes from "./action-types";
const types = { ...commonTypes, ...otherTypes };

export const getScholarshipCombinations = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/scholarship/combinations");

      dispatch({
        type: types.SET_SCHOLARSHIP_COMBINATIONS,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const saveScholarshipCombination = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/scholarship/combinations",
        payload
      );

      dispatch({
        type: types.ADD_OR_UPDATE_SCHOLARSHIP_COMBINATION,
        data,
      });

      dispatch({ type: types.END_LOADING });

      cb();
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      showError(error);
    }
  };
};

export const getScholarshipCourses = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/scholarship/courses");

      dispatch({
        type: types.SET_SCHOLARSHIP_COURSES,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const saveScholarshipCourse = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/scholarship/courses", payload);

      dispatch({
        type: types.ADD_OR_UPDATE_SCHOLARSHIP_COURSE,
        data,
      });

      dispatch({ type: types.END_LOADING });

      cb();
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      showError(error);
    }
  };
};

export const getScholarshipDocuments = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/scholarship/documents");

      dispatch({
        type: types.SET_SCHOLARSHIP_DOCUMENTS,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const getScholarshipRequests = (query, setTotalRecords, setLoader) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      setLoader(true);

      const { data } = await axios.get(
        "/api/scholarship/requests" +
          "?" +
          new URLSearchParams(query).toString()
      );

      setLoader(false);

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_SCHOLARSHIP_REQUESTS,
        data: data.records,
      });

      setTotalRecords(data.totalRecords);
    } catch (error) {
      setLoader(false);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveScholarshipRequest = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/scholarship/requests", formData);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      onClose();

      dispatch({
        type: types.ADD_OR_UPDATE_SCHOLARSHIP_REQUEST,
        data,
      });

      showSuccess("Submitted successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const deleteScholarshipRequest = (id, setConfirmDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/scholarship/requests/" + id);

      setConfirmDialog(false);

      dispatch({
        type: types.DELETE_SCHOLARSHIP_REQUEST,
        id,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showSuccess("Deleted successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const approveOrRejectScholarshipRequest = (payload, setShowDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/scholarship/approvals", payload);

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.ADD_OR_UPDATE_SCHOLARSHIP_REQUEST,
        data,
      });

      showSuccess(`Saved successfully`);

      setShowDialog(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getScholarshipRequestAttachments = (requestId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/scholarship/attachments/" + requestId
      );

      dispatch({
        type: types.SET_SCHOLARSHIP_REQUEST_ATTACHMENTS,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const getScholarshipRequestLogs = (requestId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/scholarship/logs/" + requestId);

      dispatch({
        type: types.SET_SCHOLARSHIP_REQUEST_LOGS,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const getEligibility = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/scholarship/eligibility");

      dispatch({
        type: types.SET_ELIGIBILITY,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const getScholarshipSummary = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_SCHOLARSHIP_SUMMARY,
        data: {},
      });

      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/scholarship/summary");

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_SCHOLARSHIP_SUMMARY,
        data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const executeScholarshipAlgorithm = (setConfirmDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/scholarship/execute");

      dispatch({ type: types.END_LOADING });

      setConfirmDialog(false);

      dispatch({
        type: types.SET_SCHOLARSHIP_REQUESTS,
        data: data.records,
      });

      showSuccess("Scholarship algorithm executed successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const publishScholarshipRequests = (setConfirmDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/scholarship/publish");

      dispatch({ type: types.END_LOADING });

      setConfirmDialog(false);

      dispatch({
        type: types.SET_SCHOLARSHIP_REQUESTS,
        data: data.records,
      });

      showSuccess("Published successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getScholarshipSchedule = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/scholarship/schedules");

      dispatch({
        type: types.SET_SCHOLARSHIP_SCHEDULE,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const saveScholarshipSchedule = (payload, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/scholarship/schedules", payload);

      dispatch({
        type: types.SET_SCHOLARSHIP_SCHEDULE,
        data,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};
