import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import SearchBox from "../../common/components/SearchBox";
import {
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Badge,
  Divider,
  TextField,
  Tooltip,
} from "@mui/material";
import { connect } from "react-redux";
import { CardContent, Toolbar } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  getBudgetPostRequests,
  getHTPostLimits,
  getBudgetPostRequestPositions,
  deleteBudgetPostRequest,
} from "../../../../store/posts/actions";
import ReactPaginate from "react-paginate";
import PostRequestDetailsDialog from "../components/PostRequestDetailsDialog";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { CSVLink } from "react-csv";
import BudgetPostRequestDialog from "../components/BudgetPostRequestDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const PostsRequest = (props) => {
  useEffect(() => {
    document.title = "TMIS | Post Management - Post request";
  }, []);

  const {
    user,
    loading,
    budgetPostRequests,
    getBudgetPostRequests,
    htPostLimits,
    getHTPostLimits,
    getBudgetPostRequestPositions,
    deleteBudgetPostRequest,
  } = props;

  useEffect(() => {
    getBudgetPostRequests();
    getHTPostLimits();

    if (user?.selectedEntity.role === "HEAD_TEACHER")
      getBudgetPostRequestPositions();
  }, [user?.selectedEntity, user?.selectedAcademicYear]);

  const [searchTeam, setSearchTeam] = useState("");

  const [filteredRequests, setFilteredRequests] = useState([
    ...budgetPostRequests,
  ]);

  useEffect(() => {
    const filtered = [...budgetPostRequests].filter(
      ({
        positionName,
        schoolName,
        sectorName,
        districtName,
        schoollevelName,
        qualificationLevelName,
      }) =>
        (
          (schoolName || "") +
          (schoollevelName || "") +
          (qualificationLevelName || "") +
          (sectorName || "") +
          (districtName || "") +
          (positionName || "")
        )
          .replace(/\s/g, "")
          .toLowerCase()
          .includes(searchTeam.toLowerCase().replace(/\s/g, ""))
    );
    setFilteredRequests(filtered);

    paginate(filtered);
  }, [searchTeam, budgetPostRequests]);

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 20;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(filteredRequests);
  }, [itemOffset, itemsPerPage, filteredRequests]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredRequests.length || 0;

    setItemOffset(newOffset);
  };

  const getNo = (data, key, idToSearch) =>
    data.findIndex((item) => item[key] === idToSearch);

  const [showBudgetPostRequestDialog, setShowBudgetPostRequestDialog] =
    useState(false);

  return (
    <div className="px-4 pt-2">
      <div className="border rounded">
        <AppBar position="static" elevation={0} className="app-bar w-100">
          <Toolbar
            style={{
              minHeight: "0px",
              borderBottom: "3px solid #e9ecef",
            }}
            className=" my-0 py-1 bg-light"
          >
            <Typography
              variant="p"
              noWrap
              component="div"
              className="d-flex  ilign-items-center text-uppercase text-primary font-weight-bold"
            >
              <span className="text-dark">Post Requests </span>
              <span className="material-icons" style={{ color: "#cecece" }}>
                chevron_right
              </span>

              <span
                className="d-block text-truncate"
                style={{ maxWidth: "70%" }}
              >
                {user?.selectedEntity?.name}{" "}
                {!!user?.selectedEntity?.type &&
                !(user?.selectedEntity?.name)
                  .toUpperCase()
                  .includes(user?.selectedEntity?.type)
                  ? user?.selectedEntity?.type
                  : ""}
                {user?.selectedEntity?.id === "00000000" && <>S</>}
              </span>

              {["HEAD_TEACHER"].includes(user?.selectedEntity.role) && (
                <Button
                  className="ml-2"
                  disabled={loading}
                  onClick={() => {
                    setShowBudgetPostRequestDialog(true);
                  }}
                  variant="contained"
                  size="small"
                  disableElevation
                  color="primary"
                  style={{
                    borderRadius: "8px",
                    height: "32px",
                    alignItems: "center",
                    fontSize: ".75rem",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform: "initial",
                  }}
                >
                  <span className="material-icons">add</span>
                  New requests
                </Button>
              )}
            </Typography>

            <CSVLink
              data={budgetPostRequests}
              filename={`Post requests - ${
                user?.selectedEntity?.name || user?.selectedEntity?.name
              }.csv`}
              className="hidden"
              id="downloadBTN"
              target="_blank"
            />
            <Tooltip title="Download">
              <span className="ml-2">
                <IconButton
                  size="small"
                  disabled={loading || !budgetPostRequests.length}
                  className=" mr-1"
                  color="primary"
                  onClick={() => document.getElementById("downloadBTN").click()}
                >
                  <span className="material-icons">file_download</span>
                </IconButton>
              </span>
            </Tooltip>

            <Box sx={{ flexGrow: 1 }} />

            {/* <span className="badge text-primary d-inline-flex align-items-center border border-info mr-2">
              Limit: A0:{" "}
              <span
                className={`badge badge-${
                  htPostLimits.a0Limit > 0 ? "primary" : "danger"
                } mx-1`}
              >
                {htPostLimits.a0Limit || 0}
              </span>
              {" - "} A1:{" "}
              <span
                className={`badge badge-${
                  htPostLimits.a1Limit > 0 ? "primary" : "danger"
                }  mx-1`}
              >
                {htPostLimits.a1Limit || 0}
              </span>
              {" - "} A2:{" "}
              <span
                className={`badge badge-${
                  htPostLimits.a2Limit > 0 ? "primary" : "danger"
                } ml-1`}
              >
                {htPostLimits.a2Limit || 0}
              </span>
            </span> */}
            <Box sx={{ display: { xs: "flex", md: "flex" } }}>
              <SearchBox onSearch={onSearch} placeholder="Search…" />
            </Box>
          </Toolbar>
        </AppBar>

        <div
          style={{
            backgroundColor: "#f3f4f6",
            borderRadius: "0.5rem",
            boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
            MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            height: "69vh",
            overflowY: "auto",
          }}
          className="px-3"
        >
          <table className="table table-striped table-hover table-sm  fixTableHead">
            {!!budgetPostRequests.length && (
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th scope="col">#</th>

                  {user?.selectedEntity?.id === "00000000" && (
                    <th scope="col">DISTRICT</th>
                  )}

                  {user?.selectedEntity?.type === "DISTRICT" && (
                    <th scope="col">Sector</th>
                  )}

                  {user?.selectedEntity?.type !== "SCHOOL" && (
                    <th scope="col">School</th>
                  )}

                  {user?.selectedEntity?.type !== "SCHOOL" && (
                    <th scope="col">School Level</th>
                  )}

                  <th scope="col">Position</th>
                  <th scope="col" className="text-center">
                    Qualification
                  </th>

                  <th scope="col" className="text-center">
                    Requested
                  </th>

                  {/* <th scope="col" className="text-center">
                    Approved
                  </th> */}
                  {/* <th scope="col" className="text-left">
                    Reason
                  </th> */}
                  {/* <th scope="col" className="text-center">
                    Status
                  </th> */}
                  <th scope="col" className="text-rcenteright"></th>
                </tr>
              </thead>
            )}

            {!!paginatedItems && (
              <tbody>
                {paginatedItems.map((request) => (
                  <BudgetPostRequestCard
                    user={user}
                    key={request.id}
                    index={getNo(budgetPostRequests, "id", request.id)}
                    request={request}
                    selectedEntity={user?.selectedEntity || undefined}
                    htPostLimits={htPostLimits}
                    deleteBudgetPostRequest={deleteBudgetPostRequest}
                  />
                ))}
              </tbody>
            )}
          </table>

          {!paginatedItems?.length && (
            <div className="jumbotron jumbotron-fluid text-center">
              <div className="container py-5 ">
                <p className="lead">
                  {loading ? "Fetching data..." : "No record found"}{" "}
                </p>
              </div>
            </div>
          )}

          {budgetPostRequests.length > 20 && (
            <ReactPaginate
              breakLabel="..."
              nextLabel={
                <>
                  <span className="pr-1 d-none d-md-inline">Next</span>
                  <i className="fas fa-angle-double-right"></i>
                </>
              }
              previousLabel={
                <>
                  <i className="fas fa-angle-double-left"></i>
                  <span className="pl-1  d-none d-md-inline">Previous</span>
                </>
              }
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              onPageChange={handlePageClick}
              pageCount={pageCount}
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              pageLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
              previousLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
              nextLinkClassName="btn btn-outline-dark btn-sm ml-1"
              activeLinkClassName="active"
            />
          )}
        </div>
      </div>

      {showBudgetPostRequestDialog && (
        <BudgetPostRequestDialog
          showDialog={showBudgetPostRequestDialog}
          setShowDialog={setShowBudgetPostRequestDialog}
          // position={{}}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({
  user,
  loading,
  budgetPostRequests,
  htPostLimits,
}) => {
  return { user, loading, budgetPostRequests, htPostLimits };
};
export default connect(mapStateToProps, {
  getBudgetPostRequests,
  getHTPostLimits,
  getBudgetPostRequestPositions,
  deleteBudgetPostRequest,
})(PostsRequest);

const BudgetPostRequestCard = (props) => {
  const { user, request, index, selectedEntity, deleteBudgetPostRequest } =
    props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showPostRequestApprovalDialog, setShowPostRequestApprovalDialog] =
    useState(false);

  const [confirmRemoveRequest, setConfirmRemoveRequest] = useState(false);

  return (
    <>
      <tr className={``}>
        <th scope="row" className="text-left">
          {index + 1}
        </th>

        {selectedEntity?.id === "00000000" && <td>{request.districtName}</td>}
        {selectedEntity?.type === "DISTRICT" && <td>{request.sectorName}</td>}
        {selectedEntity?.type !== "SCHOOL" && <td>{request.schoolName}</td>}
        {selectedEntity?.type !== "SCHOOL" && (
          <td>{request.schoollevelName}</td>
        )}
        <td className="text-left">{request.positionName}</td>

        <td className="text-center">{request.qualificationLevelName}</td>

        <td className="text-center">
          {" "}
          <span className="badge badge-secondary">
            {request.requestedNumber}
          </span>{" "}
        </td>
        {/* <td className="text-center">
          {request.approvedNumber && request.statusId === 3 ? (
            <span className="badge badge-success">
              {request.approvedNumber}
            </span>
          ) : (
            "-"
          )}{" "}
        </td> */}

        {/* <td className="text-center">
          <span
            className={`badge badge-${
              request.statusId === 1
                ? "secondary"
                : request.statusId === 2
                ? "primary"
                : request.statusId === 3 || request.statusId === 6
                ? "success"
                : request.statusId === 4
                ? "warning"
                : "danger"
            } `}
          >
            {request.statusName}
          </span>
        </td> */}
        <td className="text-right py-1">
          {/* <Button
            onClick={() => {
              setShowPostRequestApprovalDialog(true);
            }}
            variant="contained"
            size="small"
            disableElevation
            color="primary"
            style={{
              borderRadius: "8px",
              height: "24px",
              // float: "right",
              // border: `1px solid rgb(7, 142, 206)`,
              // color: "#fff",
              alignItems: "center",
              fontSize: ".75rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textTransform: "initial",
              // backgroundColor: "rgb(7, 142, 206)",
            }}
          >
            <span className="material-icons mr-1">open_in_new</span>
            Open
          </Button> */}

          {/* <Tooltip title="Open"> */}
          <IconButton
            size="small"
            className="p-0"
            // onClick={() => setShowPostRequestApprovalDialog(true)}
            onClick={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
              {/* <span className="material-icons">open_in_new</span> */}
            </Badge>
          </IconButton>
          {/* </Tooltip> */}

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                setShowPostRequestApprovalDialog(true);
              }}
              className="text-primary font-weight-bolder"
            >
              <span className="material-icons mr-1">open_in_new</span>
              Open
            </MenuItem>

            {(request.statusId === 1 ||
              (["DDE"].includes(user?.selectedEntity.role) &&
                request.statusId === 5) ||
              ["REB", "RTB"].includes(user?.selectedEntity.role)) && (
              <MenuItem
                onClick={(e) => {
                  handleCloseMenu();
                  setConfirmRemoveRequest(true);
                }}
                className="text-danger"
              >
                <span className="material-icons mr-1">
                  remove_circle_outline
                </span>
                Remove
              </MenuItem>
            )}
          </Menu>
        </td>
      </tr>

      {showPostRequestApprovalDialog && (
        <PostRequestDetailsDialog
          showDialog={showPostRequestApprovalDialog}
          setShowDialog={setShowPostRequestApprovalDialog}
          postRequest={request}
        />
      )}

      {confirmRemoveRequest && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveRequest}
          message={`Are you sure you want to remove this request?`}
          setConfirmationDialog={setConfirmRemoveRequest}
          onYes={() => {
            deleteBudgetPostRequest(request.id, setConfirmRemoveRequest);
          }}
        />
      )}
    </>
  );
};
