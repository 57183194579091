import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import { isEmpty } from "lodash";
import { connect } from "react-redux";
import MyProfile from "./pages/MyProfile";
import ChangePassword from "./pages/ChangePassword";
import SystemUsers from "./pages/SystemUsers";

import { getRoles } from "../../../store/users/actions";
import { getDistricts, getTeachingLevels } from "../../../store/posts/actions";
import GlobalSettings from "./pages/GlobalSettings";

const Layout = (props) => {
  useEffect(() => {
    document.title = "TMIS | Security";
  }, []);

  const { user, getRoles, getDistricts, getTeachingLevels } = props;
  const history = useHistory();

  useEffect(() => {
    getRoles();
    getDistricts();
    getTeachingLevels();
  }, []);

  return (
    <React.Fragment>
      <Switch>
        <Route path="/in/security/my-profile" component={MyProfile} />
        <Route path="/in/security/change-password" component={ChangePassword} />
        <Route path="/in/security/system-users" component={SystemUsers} />
        <Route path="/in/security/global-settings" component={GlobalSettings} />

        <Route
          path="/in/security"
          component={() => <Redirect to="/in/welcome" />}
        />
      </Switch>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};
export default connect(mapStateToProps, {
  getRoles,
  getDistricts,
  getTeachingLevels,
})(Layout);
