import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Typography,
  Box,
  CardContent,
  Toolbar,
  IconButton,
  Badge,
  Divider,
  Menu,
  MenuItem,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Tooltip,
  ListItemButton,
  ListItemText,
  List,
  ListSubheader,
  Card,
  CardHeader,
  ListItem,
  CardActions,
  ListItemAvatar,
  Avatar,
  Autocomplete,
} from "@mui/material";

import { connect } from "react-redux";
import NoRecordMessage from "../../common/components/NoRecordMessage";

import { saveScholarshipCourse } from "../../../../store/scholarship/actions";

import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import ReactPaginate from "react-paginate";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ScholarshipDepartments = (props) => {
  const {
    loading,
    user,
    scholarshipCourses,
    qualificationLevels,
    saveScholarshipCourse,
  } = props;

  const [showAdd, setShowAdd] = useState(false);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 5;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(scholarshipCourses);
  }, [itemOffset, itemsPerPage, scholarshipCourses]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % scholarshipCourses.length || 0;

    setItemOffset(newOffset);
  };

  const getNo = (data, key, idToSearch) =>
    data.findIndex((item) => item[key] === idToSearch);

  const [formData, setFormData] = useState({
    id: null,
    name: "",
    limitNumber: 0,
    qualifications: [],
    isActive: true,
  });

  const onClose = () => {
    setFormData({
      id: null,
      name: "",
      limitNumber: "",
      qualifications: [],
      isActive: true,
    });
    setShowAdd(false);
  };

  return (
    <Card className="border" variant="outlined">
      <div
        style={{
          height: "50vh",
          overflow: "auto",
        }}
      >
        <List
          dense
          component="div"
          subheader={
            <ListSubheader
              className="border-bottom  bg-light text-uppercase text-primary  d-flex justify-content-between align-items-center"
              component="div"
            >
              Departments
              <IconButton color="primary" onClick={() => setShowAdd(true)}>
                <span className="material-icons">add</span>
              </IconButton>
            </ListSubheader>
          }
        >
          {paginatedItems?.map((specialization, index) => (
            <DepartmentCard
              index={getNo(scholarshipCourses, "id", specialization.id)}
              key={specialization.id}
              loading={loading}
              user={user}
              specialization={specialization}
              setFormData={setFormData}
              setShowAdd={setShowAdd}
              saveScholarshipCourse={saveScholarshipCourse}
              onClose
            />
          ))}
        </List>
      </div>

      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <>
            <i className="fas fa-angle-double-right"></i>
          </>
        }
        previousLabel={
          <>
            <i className="fas fa-angle-double-left"></i>
          </>
        }
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        onPageChange={handlePageClick}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
        previousLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
        nextLinkClassName="btn btn-outline-dark btn-sm ml-1"
        activeLinkClassName="active"
      />

      {showAdd && (
        <AddOrEditSpecialization
          loading={loading}
          showAdd={showAdd}
          formData={formData}
          setFormData={setFormData}
          qualificationLevels={qualificationLevels}
          saveScholarshipCourse={saveScholarshipCourse}
          onClose={onClose}
        />
      )}
    </Card>
  );
};

const mapStateToProps = ({
  user,
  loading,
  scholarshipCourses,
  qualificationLevels,
}) => {
  return {
    user,
    loading,
    scholarshipCourses,
    qualificationLevels,
  };
};

export default connect(mapStateToProps, { saveScholarshipCourse })(
  ScholarshipDepartments
);

const DepartmentCard = (props) => {
  const {
    loading,
    user,
    specialization,
    index,
    setFormData,
    setShowAdd,
    saveScholarshipCourse,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showConfirmDisable, setShowConfirmDisable] = useState(false);

  return (
    <>
      <ListItem
        style={{
          backgroundColor: !specialization.isActive ? "#ff00000f" : "#fff",
        }}
        divider
        key={specialization.id}
        secondaryAction={
          <span className="d-flex align-items-center">
            {!specialization.isActive && (
              <span
                style={{ fontSize: "12px" }}
                className={`material-icons text-danger mr-1`}
              >
                lock
              </span>
            )}

            <Tooltip title="Limit">
              <span className="badge badge-pill">
                [{specialization.limitNumber || 0}]
              </span>
            </Tooltip>

            <IconButton size="small" onClick={handleOpenMenu}>
              <Badge color="info">
                <span className="material-icons">more_vert</span>
              </Badge>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleCloseMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 2,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 15,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    handleCloseMenu();

                    setFormData(specialization);
                    setShowAdd(true);
                  }}
                  className={`text-primary`}
                >
                  <span className="material-icons mr-2">edit</span>
                  Edit
                </MenuItem>
              </span>

              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    handleCloseMenu();
                    setShowConfirmDisable(true);
                  }}
                  className={`text-${
                    !specialization.isActive ? "success" : "danger"
                  } font-weight-light`}
                >
                  {specialization.isActive && (
                    <span className="material-icons mr-2">close</span>
                  )}
                  {!specialization.isActive && (
                    <span className="material-icons mr-2">check</span>
                  )}
                  {specialization.isActive ? "Disable" : "Enable"}
                </MenuItem>
              </span>
            </Menu>
          </span>
        }
      >
        <ListItemText
          primary={
            <strong className="text-primary">
              {index + 1}: {specialization.name}
            </strong>
          }
          secondary={
            <>
              {specialization.qualifications.length ? (
                specialization.qualifications.map((qualification) => (
                  <span
                    key={qualification.id}
                    className="badge badge-secondary"
                    style={{ borderRadius: "0px" }}
                  >
                    {qualification.name}
                  </span>
                ))
              ) : (
                <span className="badge badge-pill">
                  <small className="text-danger ">Qualification not set</small>
                </span>
              )}
            </>
          }
        />
      </ListItem>

      {showConfirmDisable && (
        <ConfirmationDialog
          confirmationDialog={showConfirmDisable}
          message={
            <>
              Are you sure you want to{" "}
              <strong
                className={`text-${
                  specialization.isActive ? "danger" : "success"
                }`}
              >
                {specialization.isActive ? "disable" : "enable"}
              </strong>{" "}
              this department?
            </>
          }
          setConfirmationDialog={setShowConfirmDisable}
          noPreFormat={true}
          onYes={() => {
            const qualifications = [];

            specialization.qualifications.forEach((q) =>
              qualifications.push(q.id)
            );

            saveScholarshipCourse(
              {
                id: specialization.id,
                name: specialization.name,
                isActive: !specialization.isActive,
                qualifications,
              },
              () => setShowConfirmDisable(false)
            );
          }}
        />
      )}
    </>
  );
};

const AddOrEditSpecialization = (props) => {
  const {
    loading,
    showAdd,
    formData,
    setFormData,
    qualificationLevels,
    saveScholarshipCourse,
    onClose,
  } = props;

  // const [qualifications, setQualifications] = useState(formData.qualifications ||[]);

  const onSave = () => {
    const qualifications = [];

    formData.qualifications.forEach((q) => qualifications.push(q.id));

    saveScholarshipCourse(
      {
        id: formData.id,
        name: formData.name,
        limitNumber: formData.limitNumber || 0,
        qualifications,
      },
      () => onClose()
    );
  };

  return (
    <Dialog onClose={onClose} open={showAdd} fullWidth maxWidth="xs">
      <DialogTitle className="text-primary">
        <Typography variant="p" noWrap component="div">
          <span className="text-primary">
            {" "}
            {formData.id ? "Edit " : "Add New "} Department
          </span>
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            minHeight: "5vh",
          }}
        >
          <TextField
            multiline
            rows={2}
            style={{ backgroundColor: !!formData.id ? "#eee" : "#fff" }}
            InputProps={{ readOnly: !!formData.id }}
            autoFocus
            fullWidth
            size="small"
            name="knowledgeDomainName"
            label={`Name`}
            placeholder={`Name`}
            variant="outlined"
            className="mt-3"
            value={formData.name || ""}
            onChange={(e) => {
              setFormData({
                ...formData,
                name: e.target.value || "",
              });
            }}
          />

          <Autocomplete
            multiple
            className="mt-3"
            size="small"
            defaultValue={[]}
            value={formData.qualifications}
            options={qualificationLevels.filter((q) =>
              [1, 2, 3].includes(q.id)
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <ListItem dense {...props}>
                <ListItemAvatar>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                </ListItemAvatar>
                <ListItemText primary={option.name} />
              </ListItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name="qualifications"
                label="Qualifications"
                placeholder="Qualifications"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
            onChange={(event, qualification) => {
              setFormData({
                ...formData,
                qualifications: qualification || [],
              });
            }}
          />

          <TextField
            autoFocus
            type="number"
            fullWidth
            size="small"
            name="limit"
            label={`Limit`}
            placeholder={`Limit`}
            variant="outlined"
            className="mt-3"
            value={formData.limitNumber || ""}
            onChange={(e) => {
              setFormData({
                ...formData,
                limitNumber: e.target.value || "",
              });
            }}
          />
        </div>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center py-4">
        <Button
          variant="contained"
          color="primary"
          onClick={onSave}
          type="button"
          className="px-5"
          disabled={
            loading ||
            !formData.name ||
            !formData?.qualifications?.length ||
            !formData.limitNumber
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
