import {
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
} from "recharts";

const convertToPieData = (data) => {
  return data.map((item) => ({
    name: item.reason,
    value: item.count,
  }));
};

export default function ExitReasonsChart({ data }) {
  const barData = convertToPieData(data);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={barData}
        margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip
          formatter={(value, label) => [
            `${value?.toLocaleString()} ${
              value == 1 ? "Teacher" : "Teachers"
            }`,
            label,
          ]}
        />
        <Legend />
        <Bar
          dataKey="value"
          fill="hsl(var(--chart-1))"
          radius={4}
          name="Exit Reasons"
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
