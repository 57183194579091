import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import ChartCard from "../../../common/components/ChartCard";
import VacancyDistributionChart from "./charts/VacancyDistributionChart";
import QualificationVacanciesChart from "./charts/QualificationVacanciesChart";
import PositionVacanciesChart from "./charts/PositionVacanciesChart";
import TeachingLevelVacanciesChart from "./charts/TeachingLevelVacanciesChart";
import OversightSkeleton from "../../../common/components/OversightSkeleton";

const VacanciesOversight = (props) => {
  const { user, loading } = props;

  const [isLoading1, setIsLoading1] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    if (user?.selectedEntity) {
      getPlacementOversights();
    }
  }, [user?.selectedEntity]);
  console.log("user", user);
  const getPlacementOversights = async () => {
    try {
      setIsLoading1(true);
      const { data } = await axios.get(
        `/api/oversight/vacancies/${user?.selectedEntity?.id}/${user?.selectedEntity?.scopeId}`
      );

      setData(data);
      setIsLoading1(false);
    } catch (error) {
      setIsLoading1(false);
    }
  };

  if (isLoading1 || loading) {
    return <OversightSkeleton />;
  }

  return (
    <div className="pl-4 pt-2">
      <div className="row w-100">
        <div className="col-12 col-md-6 p-0">
          <ChartCard
            title="Vacancy Distribution by District"
            subheader="Distribution of vacancies across districts"
          >
            <VacancyDistributionChart data={data?.districtVacancyDistributions} />
          </ChartCard>
        </div>
        <div className="col-12 col-md-6 p-0">
          <ChartCard
            title="Vacancy Distribution by Qualification"
            subheader="Distribution of vacancies by qualification"
          >
            <QualificationVacanciesChart
              data={data?.qualificationVacancyDistributions}
            />
          </ChartCard>
        </div>
        <div className="col-12 col-md-6 p-0">
          <ChartCard
            title="Vacancy Distribution by Teaching Level"
            subheader="Distribution of vacancies by teaching level"
          >
            <TeachingLevelVacanciesChart
              data={data?.teachingLevelVacancyDistributions}
            />
          </ChartCard>
        </div>
        <div className="col-12 col-md-6 p-0">
          <ChartCard
            title="Vacancy Distribution by Position"
            subheader="Distribution of vacancies by position"
          >
            <PositionVacanciesChart data={data?.positionVacancyDistributions} />
          </ChartCard>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return { user, loading };
};

export default connect(mapStateToProps, {})(VacanciesOversight);
