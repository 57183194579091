import React, { useState, useEffect } from "react";
import Login from "./Login";
import PhoneIcon from "@mui/icons-material/Phone";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DescriptionIcon from "@mui/icons-material/Description";
import GroupIcon from "@mui/icons-material/Group";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import logoSmall from "../../assets/REB_Logo.png";
import SupportStaff from "./SupportStaff";

const Home = () => {
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showSupportStaffDialog, setShowSupportStaffDialog] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 15) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <React.Fragment>
      <div>
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-9ndCyUaIbzAi2FUVXJi0CjmCapSmO7SnpJef0486qhLnuZ2cdeRhO02iuK6FUUVM"
          crossOrigin="anonymous"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <header
          className={`fixed-top transition ${
            isScrolled ? "bg-white shadow-sm py-2" : "bg-transparent py-3"
          }`}
          style={{ transition: "all 0.3s ease" }}
        >
          <div className="container d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-2">
              <img
                src={logoSmall}
                alt="REB Logo"
                style={{ height: "70px", width: "auto" }}
              />
              <span
                className="fs-4 fw-bold"
                style={{
                  background: "linear-gradient(to right, #0d6efd, #fd7e14)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                TMIS
              </span>
            </div>
            <div className="d-flex align-items-center gap-3">
              <div
                onClick={() => setShowSupportStaffDialog(true)}
                className="d-flex align-items-center gap-2 btn btn-primary rounded-pill px-4 py-2 shadow"
              >
                <PhoneIcon style={{ fontSize: "18px" }} />
                <span className="fw-medium">SUPPORT STAFF</span>
              </div>
              <div
                onClick={() => setShowLoginDialog(true)}
                className="d-flex align-items-center gap-1 text-decoration-none text-primary fw-semibold cursor-pointer"
                style={{ transition: "all 0.3s ease" }}
                onMouseOver={(e) => {
                  e.currentTarget.style.color = "#fd7e14";
                  e.currentTarget.querySelector(".chevron").style.transform =
                    "translateX(4px)";
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.color = "";
                  e.currentTarget.querySelector(".chevron").style.transform =
                    "";
                }}
              >
                <span>LOGIN</span>
                <ChevronRightIcon
                  className="chevron"
                  style={{
                    fontSize: "18px",
                    transition: "transform 0.3s ease",
                  }}
                />
              </div>
            </div>
          </div>
        </header>

        <section
          className="position-relative pt-5"
          style={{
            paddingTop: "7rem",
            paddingBottom: "5rem",
            background:
              "linear-gradient(135deg, #0d6efd20, #0a58ca30, #0d47a115)",
          }}
        >
          <div className="position-absolute" style={{ inset: 0, zIndex: 0 }}>
            <div
              className="position-absolute"
              style={{
                top: "80px",
                right: "80px",
                width: "300px",
                height: "300px",
                background: "#fd7e14",
                borderRadius: "50%",
                mixBlendMode: "multiply",
                filter: "blur(100px)",
                opacity: "0.2",
                animation: "blob 7s infinite",
              }}
            ></div>
            <div
              className="position-absolute"
              style={{
                bottom: "80px",
                left: "80px",
                width: "300px",
                height: "300px",
                background: "#0d6efd",
                borderRadius: "50%",
                mixBlendMode: "multiply",
                filter: "blur(100px)",
                opacity: "0.2",
                animation: "blob 7s infinite 2s",
              }}
            ></div>
            <div
              className="position-absolute"
              style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "300px",
                height: "300px",
                background: "#0d6efd",
                borderRadius: "50%",
                mixBlendMode: "multiply",
                filter: "blur(100px)",
                opacity: "0.2",
                animation: "blob 7s infinite 4s",
              }}
            ></div>
          </div>

          <div className="container position-relative" style={{ zIndex: 1 }}>
            <div className="row justify-content-center text-center">
              <div className="col-md-8 col-lg-6">
                <h1
                  className="display-4 fw-bold mb-4 mt-5"
                  style={{
                    background:
                      "linear-gradient(to right, #0d47a1, #0d6efd, #fd7e14)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Welcome to TMIS
                </h1>
                <p className="fs-5 text-secondary mb-5">
                  Teacher Management Information System
                </p>
                <div className="position-relative d-inline-block">
                  <div
                    className="position-absolute"
                    style={{
                      inset: "-2px",
                      background: "linear-gradient(to right, #e76f51, #0d6efd)",
                      borderRadius: "50px",
                      filter: "blur(8px)",
                      opacity: "0.7",
                      transition: "opacity 0.2s ease",
                    }}
                  ></div>
                  <button
                    onClick={() => setShowLoginDialog(true)}
                    className="btn btn-lg position-relative d-flex align-items-center gap-2 border-0 shadow-lg"
                    style={{
                      background: "linear-gradient(to right, #e76f51, #0d6efd)",
                      color: "white",
                      padding: "0.75rem 2rem",
                      borderRadius: "50px",
                      transition: "all 0.3s ease",
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.transform = "scale(1.05)";
                      e.currentTarget.querySelector(".arrow").style.transform =
                        "translateX(4px)";
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.transform = "";
                      e.currentTarget.querySelector(".arrow").style.transform =
                        "";
                    }}
                  >
                    LOGIN
                    <ArrowForwardIcon
                      className="arrow"
                      style={{
                        fontSize: "20px",
                        transition: "transform 0.3s ease",
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="position-absolute bottom-0 start-0 w-100">
            <hr
              className="m-0 border-0"
              style={{ height: "1px", background: "#dee2e6" }}
            />
          </div>
        </section>

        <section className="py-5 bg-white">
          <div className="container py-4">
            <div className="text-center mb-5">
              <h2 className="fs-1 fw-bold text-dark mb-3">How TMIS Works</h2>
              <p
                className="text-secondary mx-auto"
                style={{ maxWidth: "600px" }}
              >
                Our comprehensive system manages the entire teacher allocation
                process from request to placement
              </p>
            </div>

            <div className="row g-4">
              <div className="col-md-4">
                <div
                  className="card h-100 border-light rounded-4 shadow hover-shadow"
                  style={{ transition: "all 0.3s ease" }}
                  onMouseOver={(e) => {
                    e.currentTarget.classList.add("shadow-lg");
                    e.currentTarget.style.borderColor = "#cfe2ff";
                    e.currentTarget.style.background =
                      "linear-gradient(to bottom right, #f8f9ff, #f0f8ff)";
                    const icon = e.currentTarget.querySelector(".feature-icon");
                    icon.style.transform = "rotate(3deg) scale(1.1)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.classList.remove("shadow");
                    e.currentTarget.style.borderColor = "";
                    e.currentTarget.style.background = "";
                    const icon = e.currentTarget.querySelector(".feature-icon");
                    icon.style.transform = "";
                  }}
                >
                  <div className="card-body p-4">
                    <div
                      className="feature-icon d-flex align-items-center justify-content-center mb-4 shadow"
                      style={{
                        width: "64px",
                        height: "64px",
                        background:
                          "linear-gradient(to bottom right, #fd7e14, #e76f51)",
                        borderRadius: "1rem",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <DescriptionIcon
                        style={{ color: "white", fontSize: "32px" }}
                      />
                    </div>
                    <h3 className="fs-4 fw-bold text-dark mb-3">
                      Post Request
                    </h3>
                    <p className="text-secondary mb-4">
                      Head Teachers have the options to request a number of
                      posts based on the positions they have at their schools.
                      The DDEs have the interface that shows all the requested
                      posts from schools before they are sent to REB. Then REB
                      get the total number of post requested from all districts
                      grouped by qualifications.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div
                  className="card h-100 border-light rounded-4 shadow hover-shadow"
                  style={{ transition: "all 0.3s ease" }}
                  onMouseOver={(e) => {
                    e.currentTarget.classList.add("shadow-lg");
                    e.currentTarget.style.borderColor = "#cfe2ff";
                    e.currentTarget.style.background =
                      "linear-gradient(to bottom right, #f8f9ff, #f0f8ff)";
                    const icon = e.currentTarget.querySelector(".feature-icon");
                    icon.style.transform = "rotate(3deg) scale(1.1)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.classList.remove("shadow");
                    e.currentTarget.style.borderColor = "";
                    e.currentTarget.style.background = "";
                    const icon = e.currentTarget.querySelector(".feature-icon");
                    icon.style.transform = "";
                  }}
                >
                  <div className="card-body p-4">
                    <div
                      className="feature-icon d-flex align-items-center justify-content-center mb-4 shadow"
                      style={{
                        width: "64px",
                        height: "64px",
                        background:
                          "linear-gradient(to bottom right, #0d6efd, #0a58ca)",
                        borderRadius: "1rem",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <GroupIcon style={{ color: "white", fontSize: "32px" }} />
                    </div>
                    <h3 className="fs-4 fw-bold text-dark mb-3">
                      Post distribution
                    </h3>
                    <p className="text-secondary mb-4">
                      Posts are distributed to districts by REB based on the
                      guidelines of MINECOFIN. Then DDEs also distribute the
                      received post limits to their schools based on their
                      analysis and need by type of position and by school.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div
                  className="card h-100 border-light rounded-4 shadow hover-shadow"
                  style={{ transition: "all 0.3s ease" }}
                  onMouseOver={(e) => {
                    e.currentTarget.classList.add("shadow-lg");
                    e.currentTarget.style.borderColor = "#cfe2ff";
                    e.currentTarget.style.background =
                      "linear-gradient(to bottom right, #f8f9ff, #f0f8ff)";
                    const icon = e.currentTarget.querySelector(".feature-icon");
                    icon.style.transform = "rotate(3deg) scale(1.1)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.classList.remove("shadow");
                    e.currentTarget.style.borderColor = "";
                    e.currentTarget.style.background = "";
                    const icon = e.currentTarget.querySelector(".feature-icon");
                    icon.style.transform = "";
                  }}
                >
                  <div className="card-body p-4">
                    <div
                      className="feature-icon d-flex align-items-center justify-content-center mb-4 shadow"
                      style={{
                        width: "64px",
                        height: "64px",
                        background:
                          "linear-gradient(to bottom right, #fd7e14, #0d6efd)",
                        borderRadius: "1rem",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <LocationOnIcon
                        style={{ color: "white", fontSize: "32px" }}
                      />
                    </div>
                    <h3 className="fs-4 fw-bold text-dark mb-3">
                      Teacher placement
                    </h3>
                    <p className="text-secondary mb-4">
                      Teachers are placed to the vacant posts. No teacher can be
                      placed if there are no vacant posts. The teacher placement
                      list are generated on monthly basis and be kept in the
                      system for future references.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="bg-white border-top py-4">
          <div className="container">
            <div className="text-center">
              <p className="text-secondary mb-0">
                ©{new Date()?.getFullYear()} RWANDA BASIC EDUCATION BOARD
              </p>
            </div>
          </div>
        </footer>

        <style jsx global>{`
          @keyframes blob {
            0% {
              transform: scale(1);
            }
            33% {
              transform: scale(1.1);
            }
            66% {
              transform: scale(0.9);
            }
            100% {
              transform: scale(1);
            }
          }
          @keyframes pulse {
            0% {
              opacity: 0.7;
            }
            50% {
              opacity: 0.4;
            }
            100% {
              opacity: 0.7;
            }
          }
          .hover-shadow:hover {
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
          }
        `}</style>
      </div>

      {showLoginDialog && (
        <Login
          showLoginDialog={showLoginDialog}
          setShowLoginDialog={setShowLoginDialog}
        />
      )}

      {showSupportStaffDialog && (
        <SupportStaff
          showSupportStaffDialog={showSupportStaffDialog}
          setShowSupportStaffDialog={setShowSupportStaffDialog}
        />
      )}
    </React.Fragment>
  );
};

export default Home;
