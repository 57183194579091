import { Tooltip, Legend } from "recharts";
import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";

export default function AgeDistributionMaternityLeaveChart({ data }) {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart height={400} data={data}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="ageGroup" />
        <YAxis />
        <Tooltip
          formatter={(value) => [
            `${value?.toLocaleString()} Teachers`,
            "Count",
          ]}
        />
        <Legend />
        <Bar
          dataKey="count"
          fill="hsl(var(--chart-1))"
          radius={4}
          name="Teachers"
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
