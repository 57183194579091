export const defaultState = {
  loading: false,
  onWait: false,
  fetchingUser: true,
  strongPassword: true,
  user: {},
  env: null,
  version: null,
  academicYears: [],
  userEntities: [],
  scopes: [],
};
