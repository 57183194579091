export const defaultState = {
  scholarshipCombinations: [],
  scholarshipCourses: [],
  scholarshipRequests: [],
  scholarshipDocuments: [],

  scholarshipRequestAttachments: [],
  scholarshipRequestLogs: [],
  eligibility: {},
  scholarshipSummary: {},

  scholarshipSchedule: null,
};
