import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import ChartCard from "../../../common/components/ChartCard";
import MaternityLeaveRequestsChart from "./charts/MaternityLeaveRequestsChart";
import MaternityLeaveReplacementsChart from "./charts/MaternityLeaveReplacementsChart";
import AgeDistributionMaternityLeaveChart from "./charts/AgeDistributionMaternityLeaveChart";
import QualificationMaternityLeaveChart from "./charts/QualificationMaternityLeaveChart";
import OversightSkeleton from "../../../common/components/OversightSkeleton";

const MaternityOversight = (props) => {
  const { user, loading } = props;

  const [isLoading1, setIsLoading1] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    if (user?.selectedEntity) {
      getPlacementOversights();
    }
  }, [user?.selectedEntity]);

  const getPlacementOversights = async () => {
    try {
      setIsLoading1(true);
      const { data } = await axios.get(
        `/api/oversight/maternity-leave/${user?.selectedEntity?.id}/${user?.selectedEntity?.scopeId}`
      );

      setData(data);
      setIsLoading1(false);
    } catch (error) {
      setIsLoading1(false);
    }
  };

  if (isLoading1 || loading) {
    return <OversightSkeleton />;
  }

  return (
    <div className="pl-4 pt-2">
      <div className="row w-100">
        <div className="col-12 col-md-6 p-0">
          <ChartCard
            title="Maternity Leave Requests"
            subheader="Distribution of maternity leave requests"
          >
            <MaternityLeaveRequestsChart
              data={data?.maternityLeaveRequestDistributions}
            />
          </ChartCard>
        </div>
        <div className="col-12 col-md-6 p-0">
          <ChartCard
            title="Maternity Leave Replacements"
            subheader="Number of teachers replaced during maternity leave"
          >
            <MaternityLeaveReplacementsChart
              data={data?.maternityLeaveReplacementDistributions}
            />
          </ChartCard>
        </div>
        <div className="col-12 col-md-6 p-0">
          <ChartCard
            title="Age Distribution of Maternity Leave"
            subheader="Age distribution of teachers taking maternity leave"
          >
            <AgeDistributionMaternityLeaveChart
              data={data?.maternityLeaveAgeDistributions}
            />
          </ChartCard>
        </div>
        <div className="col-12 col-md-6 p-0">
          <ChartCard
            title="Qualification of Maternity Leave"
            subheader="Qualification breakdown of teachers on maternity leave"
          >
            <QualificationMaternityLeaveChart
              data={data?.maternityLeaveQualificationDistributions}
            />
          </ChartCard>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return { user, loading };
};

export default connect(mapStateToProps, {})(MaternityOversight);
