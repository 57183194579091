import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import SearchBox from "./../common/components/SearchBox";
import {
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  CircularProgress,
  IconButton,
  Badge,
  Divider,
  TextField,
  Tooltip,
} from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { CardContent, Toolbar } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  getScholarshipCombinations,
  getScholarshipCourses,
  getScholarshipDocuments,
  getScholarshipRequests,
  deleteScholarshipRequest,
  approveOrRejectScholarshipRequest,
  getEligibility,
  executeScholarshipAlgorithm,
  getScholarshipSchedule,
} from "./../../../store/scholarship/actions";

import { getDistricts } from "./../../../store/posts/actions";

import ReactPaginate from "react-paginate";
import moment from "moment";
import AddOrUpdateScholarshipRequestDialog from "./components/AddOrUpdateScholarshipRequestDialog";
import ConfirmationDialog from "./../common/components/ConfirmationDialog";
import AlertDialog from "./../common/components/AlertDialog";
import PreviewTeacherProfile from "./components/PreviewTeacherProfile";
import PreviewScholarshipRequestDialog from "./components/PreviewScholarshipRequestDialog";
import { CSVLink } from "react-csv";
import axios from "axios";
import ScholarshipSummaryDialog from "./components/ScholarshipSummaryDialog";
import ScholarshipCriteriaDialog from "./components/ScholarshipCriteriaDialog";

function calculateTimeLeft(scholarshipSchedule) {
  const year = new Date().getFullYear();
  const deadLine = !!scholarshipSchedule
    ? new Date(moment(scholarshipSchedule.endOn).format("yyyy-MM-DD"))
    : new Date(moment().format("yyyy-MM-DD"));

  deadLine.setDate(deadLine.getDate() + 1);
  deadLine.setHours(deadLine.getHours() - 1);

  const toDay = new Date();

  const difference = +deadLine - +toDay;

  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
}

const PostsDistribution = (props) => {
  useEffect(() => {
    document.title = "TMIS | Scholarships";
  }, []);

  const {
    user,
    loading,
    env,

    scholarshipCombinations,
    scholarshipCourses,
    scholarshipDocuments,

    getScholarshipCombinations,
    getScholarshipCourses,
    getScholarshipDocuments,

    scholarshipRequests,
    getScholarshipRequests,

    deleteScholarshipRequest,
    approveOrRejectScholarshipRequest,

    districts,
    getDistricts,

    getEligibility,
    executeScholarshipAlgorithm,
    scholarshipSchedule,

    getScholarshipSchedule,
  } = props;

  useEffect(() => {
    if (!scholarshipCombinations.length) getScholarshipCombinations();

    if (!scholarshipCourses.length) getScholarshipCourses();

    if (!scholarshipDocuments.length) getScholarshipDocuments();

    if (!districts.length) getDistricts();

    getEligibility();
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecods, setTotalRecods] = useState(0);
  const [loader, setLoader] = useState(false);

  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 10
  );

  useEffect(() => {
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
    };
    getScholarshipRequests(query, setTotalRecods, setLoader);

    getScholarshipSchedule();
  }, [user.selectedEntity, user.selectedAcademicYear]);

  const onSearch = (term) => {
    setSearchTerm(term);

    if (term === "") {
      const _currentPage = 1;
      setCurrentPage(_currentPage);

      const _searchTerm = "";
      setSearchTerm(_searchTerm);

      const query = {
        searchTerm: _searchTerm,
        page: _currentPage,
        size: itemPerPage,
      };
      getScholarshipRequests(query, setTotalRecods, setLoader);
    }
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);

    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
    };

    getScholarshipRequests(query, setTotalRecods, setLoader);
  };

  const [showFormDialog, setShowFormDialog] = useState(false);

  const [downloadbleData, setDownloadbleData] = useState([]);

  const dispatch = useDispatch();

  const getDownloadbleData = async () => {
    try {
      setDownloadbleData([]);

      dispatch({ type: "START_LOADING" });

      const { data } = await axios.get("/api/scholarship/all-requests");

      dispatch({ type: "END_LOADING" });

      setDownloadbleData(data);
    } catch (error) {
      dispatch({ type: "END_LOADING" });
    }
  };

  useEffect(() => {
    if (!!downloadbleData.length)
      document.getElementById("downloadBTN").click();
  }, [downloadbleData]);

  const [confirmExecute, setConfirmExecute] = useState(false);
  const [showScholarshipSummary, setShowScholarshipSummary] = useState(false);

  const [timeLeft, setTimeLeft] = useState(
    calculateTimeLeft(scholarshipSchedule)
  );

  useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(scholarshipSchedule));
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  });

  const timerComponents = Object.keys(timeLeft).map((interval, index) => {
    if (!timeLeft[interval]) {
      <span key={index}>00</span>;
    }

    return (
      <span key={index}>
        {timeLeft[interval].toString().padStart(2, "0")}
        {index + 1 < Object.keys(timeLeft).length && <>:</>}
      </span>
    );
  });

  return (
    <>
      <div className="px-4 pt-2">
        <div className="border rounded">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar
              style={{
                minHeight: "0px",
                borderBottom: "3px solid #e9ecef",
              }}
              className=" my-0 py-1 bg-light"
            >
              <Typography
                variant="p"
                noWrap
                component="div"
                className="d-flex  align-items-center text-uppercase text-primary font-weight-bold"
              >
                <span className="text-dark">({totalRecods}) Applications</span>

                <CSVLink
                  data={downloadbleData}
                  filename={`Scholarship requests - ${
                    user?.selectedEntity?.name || user?.selectedEntity?.name
                  }.csv`}
                  className="hidden"
                  id="downloadBTN"
                  target="_blank"
                />
                {!["TEACHER", "HEAD_TEACHER"].includes(
                  user.selectedEntity.role
                ) && (
                  <Tooltip title="Download">
                    <span className="ml-2">
                      <IconButton
                        size="small"
                        disabled={loading || !scholarshipRequests.length}
                        className=" mr-1"
                        color="primary"
                        onClick={() => getDownloadbleData()}
                      >
                        <span className="material-icons">file_download</span>
                      </IconButton>
                    </span>
                  </Tooltip>
                )}

                {["TEACHER"].includes(user?.selectedEntity.role) && (
                  <Button
                    className="ml-2"
                    disabled={
                      loading ||
                      !(
                        !!scholarshipSchedule &&
                        moment(scholarshipSchedule.startOn).format(
                          "yyyy-MM-DD"
                        ) <= moment().format("yyyy-MM-DD") &&
                        moment(scholarshipSchedule.endOn).format(
                          "yyyy-MM-DD"
                        ) >= moment().format("yyyy-MM-DD")
                      )
                    }
                    onClick={() => {
                      setShowFormDialog(true);
                    }}
                    variant="contained"
                    size="small"
                    disableElevation
                    color="primary"
                    style={{
                      borderRadius: "8px",
                      height: "32px",
                      alignItems: "center",
                      fontSize: ".75rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textTransform: "initial",
                    }}
                  >
                    <span className="material-icons">add</span>
                    Apply for scholarship
                  </Button>
                )}
              </Typography>

              {["REB", "RTB", "DG_REB", "HOD_TDM", "DG_RTB", "HOD_TM"].includes(
                user.selectedEntity.role
              ) && (
                <Button
                  className="ml-2"
                  onClick={() => setConfirmExecute(true)}
                  disabled={loading || !scholarshipRequests.length}
                  variant="contained"
                  size="small"
                  disableElevation
                  color="inherit"
                  style={{
                    borderRadius: "8px",
                    height: "30px",
                    alignItems: "center",
                    fontSize: ".75rem",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform: "initial",
                  }}
                >
                  <span className="material-icons">terminal</span>
                  Execute
                </Button>
              )}

              <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                {!!scholarshipSchedule &&
                  moment(scholarshipSchedule.startOn).format("yyyy-MM-DD") <=
                    moment().format("yyyy-MM-DD") &&
                  moment(scholarshipSchedule.endOn).format("yyyy-MM-DD") >=
                    moment().format("yyyy-MM-DD") && (
                    <span
                      className="font-weight-bold ml-2"
                      style={{ color: "#ed6c02" }}
                    >
                      {timerComponents.length ? (
                        timerComponents
                      ) : (
                        <span
                          className={`badge badge-danger ml-2`}
                          style={{ cursor: "default" }}
                        >
                          <i className="fas fa-lock mr-1"></i> CLOSED
                        </span>
                      )}
                    </span>
                  )}

                {!(
                  !!scholarshipSchedule &&
                  moment(scholarshipSchedule.startOn).format("yyyy-MM-DD") <=
                    moment().format("yyyy-MM-DD") &&
                  moment(scholarshipSchedule.endOn).format("yyyy-MM-DD") >=
                    moment().format("yyyy-MM-DD")
                ) && (
                  <span
                    className={`badge badge-danger ml-2`}
                    style={{ cursor: "default" }}
                  >
                    <span>
                      {!scholarshipSchedule ||
                      (!!scholarshipSchedule &&
                        moment(scholarshipSchedule.endOn).format("yyyy-MM-DD") <
                          moment().format("yyyy-MM-DD")) ? (
                        <i className="fas fa-lock mr-1"></i>
                      ) : (
                        <i className="fas fa-clock mr-1"></i>
                      )}

                      {!scholarshipSchedule ||
                      (!!scholarshipSchedule &&
                        moment(scholarshipSchedule.endOn).format("yyyy-MM-DD") <
                          moment().format("yyyy-MM-DD"))
                        ? "CLOSED"
                        : `Will start on ${moment(
                            scholarshipSchedule.startOn
                          ).format("ll")}`}
                    </span>
                  </span>
                )}
              </Box>

              <Box sx={{ flexGrow: 1 }} />

              {["REB", "DG_REB", "HOD_TDM", "RTB", "DG_RTB", "HOD_TM"].includes(
                user.selectedEntity.role
              ) && (
                <>
                  {!!scholarshipSchedule?.published && (
                    <span className="d-inline-flex align-items-center mr-2 text-success badge badge-pill">
                      <span className="material-icons ">task_alt</span>
                      Published
                    </span>
                  )}

                  <Button
                    className="mr-2"
                    disabled={
                      loading ||
                      !!showScholarshipSummary ||
                      !scholarshipRequests.length
                    }
                    onClick={() => {
                      setShowScholarshipSummary(true);
                    }}
                    variant="contained"
                    disableElevation
                    color="primary"
                  >
                    <span className="material-icons">summarize</span>
                    Summary
                  </Button>
                </>
              )}
              <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                <SearchBox
                  onSearch={onSearch}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      const query = {
                        searchTerm: searchTerm,
                        page: currentPage,
                        size: itemPerPage,
                      };

                      getScholarshipRequests(query, setTotalRecods, setLoader);
                    }
                  }}
                  placeholder="Search…"
                />
              </Box>
            </Toolbar>
          </AppBar>
          <div
            style={{
              backgroundColor: "#f3f4f6",
              borderRadius: "0.5rem",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
              height: "59vh",
              overflowY: "auto",
            }}
            className="px-3"
          >
            {!!scholarshipRequests.length && (
              <table className="table table-striped table-hover   fixTableHead">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col">#</th>
                    <th scope="col" className="text-left">
                      Applicant
                    </th>

                    <th scope="col" className="text-left">
                      Gender
                    </th>

                    <th scope="col" className="text-left">
                      SchoolAttended
                    </th>

                    {/* <th scope="col" className="text-left">
                      Combination
                    </th> */}

                    <th scope="col" className="text-left">
                      GraduationDate
                    </th>

                    <th scope="col" className="text-left">
                      Department of study
                    </th>

                    <th scope="col" className="text-left">
                      RequestedOn
                    </th>

                    {/* <th scope="col" className="text-center" >
                      Transcript Results
                    </th> */}

                    <th scope="col" className="text-right">
                      Status
                    </th>

                    <th scope="col" className="text-right"></th>
                  </tr>
                </thead>

                <tbody>
                  {scholarshipRequests.map((request) => (
                    <ItemCard
                      key={request.no}
                      request={request}
                      user={user}
                      loading={loading}
                      setShowFormDialog={setShowFormDialog}
                      deleteScholarshipRequest={deleteScholarshipRequest}
                      approveOrRejectScholarshipRequest={
                        approveOrRejectScholarshipRequest
                      }
                      env={env}
                    />
                  ))}
                </tbody>
              </table>
            )}

            {!scholarshipRequests?.length && (
              <div className="jumbotron jumbotron-fluid text-center">
                <div className="container py-5 ">
                  <p className="lead">
                    {loading ? "Fetching data..." : "No request made"}{" "}
                  </p>
                </div>
              </div>
            )}
          </div>
          {!!scholarshipRequests.length && (
            <div
              className="d-flex justify-content-center  align-items-center"
              style={{ overflow: "hidden" }}
            >
              <>
                <div className="mt-3">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={
                      <>
                        <span className="pr-1 d-none d-md-inline">Next</span>
                        <i className="fas fa-angle-double-right"></i>
                      </>
                    }
                    previousLabel={
                      <>
                        <i className="fas fa-angle-double-left"></i>
                        <span className="pl-1  d-none d-md-inline">
                          Previous
                        </span>
                      </>
                    }
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageClick}
                    pageCount={Math.ceil(totalRecods / itemPerPage)}
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
                    previousLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
                    nextLinkClassName="btn btn-outline-dark btn-sm ml-1"
                    activeLinkClassName="active"
                  />
                </div>

                <select
                  className="custom-select custom-select-sm mr-3 ml-2"
                  style={{ width: "100px" }}
                  value={itemPerPage}
                  onChange={(event) => {
                    const itemPerPage = +event.target.value;
                    window.localStorage.setItem("ITEM_PER_PAGE", itemPerPage);
                    setItemPerPage(itemPerPage);
                    const query = {
                      searchTerm: searchTerm,
                      page: currentPage,
                      size: itemPerPage,
                    };
                    getScholarshipRequests(query, setTotalRecods, setLoader);
                  }}
                >
                  {[10, 50, 100, 150].map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
              </>
            </div>
          )}
        </div>
      </div>

      {loader && (
        <CircularProgress
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            color: "rgb(7, 142, 206)",
            position: "absolute",
            top: "42%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
          size={40}
          thickness={4}
        />
      )}

      {showFormDialog && (
        <AddOrUpdateScholarshipRequestDialog
          showDialog={showFormDialog}
          setShowDialog={setShowFormDialog}
        />
      )}

      {showScholarshipSummary && (
        <ScholarshipSummaryDialog
          showDialog={showScholarshipSummary}
          setShowDialog={setShowScholarshipSummary}
          executed={scholarshipRequests.find(
            ({ statusId }) => !![3, 5].includes(statusId)
          )}
        />
      )}

      {confirmExecute && (
        <ConfirmationDialog
          confirmationDialog={confirmExecute}
          message="Are you sure you want to excute scholarship algorithm?"
          setConfirmationDialog={setConfirmExecute}
          onYes={() => {
            executeScholarshipAlgorithm(setConfirmExecute);
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  env,
  user,
  loading,
  scholarshipCombinations,
  scholarshipCourses,
  scholarshipDocuments,
  scholarshipRequests,
  districts,
  scholarshipSchedule,
}) => {
  return {
    env,
    user,
    loading,
    scholarshipCombinations,
    scholarshipCourses,
    scholarshipDocuments,
    scholarshipRequests,
    districts,
    scholarshipSchedule,
  };
};

export default connect(mapStateToProps, {
  getScholarshipCombinations,
  getScholarshipCourses,
  getScholarshipDocuments,
  getScholarshipRequests,
  deleteScholarshipRequest,
  approveOrRejectScholarshipRequest,
  getDistricts,
  getEligibility,
  executeScholarshipAlgorithm,
  getScholarshipSchedule,
})(PostsDistribution);

const ItemCard = (props) => {
  const {
    loading,
    user,
    request,
    setShowFormDialog,
    approveOrRejectScholarshipRequest,
    deleteScholarshipRequest,
    env,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showPreviewProfileDialog, setShowPreviewProfileDialog] =
    useState(false);

  const [statusId, setStatusId] = useState(null);
  const [statusComment, setStatusComment] = useState("");
  const [showStatusComment, setShowStatusComment] = useState(false);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showCriteriaDialog, setShowCriteriaDialog] = useState(false);

  const [
    showPreviewScholarshipRequestDialog,
    setShowPreviewScholarshipRequestDialog,
  ] = useState(false);

  return (
    <>
      <tr>
        <th scope="row" className="text-left">
          {request.no}
        </th>

        <td className="text-left">
          <button
            onClick={() => setShowPreviewProfileDialog(true)}
            className="btn btn-link btn-sm px-0 py-0 text-uppercase text-left text-underline font-weight-bold"
          >
            {request.lastName} {request.firstName}{" "}
            {request.requestedBy === user.id && (
              <span className="badge badge-primary">Me</span>
            )}
          </button>
          <div>
            {request.positionName} ({request.qualificationLevelName}) <br />
            <span className="text-primary font-weight-bold">
              @{request.schoolName} <i className="fas fa-chevron-right"></i>{" "}
              {request.sectorName} / {request.districtName}
            </span>
          </div>
        </td>

        <td className="text-left">{request.gender}</td>
        <td className="text-left">
          <div>
            <span className="text-primary font-weight-bold text-uppercase">
              {request.attendedSchoolName}
            </span>{" "}
            <br />
            {request.combinationName}
          </div>
        </td>
        {/* <td className="text-left">{request.combinationName}</td> */}
        <td className="text-left">
          {moment(request.graduationDate).format("MMMM YYYY")}
        </td>

        <td className="text-left">{request.scholarshipCourseName}</td>

        <td className="text-left">{moment(request.createdOn).format("lll")}</td>

        {/* <td className="text-center">{request.mark}</td> */}

        <td className="text-right">
          <span className="d-flex align-items-center justify-content-end">
            <span
              className={`text-uppercase badge badge-${
                [2, 3].includes(request.statusId)
                  ? "success"
                  : [4, 5].includes(request.statusId)
                  ? "danger"
                  : [7].includes(request.statusId)
                  ? "warning"
                  : [1].includes(request.statusId)
                  ? "secondary"
                  : "primary"
              } `}
            >
              {request.statusName}
            </span>

            <IconButton
              disabled
              size="small"
              className="ml-1"
              color="primary"
              onClick={() => setShowCriteriaDialog(true)}
            >
              <span className="material-icons">info</span>
            </IconButton>
          </span>
        </td>

        <td className="text-right">
          {/* <Tooltip title="More"> */}
          <IconButton onClick={handleOpenMenu}>
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          {/* </Tooltip> */}

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setShowPreviewScholarshipRequestDialog(true);
                }}
                className="text-primary font-weight-bolder"
              >
                <span className="material-icons mr-1">open_in_new</span>
                Preview
              </MenuItem>
              <Divider className="my-1" />
            </span>

            {((user.id === request.teacherId && request.statusId === 1) ||
              ["REB", "RTB"].includes(user.selectedEntity.role)) && (
              <span>
                <MenuItem
                  onClick={(e) => {
                    handleCloseMenu();
                    setShowConfirmDelete(true);
                  }}
                  className="text-danger"
                >
                  <span className="material-icons mr-1">delete</span>
                  Remove
                </MenuItem>
              </span>
            )}
          </Menu>
        </td>
      </tr>

      {showStatusComment && (
        <AlertDialog
          maxWidth="xs"
          showAlert={showStatusComment}
          setShowAlert={setShowStatusComment}
        >
          <TextField
            InputProps={{
              readOnly: true,
            }}
            className="mt-3 bg-light"
            multiline
            rows={5}
            autoFocus
            fullWidth
            type="text"
            size="small"
            name="reason"
            label="Reason for rejection"
            variant="outlined"
            placeholder="Reason for rejection"
            value={request.statusComment}
          />
          <span className="badge badge-pill text-right d-block">
            By:{request.statusBy}
          </span>
        </AlertDialog>
      )}

      {!!showConfirmDelete && (
        <ConfirmationDialog
          confirmationDialog={showConfirmDelete}
          message={
            <>
              <p className="mt-2">
                Are you sure you want to{" "}
                <strong className={`text-danger`}>delete</strong> this
                scholarship request?
              </p>
            </>
          }
          setConfirmationDialog={setShowConfirmDelete}
          onYes={() => {
            deleteScholarshipRequest(request.id, () =>
              setShowConfirmDelete(false)
            );
          }}
        />
      )}

      {showPreviewScholarshipRequestDialog && (
        <PreviewScholarshipRequestDialog
          showDialog={showPreviewScholarshipRequestDialog}
          setShowDialog={setShowPreviewScholarshipRequestDialog}
          request={request}
        />
      )}

      {showPreviewProfileDialog && (
        <PreviewTeacherProfile
          showDialog={showPreviewProfileDialog}
          setShowDialog={setShowPreviewProfileDialog}
          teacherId={request.teacherId}
        />
      )}

      {showCriteriaDialog && (
        <ScholarshipCriteriaDialog
          showDialog={showCriteriaDialog}
          setShowDialog={setShowCriteriaDialog}
        />
      )}
    </>
  );
};
