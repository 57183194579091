import React from "react";
import { Card, CardHeader, CardContent } from "@mui/material";

const ChartCard = ({ title, subheader, children }) => {
  return (
    <Card className="m-2">
      <CardHeader
        title={title}
        subheader={subheader}
        titleTypographyProps={{ variant: "h6", pl: 1 }}
        subheaderTypographyProps={{ variant: "body2", pl: 1 }}
      />
      <CardContent className="overflow-auto">{children}</CardContent>
    </Card>
  );
};

export default ChartCard;
