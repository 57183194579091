import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import {
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import { Toolbar } from "@mui/material";


import { Tab, Tabs } from "@mui/material";
import { TabPanel, a11yProps } from "./../common/components/TabPanel";
import PlacementOversight from "./components/placement/PlacementOversight";
import VacanciesOversight from "./components/vacancies/VacanciesOversight";
import TransferOversight from "./components/transfer/TransferOversight";
import MaternityOversight from "./components/maternity/MaternityOversight";

const Oversight = (props) => {
  useEffect(() => {
    document.title = "TMIS | System Users";
  }, []);

  const { user, loading, systemSettingPositions } = props;

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="px-4 pt-2">
      <div className="border rounded">
        <AppBar position="static" elevation={0} className="app-bar w-100">
          <Toolbar
            style={{
              minHeight: "0px",
              borderBottom: "3px solid #e9ecef",
            }}
            className=" my-0 py-1 bg-light"
          >
            <Typography
              variant="p"
              noWrap
              component="div"
              className="d-flex  align-items-center text-uppercase text-primary font-weight-bold"
            >
              <span className="text-dark">Oversight</span>
              <span className="material-icons" style={{ color: "#cecece" }}>
                chevron_right
              </span>

              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                value={value}
                onChange={handleChange}
              >
                <Tab label="Placements" {...a11yProps(0)} />
                <Tab label="Vacancies" {...a11yProps(1)} />
                <Tab label="Transfers" {...a11yProps(2)} />
                <Tab label="Maternity" {...a11yProps(3)} />
                {/* <Tab label="Payroll" {...a11yProps(4)} /> */}
              </Tabs>
            </Typography>
          </Toolbar>
        </AppBar>

        <TabPanel value={value} index={0}>
          <PlacementOversight />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <VacanciesOversight />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TransferOversight />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <MaternityOversight />
        </TabPanel>
        {/* <TabPanel value={value} index={4}>
          <PayrollOversight />
        </TabPanel> */}
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, loading, systemSettingPositions }) => {
  return { user, loading, systemSettingPositions };
};

export default connect(mapStateToProps, {})(Oversight);
