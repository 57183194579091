import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import SearchBox from "../../common/components/SearchBox";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Badge,
  Divider,
  TextField,
  Tooltip,
} from "@mui/material";
import { connect } from "react-redux";
import { CardContent, Toolbar } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  getScholarshipCombinations,
  getScholarshipCourses,
  getScholarshipSchedule,
} from "../../../../store/scholarship/actions";

import { getQualificationLevels } from "../../../../store/posts/actions";
import ReactPaginate from "react-paginate";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import AddOrUpdatePositionDropdownDialog from "../../security/components/AddOrUpdatePositionDropdownDialog";
import ScholarshipSpecializations from "./ScholarshipSpecializations";
import ScholarshipDepartments from "./ScholarshipDepartments";
import ScholarshipScheduleDialog from "./ScholarshipScheduleDialog";
import moment from "moment";

function calculateTimeLeft(scholarshipSchedule) {
  const year = new Date().getFullYear();
  const deadLine = !!scholarshipSchedule
    ? new Date(moment(scholarshipSchedule.endOn).format("yyyy-MM-DD"))
    : new Date(moment().format("yyyy-MM-DD"));

  deadLine.setDate(deadLine.getDate() + 1);
  deadLine.setHours(deadLine.getHours() - 1);

  const toDay = new Date();

  const difference = +deadLine - +toDay;

  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
}

const ScholarshipSettings = (props) => {
  useEffect(() => {
    document.title = "TMIS | System Users";
  }, []);

  const {
    user,
    loading,
    getScholarshipCombinations,
    getScholarshipCourses,
    getQualificationLevels,
    scholarshipSchedule,
    getScholarshipSchedule,
  } = props;

  useEffect(() => {
    getScholarshipCombinations();
    getScholarshipCourses();
    getQualificationLevels();
    getScholarshipSchedule();
  }, []);

  const [showScheduleDialog, setShowScheduleDialog] = useState(false);

  const [timeLeft, setTimeLeft] = useState(
    calculateTimeLeft(scholarshipSchedule)
  );

  useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(scholarshipSchedule));
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  });

  const timerComponents = Object.keys(timeLeft).map((interval, index) => {
    if (!timeLeft[interval]) {
      <span key={index}>00</span>;
    }

    return (
      <span key={index}>
        {timeLeft[interval].toString().padStart(2, "0")}
        {index + 1 < Object.keys(timeLeft).length && <>:</>}
      </span>
    );
  });

  return (
    <div>
      <div
        style={{
          backgroundColor: "#f3f4f6",
          borderRadius: "0.5rem",
          boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
          MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
          height: "65vh",
          overflowY: "auto",
        }}
        className="px-3"
      >
        <AppBar position="static" elevation={0}>
          <Toolbar
            style={{
              minHeight: "0px",
              // borderBottom: "3px solid #e9ecef",
            }}
            className=" my-0 py-1 bg-white mx-n3"
          >
            <span className="text-uppercase text-primary">Scholarships</span>

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "flex", md: "flex" } }}>
              {!!scholarshipSchedule &&
                moment(scholarshipSchedule.startOn).format("yyyy-MM-DD") <=
                  moment().format("yyyy-MM-DD") &&
                moment(scholarshipSchedule.endOn).format("yyyy-MM-DD") >=
                  moment().format("yyyy-MM-DD") && (
                  <span
                    className="font-weight-bold ml-2"
                    style={{ color: "#ed6c02" }}
                  >
                    {timerComponents.length ? (
                      timerComponents
                    ) : (
                      <span
                        className={`badge badge-danger ml-2`}
                        style={{ cursor: "default" }}
                      >
                        <i className="fas fa-lock mr-1"></i> CLOSED
                      </span>
                    )}
                  </span>
                )}

              {!(
                !!scholarshipSchedule &&
                moment(scholarshipSchedule.startOn).format("yyyy-MM-DD") <=
                  moment().format("yyyy-MM-DD") &&
                moment(scholarshipSchedule.endOn).format("yyyy-MM-DD") >=
                  moment().format("yyyy-MM-DD")
              ) && (
                <span
                  className={`badge badge-danger ml-2`}
                  style={{ cursor: "default" }}
                >
                  <span>
                    {!scholarshipSchedule ||
                    (!!scholarshipSchedule &&
                      moment(scholarshipSchedule.endOn).format("yyyy-MM-DD") <
                        moment().format("yyyy-MM-DD")) ? (
                      <i className="fas fa-lock mr-1"></i>
                    ) : (
                      <i className="fas fa-clock mr-1"></i>
                    )}

                    {!scholarshipSchedule ||
                    (!!scholarshipSchedule &&
                      moment(scholarshipSchedule.endOn).format("yyyy-MM-DD") <
                        moment().format("yyyy-MM-DD"))
                      ? "CLOSED"
                      : `Will start on ${moment(
                          scholarshipSchedule.startOn
                        ).format("ll")}`}
                  </span>
                </span>
              )}
            </Box>
            <Button
              className="ml-1"
              disabled={loading}
              onClick={() => {
                setShowScheduleDialog(true);
              }}
              variant="contained"
              size="small"
              disableElevation
              color="primary"
            >
              Schedule
            </Button>
          </Toolbar>
        </AppBar>
        <div className="row mt-1">
          <div className="col-12 col-lg-6">
            <ScholarshipDepartments />
          </div>
          <div className="col-12 col-lg-6">
            <ScholarshipSpecializations />
          </div>
        </div>
        {showScheduleDialog && (
          <ScholarshipScheduleDialog
            scholarshipSchedule={scholarshipSchedule}
            showDialog={showScheduleDialog}
            setShowDialog={setShowScheduleDialog}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, loading, scholarshipSchedule }) => {
  return { user, loading, scholarshipSchedule };
};

export default connect(mapStateToProps, {
  getScholarshipCombinations,
  getScholarshipCourses,
  getQualificationLevels,
  getScholarshipSchedule,
})(ScholarshipSettings);
