const types = {
  SET_SCHOLARSHIP_COMBINATIONS: "SET_SCHOLARSHIP_COMBINATIONS",
  ADD_OR_UPDATE_SCHOLARSHIP_COMBINATION:
    "ADD_OR_UPDATE_SCHOLARSHIP_COMBINATION",
  SET_SCHOLARSHIP_COURSES: "SET_SCHOLARSHIP_COURSES",
  ADD_OR_UPDATE_SCHOLARSHIP_COURSE: "ADD_OR_UPDATE_SCHOLARSHIP_COURSE",
  SET_SCHOLARSHIP_REQUESTS: "SET_SCHOLARSHIP_REQUESTS",

  ADD_OR_UPDATE_SCHOLARSHIP_REQUEST: "ADD_OR_UPDATE_SCHOLARSHIP_REQUEST",
  DELETE_SCHOLARSHIP_REQUEST: "DELETE_SCHOLARSHIP_REQUEST",
  SET_SCHOLARSHIP_DOCUMENTS: "SET_SCHOLARSHIP_DOCUMENTS",
  SET_SCHOLARSHIP_REQUEST_ATTACHMENTS: "SET_SCHOLARSHIP_REQUEST_ATTACHMENTS",
  SET_SCHOLARSHIP_REQUEST_LOGS: "SET_SCHOLARSHIP_REQUEST_LOGS",
  SET_ELIGIBILITY: "SET_ELIGIBILITY",
  SET_SCHOLARSHIP_SUMMARY: "SET_SCHOLARSHIP_SUMMARY",
  SET_SCHOLARSHIP_SCHEDULE: "SET_SCHOLARSHIP_SCHEDULE",
};

export default types;
