import React, { useState, useEffect } from "react";

import {
  Switch,
  Route,
  useHistory,
  useLocation,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";

import Header from "./components/Header";

import Home from "./pages/Home";
import ResetPassword from "./pages/PasswordReset";
import Unauthorized from "./pages/Unauthorized";
import Login from "./pages/Login";
import SupportStaff from "./pages/SupportStaff";
import PasswordReset from "./pages/PasswordReset";

function Layout() {
  const location = useLocation();
  return (
    <>
      <div>
        {location.pathname !== "/" && <Header />}

        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/support-staff" component={SupportStaff} />
          <Route path="/password-reset" component={PasswordReset} />
          <Route path="/unauthorized" component={Unauthorized} />
          <Route path="/" component={Home} />
        </Switch>
      </div>
    </>
  );
}

export default Layout;
