"use client";

import { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { showError } from "../../toastify";

import {
  login,
  // resetPassword,
} from "../../../store/common/actions";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";

// Material UI Icons
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import SmartphoneIcon from "@mui/icons-material/Smartphone";

const Login = (props) => {
  const {
    loading,
    login,
    // resetPassword,
    showLoginDialog,
    setShowLoginDialog,
  } = props;

  const history = useHistory();

  const [forgotPassword, setForgotPassword] = useState(false);

  const [errors, setErrors] = useState({
    emailHasError: false,
    passwordHasError: false,
    hasError: false,
  });

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const formValidator = () => {
    const error = {
      emailHasError: false,
      passwordHasError: false,
      hasError: false,
    };

    if (!credentials.email) {
      error.emailHasError = true;
      error.hasError = true;
    }

    if (!credentials.password) {
      error.passwordHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const onLogin = async (e) => {
    e.preventDefault();
    if (!formValidator())
      login(
        { email: credentials.email, password: credentials.password },
        history
      );
  };

  const [onWait, setOnWait] = useState(false);
  const [wasReset, setWasReset] = useState(false);

  const resetPassword = async () => {
    try {
      setOnWait(true);
      setWasReset(false);
      await axios.post("/api/auth/reset-password", {
        phone: credentials.email,
      });
      setOnWait(false);
      setWasReset(true);
    } catch (error) {
      setOnWait(false);
      setWasReset(false);
      showError(error);
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      onClose={() => {
        setShowLoginDialog(false);
      }}
      open={showLoginDialog}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          boxShadow: "0 10px 40px rgba(0,0,0,0.1)",
          overflow: "hidden",
        },
      }}
    >
      <DialogTitle
        sx={{
          background: "white",
          padding: "24px",
          position: "relative",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{
            fontWeight: 700,
            background: "linear-gradient(to right, #0d47a1, #0d6efd, #fd7e14)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            marginBottom: 0,
          }}
        >
          {!forgotPassword ? "TMIS Login" : "Reset Password"}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => {
            setShowLoginDialog(false);
          }}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
            color: "rgba(0,0,0,0.5)",
            "&:hover": {
              color: "rgba(0,0,0,0.7)",
              background: "rgba(0,0,0,0.04)",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ padding: "24px", minHeight: "320px" }}>
        {!forgotPassword && (
          <Box component="form" onSubmit={onLogin} sx={{ mt: 1 }}>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{ mb: 3, mt: 1 }}
              error={errors.emailHasError}
            >
              <InputLabel htmlFor="emailOrPhone">
                Enter your phone/email
              </InputLabel>
              <OutlinedInput
                autoFocus
                id="emailOrPhone"
                name="emailOrPhone"
                type="text"
                value={credentials.email}
                onChange={(e) => {
                  setCredentials({ ...credentials, email: e.target.value });
                  setErrors({ ...errors, emailHasError: false });
                }}
                label="Enter your phone/email"
                placeholder="Enter your phone/email"
                sx={{
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: errors.emailHasError
                      ? "error.main"
                      : "rgba(0, 0, 0, 0.1)",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: errors.emailHasError
                      ? "error.main"
                      : "primary.main",
                  },
                }}
              />
              {errors.emailHasError && (
                <Typography
                  variant="caption"
                  color="error"
                  sx={{ mt: 0.5, ml: 1 }}
                >
                  Phone/email is required
                </Typography>
              )}
            </FormControl>

            <FormControl
              fullWidth
              variant="outlined"
              sx={{ mb: 1 }}
              error={errors.passwordHasError}
            >
              <InputLabel htmlFor="password">Enter your password</InputLabel>
              <OutlinedInput
                id="password"
                placeholder="Enter your password"
                name="password"
                type={credentials.showPassword ? "text" : "password"}
                value={credentials.password}
                onChange={(e) => {
                  setCredentials({
                    ...credentials,
                    password: e.target.value,
                  });
                  setErrors({ ...errors, passwordHasError: false });
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setCredentials({
                          ...credentials,
                          showPassword: !credentials.showPassword,
                        })
                      }
                      edge="end"
                      sx={{ color: "rgba(0,0,0,0.5)" }}
                    >
                      {credentials.showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Enter your password"
                sx={{
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: errors.passwordHasError
                      ? "error.main"
                      : "rgba(0, 0, 0, 0.1)",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: errors.passwordHasError
                      ? "error.main"
                      : "primary.main",
                  },
                }}
              />
              {errors.passwordHasError && (
                <Typography
                  variant="caption"
                  color="error"
                  sx={{ mt: 0.5, ml: 1 }}
                >
                  Password is required
                </Typography>
              )}
            </FormControl>

            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
              <Button
                variant="text"
                onClick={() => setForgotPassword(true)}
                sx={{
                  textTransform: "none",
                  color: "primary.main",
                  "&:hover": {
                    background: "transparent",
                    color: "#fd7e14",
                  },
                }}
              >
                Reset password?
              </Button>
            </Box>

            <Button
              fullWidth
              type="submit"
              disabled={loading}
              variant="contained"
              size="large"
              sx={{
                background: "linear-gradient(to right, #e76f51, #0d6efd)",
                color: "white",
                padding: "12px",
                borderRadius: "8px",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "16px",
                boxShadow: "0 4px 12px rgba(13, 110, 253, 0.2)",
                "&:hover": {
                  boxShadow: "0 6px 16px rgba(13, 110, 253, 0.3)",
                  background: "linear-gradient(to right, #e76f51, #0a58ca)",
                },
              }}
            >
              {loading ? (
                <Box
                  sx={{ display: "flex", alignItems: "center", color: "white" }}
                >
                  <CircularProgress
                    size={20}
                    color="inherit"
                    sx={{ mr: 1, color: "white" }}
                  />
                  Connecting...
                </Box>
              ) : (
                "Login"
              )}
            </Button>
          </Box>
        )}

        {forgotPassword && (
          <Box sx={{ mt: 1, textAlign: "center" }}>
            {wasReset && (
              <Alert
                icon={<SmartphoneIcon />}
                severity="success"
                sx={{
                  mb: 3,
                  borderRadius: "8px",
                  "& .MuiAlert-message": {
                    width: "100%",
                  },
                }}
              >
                <AlertTitle sx={{ fontWeight: 600, textAlign: "center" }}>
                  Check your phone or email
                </AlertTitle>
                <Typography variant="body2">
                  The new password was sent to your phone and email.
                </Typography>
              </Alert>
            )}

            {!wasReset && (
              <>
                <TextField
                  fullWidth
                  autoFocus
                  name="phone"
                  type="number"
                  variant="outlined"
                  label="Enter your phone number"
                  placeholder="07XXXXXXXX"
                  sx={{
                    mt: 1,
                    mb: 3,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 0.1)",
                      },
                      "&:hover fieldset": {
                        borderColor: "primary.main",
                      },
                    },
                  }}
                  value={credentials.email}
                  onChange={(e) => {
                    setCredentials({
                      ...credentials,
                      email: e.target.value || "",
                    });
                  }}
                />

                <Button
                  fullWidth
                  disabled={
                    !credentials.email ||
                    credentials.email.length !== 10 ||
                    onWait
                  }
                  type="button"
                  variant="contained"
                  size="large"
                  onClick={() => resetPassword()}
                  sx={{
                    background: "linear-gradient(to right, #e76f51, #0d6efd)",
                    color: "white",
                    padding: "12px",
                    borderRadius: "8px",
                    textTransform: "none",
                    fontWeight: 600,
                    fontSize: "16px",
                    boxShadow: "0 4px 12px rgba(13, 110, 253, 0.2)",
                    "&:hover": {
                      boxShadow: "0 6px 16px rgba(13, 110, 253, 0.3)",
                      background: "linear-gradient(to right, #e76f51, #0a58ca)",
                    },
                    "&.Mui-disabled": {
                      background: "rgba(0, 0, 0, 0.12)",
                      color: "rgba(0, 0, 0, 0.26)",
                    },
                  }}
                >
                  {onWait ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                      }}
                    >
                      <CircularProgress
                        size={20}
                        color="inherit"
                        sx={{ mr: 1, color: "white" }}
                      />
                      Connecting...
                    </Box>
                  ) : (
                    "Get New Password"
                  )}
                </Button>
              </>
            )}

            <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
              <Button
                variant="text"
                startIcon={<KeyboardDoubleArrowLeftIcon />}
                onClick={() => {
                  setForgotPassword(false);
                  setOnWait(false);
                  setWasReset(false);
                }}
                sx={{
                  textTransform: "none",
                  color: "primary.main",
                  "&:hover": {
                    background: "transparent",
                    color: "#fd7e14",
                  },
                }}
              >
                Back to Login
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return { user, loading };
};
export default connect(mapStateToProps, {
  login,
})(Login);
