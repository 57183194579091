import {
  IconButton,
  Dialog,
  DialogTitle,
  AppBar,
  Toolbar,
  Typography,
  CircularProgress,
  DialogContent,
  DialogActions,
  Tooltip,
  Button,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Autocomplete,
  Box,
  InputLabel,
  OutlinedInput,
  Menu,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getScholarshipRequestAttachments,
  getScholarshipRequestLogs,
  approveOrRejectScholarshipRequest,
} from "../../../../store/scholarship/actions";
import { showError } from "../../../toastify";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import axios from "axios";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

// const steps = ["Eligibility to apply", "Submit application"];

const PreviewScholarshipRequestDialog = (props) => {
  const {
    user,
    loading,
    showDialog,
    setShowDialog,
    districts,

    scholarshipCombinations,
    scholarshipCourses,
    scholarshipDocuments,

    request,

    getScholarshipRequestAttachments,
    getScholarshipRequestLogs,
    scholarshipRequestAttachments,
    scholarshipRequestLogs,

    approveOrRejectScholarshipRequest,
  } = props;

  const onClose = () => {
    setShowDialog(false);
  };

  const onSave = () => {};

  const [showUploadedDocument, setShowUploadedDocument] = useState(false);
  const [uploadedDocument, setUploadedDocument] = useState(null);

  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedCombination, setSelectedCombination] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);

  useEffect(() => {
    setSelectedCourse({
      id: request.scholarshipCourseId,
      name: request.scholarshipCourseName,
    });

    setSelectedDistrict({
      id: request.attendedSchoolDistrictId,
      name: request.attendedSchoolDistrictName,
    });

    setSelectedSchool({
      id: request.attendedSchoolId,
      name: request.attendedSchoolName,
    });

    setSelectedCombination({
      id: request.combinationId,
      name: request.combinationName,
    });

    getScholarshipRequestAttachments(request.id);
    getScholarshipRequestLogs(request.id);
  }, [request]);

  const [statusId, setStatusId] = useState(null);
  const [statusComment, setStatusComment] = useState("");

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "90%" }}
          >
            <span className="text-primary font-weight-bold">
              Scholarship application
            </span>
            <span
              className={`ml-2 badge badge-${
                [2, 3].includes(request.statusId)
                  ? "success"
                  : [4, 5].includes(request.statusId)
                  ? "danger"
                  : [1].includes(request.statusId)
                  ? "secondary"
                  : "primary"
              } `}
            >
              {request.statusName}
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div style={{ minHeight: "55vh" }}>
            <div className="row">
              <div className="col-12 row no-gutters px-3">
                <fieldset
                  className={`col-12 row no-gutters px-2 form-group border border-primary bg-light`}
                >
                  <legend
                    className={`col-12 w-auto px-2 text-primary`}
                    style={{
                      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                      fontWeight: 400,
                      fontSize: "0.82rem",
                      lineHeight: "1em",
                      letterSpacing: "0.00938em",
                    }}
                  >
                    School or University attended
                  </legend>

                  <div className="col-4 mb-2 px-2">
                    <TextField
                      size="small"
                      fullWidth
                      name="schoolName"
                      value={request.attendedSchoolName || ""}
                      label="attended name of school"
                      placeholder="attended name of school"
                      variant="outlined"
                      InputProps={{ readOnly: true }}
                    />
                  </div>

                  <div className="col-4 mb-2 px-2">
                    <Autocomplete
                      readOnly
                      size="small"
                      value={selectedCombination}
                      options={[selectedCombination]}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          name="selectedCombination"
                          {...params}
                          label="Specialization"
                          placeholder="Specialization"
                        />
                      )}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {option.name}
                        </Box>
                      )}
                    />
                  </div>

                  <div className="col-4 mb-2 px-2">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        readOnly
                        label="Graduation date"
                        inputFormat="dd/MM/yyyy"
                        value={request.graduationDate}
                        onChange={(date) => {}}
                        renderInput={(params) => (
                          <TextField
                            name="scholarshipGraduationDate"
                            onKeyDown={(e) => e.preventDefault()}
                            size="small"
                            fullWidth
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </fieldset>
              </div>

              <div className="col-12 row no-gutters px-3 mt-2">
                <fieldset
                  className={`col-12 row no-gutters px-2 form-group border border-primary bg-light`}
                >
                  <legend
                    className={`col-12 w-auto px-2 text-primary`}
                    style={{
                      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                      fontWeight: 400,
                      fontSize: "0.82rem",
                      lineHeight: "1em",
                      letterSpacing: "0.00938em",
                    }}
                  >
                    Field/department of study
                  </legend>

                  <div className="col-12 mb-2">
                    <Autocomplete
                      readOnly
                      size="small"
                      value={selectedCourse}
                      options={[selectedCourse]}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          name="course"
                          {...params}
                          label=""
                          placeholder=""
                        />
                      )}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {option.name}
                        </Box>
                      )}
                    />
                  </div>
                </fieldset>
              </div>

              <div className="col-12 row no-gutters px-3">
                <fieldset
                  className={`col-12 row no-gutters px-2 bg-light form-group border border-primary`}
                >
                  <legend
                    className={`col-12 w-auto px-2 text-primary`}
                    style={{
                      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                      fontWeight: 400,
                      fontSize: "0.82rem",
                      lineHeight: "1em",
                      letterSpacing: "0.00938em",
                    }}
                  >
                    Required documents
                  </legend>

                  {scholarshipDocuments.map((document) => (
                    <DocumentItem
                      key={document.id}
                      document={document}
                      scholarshipRequestAttachments={
                        scholarshipRequestAttachments
                      }
                      setShowUploadedDocument={setShowUploadedDocument}
                      setUploadedDocument={setUploadedDocument}
                    />
                  ))}
                </fieldset>
              </div>

              {!["TEACHER"].includes(user.selectedEntity.role) &&
                scholarshipRequestLogs.map((log) => (
                  <div className="col-12 row no-gutters px-3 pb-0">
                    <fieldset
                      className={`col-12 row no-gutters px-2 bg-light form-group border border-primary`}
                    >
                      <legend
                        className={`col-12 w-auto px-2 text-primary`}
                        style={{
                          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                          fontWeight: 400,
                          fontSize: "0.82rem",
                          lineHeight: "1em",
                          letterSpacing: "0.00938em",
                        }}
                      >
                        {log?.user?.lastName} {log?.user?.firstName}
                        {" | "}
                        {moment(log.createdOn).format("lll")}
                        <span
                          className={`ml-2 badge badge-${
                            [2, 3].includes(log.statusId)
                              ? "success"
                              : [4, 5].includes(log.statusId)
                              ? "danger"
                              : "secondary"
                          } `}
                        >
                          {log?.status?.name}
                        </span>
                      </legend>

                      <div className="col-12 mb-2">
                        <TextField
                          InputProps={{ readOnly: true }}
                          fullWidth
                          multiline
                          rows={2}
                          name="message"
                          value={log.comment}
                          label=""
                          placeholder=""
                          variant="outlined"
                        />
                      </div>
                    </fieldset>
                  </div>
                ))}
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-3 pt-0">
          {["REB"].includes(user.selectedEntity.role) &&
            request.statusId === 1 && (
              <div className="col-12 mt-2">
                <div className="d-flex justify-content-center">
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => {
                      setStatusId(2);
                    }}
                    type="button"
                    className="px-4 mx-2"
                    disabled={loading}
                  >
                    Accept
                  </Button>

                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setStatusId(4);
                    }}
                    type="button"
                    className="px-4 mx-2"
                    disabled={loading}
                  >
                    Reject
                  </Button>
                </div>
              </div>
            )}
        </DialogActions>
      </Dialog>

      {showUploadedDocument && uploadedDocument && (
        <PreviewPdfUrl
          isBase64={true}
          showDocument={showUploadedDocument}
          setShowDocument={setShowUploadedDocument}
          document={{
            name: `${uploadedDocument?.name || ""}`,
            url: uploadedDocument?.url || "",
          }}
        />
      )}

      {!!statusId && (
        <ConfirmationDialog
          disabled={!statusComment}
          confirmationDialog={!!statusId}
          message={
            <>
              <p className="mt-2">
                Are you sure you want to{" "}
                <strong
                  className={`text-${statusId === 2 ? "success" : "danger"}`}
                >
                  {statusId === 2 ? "accept" : "reject"}
                </strong>{" "}
                this request?
              </p>
              <TextField
                multiline
                rows={3}
                autoFocus
                fullWidth
                type="text"
                size="small"
                name="reason"
                label="Reason for decision"
                variant="outlined"
                placeholder="Reason for decision"
                value={statusComment}
                onChange={(e) => {
                  setStatusComment(e.target.value || "");
                }}
              />
            </>
          }
          setConfirmationDialog={(e) => setStatusId(null)}
          onYes={() => {
            approveOrRejectScholarshipRequest(
              {
                requestId: request.id,
                statusId,
                statusComment,
              },
              (e) => setStatusId(null)
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  onWait,
  districts,
  scholarshipCombinations,
  scholarshipCourses,
  scholarshipDocuments,
  scholarshipRequestAttachments,
  scholarshipRequestLogs,
}) => {
  return {
    loading,
    onWait,
    user,
    districts,
    scholarshipCombinations,
    scholarshipCourses,
    scholarshipDocuments,
    scholarshipRequestAttachments,
    scholarshipRequestLogs,
  };
};
export default connect(mapStateToProps, {
  getScholarshipRequestAttachments,
  getScholarshipRequestLogs,
  approveOrRejectScholarshipRequest,
})(PreviewScholarshipRequestDialog);

const DocumentItem = (props) => {
  const {
    document,
    errors,
    formData,
    setFormData,
    setShowUploadedDocument,
    setUploadedDocument,
    handleUploadedPDFFile,
    scholarshipRequestAttachments,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);

    event.stopPropagation();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const attachment = scholarshipRequestAttachments.find(
    ({ scholarshipDocumentId }) => scholarshipDocumentId === document.id
  );

  return (
    <>
      <div className="col-6">
        <fieldset className={`form-group border px-2`}>
          <legend
            className="w-auto px-2"
            style={{
              fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
              fontWeight: 400,
              fontSize: "0.82rem",
              lineHeight: "1em",
              letterSpacing: "0.00938em",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          >
            {document.id}.{" "}
            <span className="ml-1">
              {document.name} {!!document.isOptional && <span>(Optional)</span>}
            </span>
          </legend>
          <div className="form-group1 mb-2">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-paperclip"></i>
                </span>
              </div>
              {!!attachment ? (
                <div
                  className="form-control"
                  style={{
                    backgroundColor: "rgb(229, 246, 253)",

                    color: "#007bff",
                  }}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setUploadedDocument({
                        name: document.name,
                        url: attachment.filePath,
                      });

                      setShowUploadedDocument(true);
                    }}
                  >
                    View attachment
                  </span>

                  <IconButton
                    size="small"
                    onClick={() => {
                      setUploadedDocument({
                        name: document.name,
                        url: attachment.filePath,
                      });

                      setShowUploadedDocument(true);
                    }}
                    style={{
                      marginTop: "-5px",
                      marginRight: "-10px",
                    }}
                    color="info"
                    className="float-right"
                    aria-label="view"
                  >
                    <span className="material-icons  mr-1">open_in_new</span>
                  </IconButton>
                </div>
              ) : (
                <div
                  className="form-control"
                  style={{
                    backgroundColor: "#f1f1f1",
                    color: "red",
                  }}
                >
                  <span style={{ cursor: "default" }}>No attachment</span>
                </div>
              )}
            </div>
          </div>
        </fieldset>
      </div>
    </>
  );
};
