const types = {
  START_LOADING: "START_LOADING",
  END_LOADING: "END_LOADING",
  CLEAN_STATE: "CLEAN_STATE",
  SET_STRONG_PASSWORD: "SET_STRONG_PASSWORD",
  SET_USER: "SET_USER",
  LOGOUT: "LOGOUT",
  START_WAIT: "START_WAIT",
  END_WAIT: "END_WAIT",
  SET_ENV: "SET_ENV",
  SET_VERSION: "SET_VERSION",
  SET_ACADEMIC_YEARS: "SET_ACADEMIC_YEARS",
  UPDATE_ACADEMIC_YEAR: "UPDATE_ACADEMIC_YEAR",
  SET_SELECTED_ACADEMIC_YEAR: "SET_SELECTED_ACADEMIC_YEAR",

  SET_USER_ENTITIES: "SET_USER_ENTITIES",
  SET_SELECTED_USER_ENTITY: "SET_SELECTED_USER_ENTITY",

  SET_SCOPES: "SET_SCOPES",
  SET_SELECTED_SCOPE: "SET_SELECTED_SCOPE",
};

export default types;
